import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { BaseSteps } from '@app/components/common/BaseSteps/BaseSteps';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import * as D from './DashboardPages/DashboardPage.styles';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { LIVE, ADDRESSES, currentChain, VAULT_IDX, VAULT_TYPE } from '@app/lib/contracts';

import { SwapWidget, JsonRpcProvider, darkTheme, lightTheme, Theme } from '@uniswap/widgets';
// import './uniswapFonts.css';
import {
  Y_UPDOWN_PARAMS,
  Y_NEUTRAL_PARAMS,
  Y_PUP_PARAMS,
  Y_PDOWN_PARAMS,
  RPC_PROVIDER_LIST,
  networkChains,
  provider,
  activeSigner,
} from '@app/lib/contracts';
import { useWalletClient } from 'wagmi';
import { polygonMumbai } from 'wagmi/chains';
import { useAppSelector } from '@app/hooks/reduxHooks';
import './uniswapFonts.css';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { themeObject } from '@app/styles/themes/themeVariables';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ISwapProps {
  loading: boolean;
}

const MY_TOKEN_LIST = [
  {
    name: 'Yiedl NEUTRAL',
    address: Y_NEUTRAL_PARAMS.vaultAddress,
    symbol: 'Y-NEUTRAL',
    decimals: 18,
    chainId: currentChain.id,
    logoURI: 'https://yiedl-assets.web.app/tokens/neutral_sm.png',
  },
  // {
  //   name: 'Yiedl UPDOWN',
  //   address: Y_UPDOWN_PARAMS.vaultAddress,
  //   symbol: 'Y-UPDOWN',
  //   decimals: 18,
  //   chainId: currentChain.id,
  //   logoURI: 'https://yiedl-assets.web.app/tokens/updown_sm.png',
  // },
  {
    name: 'Yiedl UP',
    address: Y_PUP_PARAMS.vaultAddress,
    symbol: 'Y-UP',
    decimals: 18,
    chainId: currentChain.id,
    logoURI: 'https://yiedl-assets.web.app/tokens/up_sm.png',
  },
  {
    name: 'Yiedl DOWN',
    address: Y_PDOWN_PARAMS.vaultAddress,
    symbol: 'Y-DOWN',
    decimals: 18,
    chainId: currentChain.id,
    logoURI: 'https://yiedl-assets.web.app/tokens/down_sm.png',
  },
  {
    name: 'SUSD',
    address: ADDRESSES.Assets.SUSD,
    symbol: 'SUSD',
    decimals: 18,
    chainId: currentChain.id,
    logoURI: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/master/synths/sUSD.svg',
  },
];
const defaultToken = Y_PUP_PARAMS.vaultAddress;
const jsonRpcUrlMap = {
  10: RPC_PROVIDER_LIST,
};

export const SwapPage: React.FC<ISwapProps> = (props) => {
  const { t } = useTranslation();
  const theme = useAppSelector((state) => state.theme.theme);

  const myLightTheme = {
    primary: themeObject[theme].primary,
    secondary: themeObject[theme].secondary,
    interactive: themeObject[theme].light,
    container: themeObject[theme].light,
    module: themeObject[theme].primary1,
    accent: themeObject[theme].primary,
    outline: themeObject[theme].primary,
    dialog: themeObject[theme].light,
  };
  //
  // return (
  //   <DashboardCard title={t('Work in progress..')}>
  //     <ClockCircleOutlined style={{ fontSize: '50px', color: themeObject[theme].primary }} />
  //   </DashboardCard>
  // );

  return (
    <DashboardCard bordered={true} title={t('Swap on Uniswap')} loading={props.loading}>
      <PageTitle>{t('Swap')}</PageTitle>
      <p
        style={{ margin: 0, fontSize: 'medium' }}
      >{`To acquire shares in this vault, you'll need SUSD. Here's how:`}</p>
      <p style={{ margin: 0, fontSize: 'medium' }}>{`- Exchange ETH for SUSD`}</p>
      <p style={{ margin: 0, fontSize: 'medium' }}>{`- Use your SUSD to purchase your favorite shares: `}</p>
      <p>{`( Y-UP-SUSD  |  Y-DOWN-SUSD  |  Y-NEUTRAL-SUSD )`}</p>
      {/*<p*/}
      {/*  style={{ fontSize: 'small', fontStyle: 'italic', margin: 0 }}*/}
      {/*>{`N.B. Please note that the shares available may be limited.`}</p>*/}
      {/*<p*/}
      {/*  style={{ fontSize: 'small', fontStyle: 'italic' }}*/}
      {/*>{`In that case move to the MINT tab, to mint new shares. `}</p>*/}
      <BaseRow justify={'center'} style={{ marginTop: '1rem' }}>
        <SwapWidget
          tokenList={MY_TOKEN_LIST}
          jsonRpcUrlMap={jsonRpcUrlMap}
          defaultChainId={10}
          width="90%"
          theme={theme == 'dark' ? darkTheme : myLightTheme}
          hideConnectionUI={true}
          defaultInputTokenAddress={'NATIVE'}
          defaultInputAmount={10}
          defaultOutputTokenAddress={ADDRESSES.Assets.SUSD}
        />
      </BaseRow>
    </DashboardCard>
  );
};

// export default SwapPage;

{
  /*// tokenList={MY_TOKEN_LIST}*/
}
{
  /*// jsonRpcUrlMap={jsonRpcUrlMap}*/
}
{
  /*// defaultChainId={80001}*/
}
{
  /*// width="100%"*/
}
{
  /*// theme={theme == "dark" ? darkTheme : lightTheme}*/
}
{
  /*// hideConnectionUI={true}*/
}
{
  /*// defaultInputTokenAddress={testUSDC}*/
}
{
  /*// defaultInputAmount={10}*/
}
{
  /*// defaultOutputTokenAddress={defaultToken}*/
}

{
  /*  width="50%"*/
}
{
  /*  theme={darkTheme}*/
}
{
  /*  // provider={useSigner({ chainId: 80001 }).data?.provider as JsonRpcProvider}*/
}
{
  /*  // provider={provider as JsonRpcProvider}*/
}

{
  /*</DashboardCard>*/
}
