import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
// import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { InputNumber, Popover } from 'antd';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import {
  activeUser,
  cancelDeposit,
  DECIMALS,
  deposit,
  Request,
  router,
  switchToOP,
  UNIT18,
  usdcAllow,
  usdcAllowance,
  VAULT_TYPE,
} from '@app/lib/contracts';
import { BigNumber, ethers } from 'ethers';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import * as P from '@app/components/vaults-dashboard/statisticsCards/statisticsCard/StatisticsProgress/StatisticsProgress.styles';
import { useResponsive } from '@app/hooks/useResponsive';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export interface IMintCardProps {
  usdcBal: string;
  spinning: boolean;
  vaultType: VAULT_TYPE;
  connected: boolean;
  minUsdc: string;
  maxUsdc: string;
  pending: Request;
  orderFees: string;
  onboardingFees: string;
  tooltipContent?: any;
  shareSymbol: string;
}

function roundDown(inputString: string, decimals: number) {
  decimals = decimals || 0;
  const number = Number(inputString);
  return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export const MintCard: React.FC<IMintCardProps> = (props) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [inputValue, setInputValue] = useState<BigNumber>(
    ethers.utils.parseUnits(props.minUsdc != '~' ? props.minUsdc : '0', DECIMALS),
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalMessage, setModalMessage] = useState<string>('');
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);
  const [isMRModalOpen, setIsMRModalOpen] = useState<boolean>(false);
  const [allowanceBigNum, setAllowanceBigNum] = useState<ethers.BigNumber>(ethers.BigNumber.from(0));
  const { t } = useTranslation();

  const theme = useAppSelector((state) => state.theme.theme);
  const { isTablet: isTabletOrHigher } = useResponsive();

  const formatNumString = (numString: string) => {
    return Number(numString).toLocaleString(undefined, {
      maximumFractionDigits: 3,
    });
  };

  const formatNum = (request: Request) => {
    let res = '';
    if (request) {
      res = (request.requestAmount + request.requestFeeInUsd).toLocaleString(undefined, {
        // minimumFractionDigits: 2,
        // maximumSignificantDigits: 4,
        maximumFractionDigits: 3,
      });
    }
    return res;
  };

  // const onChange = (newValue: number | null) => {
  //   setInputValue(newValue ? newValue : 0);
  //   console.log(newValue, inputValue);
  // };

  const onSliderChange = (pctValue: number | null) => {
    // const balance = Number(props.usdcBal);
    const balanceBN = ethers.utils.parseUnits(props.usdcBal, DECIMALS);
    const percentageBN = ethers.utils.parseUnits(pctValue ? pctValue.toString() : '0', DECIMALS);
    const computed = balanceBN.mul(percentageBN).div(100).div(UNIT18);

    // console.log('Balance1', props.usdcBal);

    // const computed = pctValue ? Number(((pctValue * balance) / 100).toFixed(18)) : 0;

    // console.log('balance2', ethers.utils.formatUnits(computed, DECIMALS));
    // console.log('balance3', Number(ethers.utils.formatUnits(computed, DECIMALS)));
    setInputValue(computed);
    // setInputValue(
    //   pctValue ?
    //       Number(((pctValue * balance) / 100).toLocaleString(undefined, { maximumFractionDigits: 18 }))
    //       : 0
    // );
  };

  const onInputChange = (valueIn: number | null) => {
    // const balance = Number(props.usdcBal);
    // setInputValue(inputValue ? Number(inputValue.toFixed(18)) : 0);
    const val = ethers.utils.parseUnits(valueIn ? valueIn.toString() : '0', DECIMALS);
    setInputValue(val);
  };

  const onActionClick = async () => {
    setIsLoading(true);
    await switchToOP();
    const allowanceBn = await usdcAllowance(activeUser, router.address);
    setAllowanceBigNum(allowanceBn);
    if (allowanceBn.gt(inputValue)) {
      // allowance ok, no approval needed.
      setIsMRModalOpen(true);
    } else {
      setIsApproveModalOpen(true);
    }
    setIsLoading(false);
  };

  const approve = async () => {
    setIsApproveModalOpen(false);
    const approveDelta = ethers.constants.MaxUint256.sub(allowanceBigNum);

    try {
      const { transaction } = await usdcAllow(router.address, approveDelta);

      // Show notifications
      toast
        .promise(transaction, {
          pending: 'Processing approval…',
          success: 'Approval completed.',
          error: 'Error with approval request.',
        })
        .then((result) => {
          setIsApproveModalOpen(false);
          setIsMRModalOpen(true);
        });
    } catch (err: any) {
      console.log('***', err);
      if (err.code === 'ACTION_REJECTED') {
        toast.info('Approval cancelled by user.', {
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: true,
        });
      } else {
        toast.error('Error with approval request.');
      }
    }
  };

  const mint = async () => {
    setIsMRModalOpen(false);
    let transaction: Promise<ethers.providers.TransactionReceipt>;

    try {
      setIsLoading(true);
      transaction = (await deposit(props.vaultType, inputValue)).transaction;
    } catch (err: any) {
      console.log(err);
      if (err.code === 'ACTION_REJECTED') {
        toast.info('Depositing cancelled by user.', {
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: true,
        });
      } else {
        toast.error('Error depositing.');
      }
      setIsLoading(false);
      return;
    }

    const loadingToastId = toast.loading('Please wait while your deposit request is being processed.');
    try {
      await transaction;
    } catch (err: any) {
      toast.update(loadingToastId, {
        render: 'Error processing deposit request.',
        type: toast.TYPE.ERROR,
        isLoading: false,
      });
      setIsLoading(false);
      return;
    }

    toast.update(loadingToastId, {
      render: `Deposit request of ${ethers.utils.formatUnits(
        inputValue,
        DECIMALS,
      )} sUSD recorded. Please check the Account > Transactions page for the status of your request.`,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 7000,
      closeButton: true,
      hideProgressBar: true,
    });
    setInputValue(BigNumber.from(0));
    setIsLoading(false);
  };

  const cancel = async () => {
    let transaction: Promise<ethers.providers.TransactionReceipt>;

    try {
      setIsLoading(true);
      transaction = (await cancelDeposit(props.vaultType)).transaction;
    } catch (err: any) {
      console.log(err);
      if (err.code === 'ACTION_REJECTED') {
        toast.info('Cancellation request cancelled by user.');
      } else {
        toast.error('Error depositing.');
      }
      setIsLoading(false);
      return;
    }

    const loadingToastId = toast.loading('Please wait while your cancellation request is being processed.');
    try {
      await transaction;
    } catch (err: any) {
      toast.update(loadingToastId, {
        render: 'Error processing cancel request.',
        type: toast.TYPE.ERROR,
        isLoading: false,
      });
      setIsLoading(false);
      return;
    }

    // let afterBal = Number(await pdBal(props.vaultType));
    //
    // while (afterBal == beforeBal) {
    //   afterBal = Number(await pdBal(props.vaultType));
    // }

    toast.update(loadingToastId, {
      render: `Cancellation completed.`,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 2000,
      closeButton: true,
      hideProgressBar: true,
    });
    setInputValue(BigNumber.from(0));
    setIsLoading(false);
  };

  return (
    <>
      <BaseSpin spinning={props.spinning}>
        <ToastContainer />
        <DashboardCard title={t(`Mint ${props.shareSymbol} Shares`)} bordered={true}>
          {props.connected ? (
            <>
              <BaseRow gutter={[20, 20]} justify={'space-between'}>
                {/*<BaseButtonsForm.Item name="input-number" noStyle>*/}
                <BaseCol>
                  <InputNumber
                    style={{ minWidth: 180 }}
                    min={Number(props.minUsdc)}
                    max={Number(props.usdcBal)}
                    onChange={onInputChange}
                    value={roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6)}
                  />
                  {/*</BaseButtonsForm.Item>*/}
                  <span> {t('sUSD')}</span>
                </BaseCol>
                <BaseCol>
                  <BaseButton
                    type="primary"
                    style={{ minWidth: 100, marginBottom: '1.5rem' }}
                    loading={isLoading}
                    onClick={onActionClick}
                    disabled={
                      props.minUsdc != '~' && props.usdcBal != '~'
                        ? inputValue.lt(ethers.utils.parseUnits(props.minUsdc, DECIMALS)) ||
                          inputValue.gt(ethers.utils.parseUnits(props.usdcBal, DECIMALS))
                        : true
                    }
                  >
                    {t('Deposit')}
                  </BaseButton>
                </BaseCol>
              </BaseRow>
              {/*</BaseButtonsForm.Item>*/}

              {/*<BaseButtonsForm.Item*/}
              {/*  name="slider"*/}
              {/*  // label={t('forms.validationFormLabels.slider')}*/}
              {/*>*/}
              <BaseCol style={{ marginTop: '2vh' }}>
                <BaseSlider
                  style={{ marginLeft: '2vh', marginRight: '2vh' }}
                  tooltip={{ open: false }}
                  marks={{
                    0: '0%',
                    25: '25%',
                    50: '50%',
                    75: '75%',
                    100: '100%',
                  }}
                  onChange={onSliderChange}
                  value={(100 * roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6)) / Number(props.usdcBal)}
                  min={0}
                  max={100}
                />
              </BaseCol>
              <BaseRow gutter={[20, 20]} justify={'start'} style={{ fontStyle: 'italic', fontSize: 'small' }}>
                <BaseCol>Minimum Amount:</BaseCol>
                <BaseCol>{props.minUsdc} sUSD</BaseCol>
              </BaseRow>

              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '2vh' }}>
                <BaseCol>Balance:</BaseCol>
                <BaseCol>{formatNumString(props.usdcBal)} sUSD</BaseCol>
              </BaseRow>

              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '1vh' }}>
                <BaseCol>Pending Deposits:</BaseCol>
                <BaseCol>{formatNum(props.pending)} sUSD</BaseCol>
              </BaseRow>

              <BaseRow gutter={[20, 20]} justify={'end'} style={{ marginTop: '1vh' }}>
                <BaseCol>
                  <BaseButton
                    type="default"
                    size="small"
                    style={{ minWidth: 100, marginBottom: '0rem', fontWeight: 'normal' }}
                    loading={isLoading}
                    onClick={cancel}
                    disabled={props.pending ? props.pending.requestAmount + props.pending.requestFeeInUsd <= 0 : true}
                  >
                    {t('Cancel Request')}
                  </BaseButton>
                </BaseCol>
              </BaseRow>

              {/* Fee Section */}
              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '1vh' }}>
                <BaseCol>
                  <BaseSpace direction="horizontal" size={6} style={{ marginTop: '0vh', marginBottom: '0vh' }}>
                    <span style={{ fontSize: 'small', fontWeight: 'normal' }}>
                      {`Est Order Fees`}
                      <Popover trigger="click" content={props.tooltipContent} showArrow={false} placement={'topLeft'}>
                        {' '}
                        <a>{`(?)`}</a>
                      </Popover>
                      {`:`}
                    </span>
                  </BaseSpace>
                </BaseCol>

                <BaseSpin spinning={props.spinning}>
                  <BaseCol style={{ textAlign: 'right', margin: '0vh' }}>
                    <P.ValueText style={props.orderFees.length > 0 ? { fontSize: 'small' } : { fontSize: 'medium' }}>
                      {props.orderFees} sUSD
                    </P.ValueText>
                  </BaseCol>
                </BaseSpin>
              </BaseRow>

              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '0vh' }}>
                <BaseCol>
                  {/*<BaseSpace direction="horizontal" size={6} style={{ marginTop: '0vh', marginBottom: '0vh' }}>*/}
                  <span
                    style={{ fontSize: 'small', fontWeight: 'normal' }}
                  >{`Onboarding Fee: (${props.onboardingFees}%)`}</span>
                  {/*</BaseSpace>*/}
                </BaseCol>

                <BaseSpin spinning={props.spinning}>
                  <BaseCol style={{ textAlign: 'right', margin: '0vh' }}>
                    <P.ValueText
                      style={props.onboardingFees.length > 0 ? { fontSize: 'small' } : { fontSize: 'medium' }}
                    >
                      {(
                        (Number(props.onboardingFees) *
                          (inputValue ? roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6) : 0)) /
                        100
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{' '}
                      sUSD
                    </P.ValueText>
                  </BaseCol>
                </BaseSpin>
              </BaseRow>

              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '0vh' }}>
                <BaseCol>
                  {/*<BaseSpace direction="horizontal" size={6} style={{ marginTop: '0vh', marginBottom: '0vh' }}>*/}
                  <Popover trigger="hover" content={props.tooltipContent}>
                    <span style={{ fontSize: 'small', fontWeight: 'normal' }}>{`Est Total`}</span>
                  </Popover>
                  {/*</BaseSpace>*/}
                </BaseCol>

                <BaseSpin spinning={props.spinning}>
                  <BaseCol style={{ textAlign: 'right', margin: '0vh' }}>
                    <P.ValueText
                      style={props.onboardingFees.length > 0 ? { fontSize: 'small' } : { fontSize: 'medium' }}
                    >
                      {(
                        (Number(props.onboardingFees) *
                          (inputValue ? roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6) : 0)) /
                          100 +
                        Number(props.orderFees)
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{' '}
                      sUSD
                    </P.ValueText>
                  </BaseCol>
                </BaseSpin>
              </BaseRow>

              {/*  Approve Modal*/}
              <BaseModal
                title={t('Approve')}
                centered
                open={isApproveModalOpen}
                onOk={approve}
                onCancel={() => {
                  setIsApproveModalOpen(false);
                  setIsLoading(false);
                }}
                okText={t('Approve')}
                size="medium"
              >
                <p>{t('The YIEDL Protocol requires permission to interact with your sUSD tokens.')}</p>
              </BaseModal>

              {/*  Deposit Modal*/}
              <BaseModal
                title={t('Deposit')}
                centered
                open={isMRModalOpen}
                onOk={mint}
                onCancel={() => {
                  setIsMRModalOpen(false);
                  setIsLoading(false);
                }}
                okText={t('Deposit')}
                size="medium"
              >
                <p>
                  {inputValue
                    ? t(`Confirm deposit request of
              ${ethers.utils.formatUnits(inputValue, DECIMALS)} sUSD?`)
                    : t(`invalid input`)}
                </p>
                <p>
                  {
                    // <span style={{ color: themeObject[theme].error }}>
                    //   {Number(props.minUsdc) > inputValue
                    //     ? `You are requesting a deposit amount lower than ${Number(props.minUsdc).toLocaleString(
                    //         undefined,
                    //         { maximumFractionDigits: 0 },
                    //       )} sUSD.
                    //           This deposit can only be processed when the total amount of pending deposits from all participants meets the minimum.
                    //           Your exchange rate will vary according to the final processing time.
                    //           Are you sure you want to proceed?`
                    //     : Number(props.maxUsdc) < inputValue
                    //     ? `You are requesting a deposit amount more than ${Number(props.maxUsdc).toLocaleString(
                    //         undefined,
                    //         { maximumFractionDigits: 0 },
                    //       )} sUSD.
                    //           This may result in a processing time ranging between a few hours to a few days.
                    //           Your exchange rate will vary according to the final processing time.
                    //           Are you sure you want to proceed?`
                    //     : ''}
                    // </span>
                  }
                </p>
              </BaseModal>
            </>
          ) : (
            `Please connect wallet to deposit.`
          )}
        </DashboardCard>
      </BaseSpin>
    </>
  );
};
