import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
// import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { InputNumber, Popover, Row, Slider, Space } from 'antd';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import {
  activeUser,
  router,
  VAULT_TYPE,
  redeem,
  shareAllowance,
  shareAllow,
  cancelDeposit,
  cancelRedeem,
  Request,
  DECIMALS,
  UNIT18,
  switchToOP,
} from '@app/lib/contracts';
import { BigNumber, ethers } from 'ethers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import * as P from '@app/components/vaults-dashboard/statisticsCards/statisticsCard/StatisticsProgress/StatisticsProgress.styles';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export interface IRedeemCardProps {
  shareBal: string;
  spinning: boolean;
  vaultType: VAULT_TYPE;
  connected: boolean;
  shareSymbol: string;
  inav: number;
  minVal: string;
  pending: Request;
  orderFees: string;
  offboardingFees: string;
  tooltipContent?: any;
  minShares: string;
}

function roundDown(inputString: string, decimals: number) {
  decimals = decimals || 0;
  const number = Number(inputString);
  return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export const RedeemCard: React.FC<IRedeemCardProps> = (props) => {
  const [inputValue, setInputValue] = useState<BigNumber>(
    ethers.utils.parseUnits(props.minShares != '~' ? props.minShares : '0', DECIMALS),
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);
  const [isMRModalOpen, setIsMRModalOpen] = useState<boolean>(false);
  const [allowanceBigNum, setAllowanceBigNum] = useState<ethers.BigNumber>(ethers.BigNumber.from(0));
  const { t } = useTranslation();

  const formatNumString = (numString: string) => {
    return Number(numString).toLocaleString(undefined, { maximumFractionDigits: 3 });
  };

  const formatNum = (request: Request) => {
    let res = '';
    if (request) {
      res = (request.requestAmount + request.requestFeeInUsd).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumSignificantDigits: 4,
      });
    }
    return res;
  };

  // const onChange = (newValue: number | null) => {
  //   setInputValue(newValue ? newValue : 0);
  //   console.log(newValue, inputValue);
  // };

  const onSliderChange = (pctValue: number | null) => {
    const balanceBN = ethers.utils.parseUnits(props.shareBal, DECIMALS);
    const percentageBN = ethers.utils.parseUnits(pctValue ? pctValue.toString() : '0', DECIMALS);
    const computed = balanceBN.mul(percentageBN).div(100).div(UNIT18);

    setInputValue(computed);

    // const balanceBN
    // const balance = Number(props.shareBal);
    // setInputValue(
    //   pctValue
    //     ? Number(
    //         ((pctValue * balance) / 100)
    //           // .toLocaleString(undefined, { maximumFractionDigits: 6 })
    //           .toFixed(18),
    //       )
    //     : 0,
    // );
  };

  const onInputChange = (valueIn: number | null) => {
    // const balance = Number(props.usdcBal);
    // setInputValue(inputValue ? Number(inputValue.toFixed(18)) : 0);
    const val = ethers.utils.parseUnits(valueIn ? valueIn.toString() : '0', DECIMALS);
    setInputValue(val);
  };

  const onActionClick = async () => {
    setIsLoading(true);
    await switchToOP();
    const allowanceBn = await shareAllowance(activeUser, router.address, props.vaultType);
    setAllowanceBigNum(allowanceBn);
    if (allowanceBn.gt(inputValue)) {
      // allowance ok, no approval needed.
      setIsMRModalOpen(true);
    } else {
      setIsApproveModalOpen(true);
    }
    setIsLoading(false);
  };

  const approve = async () => {
    setIsApproveModalOpen(false);
    const approveDelta = ethers.constants.MaxUint256.sub(allowanceBigNum);

    try {
      const { transaction } = await shareAllow(props.vaultType, router.address, approveDelta);

      // Show notifications
      toast
        .promise(transaction, {
          pending: 'Processing approval…',
          success: 'Approval completed.',
          error: 'Error with approval request.',
        })
        .then((result) => {
          setIsApproveModalOpen(false);
          setIsMRModalOpen(true);
        });
    } catch (err: any) {
      if (err.code === 'ACTION_REJECTED') {
        toast.info('Approval cancelled by user.', {
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: true,
        });
      } else {
        toast.error('Error with approval request.');
      }
    }
  };

  const redemption = async () => {
    setIsMRModalOpen(false);
    let transaction: Promise<ethers.providers.TransactionReceipt>;

    try {
      setIsLoading(true);
      transaction = (await redeem(props.vaultType, inputValue)).transaction;
    } catch (err: any) {
      if (err.code === 'ACTION_REJECTED') {
        toast.info('Redeeming cancelled by user.', {
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: true,
        });
      } else {
        toast.error('Error redeeming.');
      }
      setIsLoading(false);
      return;
    }

    const loadingToastId = toast.loading('Please wait while your redemption request is being processed.');
    try {
      await transaction;
    } catch (err: any) {
      toast.update(loadingToastId, {
        render: 'Error processing redemption request.',
        type: toast.TYPE.ERROR,
        isLoading: false,
      });
      setIsLoading(false);
      return;
    }

    toast.update(loadingToastId, {
      render: `Redemption request of ${ethers.utils.formatUnits(inputValue, DECIMALS)} ${
        props.shareSymbol
      } recorded. Please check the Account > Transactions page for the status of your request.`,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 7000,
      closeButton: true,
      hideProgressBar: true,
    });

    setInputValue(BigNumber.from(0));
    setIsLoading(false);
  };

  const cancel = async () => {
    let transaction: Promise<ethers.providers.TransactionReceipt>;

    try {
      setIsLoading(true);
      transaction = (await cancelRedeem(props.vaultType)).transaction;
    } catch (err: any) {
      console.log(err);
      if (err.code === 'ACTION_REJECTED') {
        toast.info('Cancellation request cancelled by user.');
      } else {
        toast.error('Error depositing.');
      }
      setIsLoading(false);
      return;
    }

    const loadingToastId = toast.loading('Please wait while your cancellation request is being processed.');
    try {
      await transaction;
    } catch (err: any) {
      toast.update(loadingToastId, {
        render: 'Error processing cancel request.',
        type: toast.TYPE.ERROR,
        isLoading: false,
      });
      setIsLoading(false);
      return;
    }

    // let afterBal = Number(await pdBal(props.vaultType));
    //
    // while (afterBal == beforeBal) {
    //   afterBal = Number(await pdBal(props.vaultType));
    // }

    toast.update(loadingToastId, {
      render: `Cancellation completed.`,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 2000,
      closeButton: true,
      hideProgressBar: true,
    });
    setInputValue(BigNumber.from(0));
    setIsLoading(false);
  };

  const theme = useAppSelector((state) => state.theme.theme);

  return (
    <>
      <BaseSpin spinning={props.spinning}>
        <ToastContainer />
        <DashboardCard title={t(`Redeem ${props.shareSymbol} Shares`)} bordered={true}>
          {props.connected ? (
            <>
              <BaseRow gutter={[20, 20]} justify={'space-between'}>
                {/*<BaseButtonsForm.Item name="input-number" noStyle>*/}
                <BaseCol>
                  <InputNumber
                    style={{ minWidth: 180 }}
                    min={Number(props.minShares)}
                    max={Number(props.shareBal)}
                    onChange={onInputChange}
                    value={roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6)}
                  />
                  {/*</BaseButtonsForm.Item>*/}
                  {/*<span style={{ fontSize: "small" }}> {t(`${props.shareSymbol}`)}</span>*/}
                  <span> {t('Shares')}</span>
                </BaseCol>
                <BaseCol>
                  <BaseButton
                    type="primary"
                    style={{ minWidth: 100 }}
                    loading={isLoading}
                    onClick={onActionClick}
                    disabled={
                      props.minShares != '~' && props.shareBal != '~'
                        ? inputValue.lt(ethers.utils.parseUnits(props.minShares, DECIMALS)) ||
                          inputValue.gt(ethers.utils.parseUnits(props.shareBal, DECIMALS))
                        : true
                    }
                  >
                    {t('Redeem')}
                  </BaseButton>
                </BaseCol>
              </BaseRow>
              <BaseRow>
                <BaseCol>
                  <span style={{ fontSize: 'small' }}>
                    {' '}
                    {`Est Value: ${(isNaN(Number(props.inav))
                      ? 0
                      : Number(ethers.utils.formatUnits(inputValue, DECIMALS)) * Number(props.inav) * 0.97
                    ).toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })} USDC`}
                  </span>
                </BaseCol>
              </BaseRow>
              {/*</BaseButtonsForm.Item>*/}

              {/*<BaseButtonsForm.Item*/}
              {/*  name="slider"*/}
              {/*  // label={t('forms.validationFormLabels.slider')}*/}
              {/*>*/}
              <BaseCol style={{ marginTop: '2vh' }}>
                <BaseSlider
                  style={{ marginLeft: '2vh', marginRight: '2vh' }}
                  tooltip={{ open: false }}
                  marks={{
                    0: '0%',
                    25: '25%',
                    50: '50%',
                    75: '75%',
                    100: '100%',
                  }}
                  onChange={onSliderChange}
                  value={(100 * roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6)) / Number(props.shareBal)}
                  min={0}
                  max={100}
                />
              </BaseCol>

              <BaseRow gutter={[20, 20]} justify={'start'} style={{ fontStyle: 'italic', fontSize: 'small' }}>
                <BaseCol>Minimum Amount:</BaseCol>
                <BaseCol>
                  {props.minShares} {props.shareSymbol}
                </BaseCol>
              </BaseRow>

              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '2vh' }}>
                <BaseCol>Balance:</BaseCol>
                <BaseCol>
                  {formatNumString(props.shareBal)} {props.shareSymbol}
                </BaseCol>
              </BaseRow>

              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '1vh' }}>
                <BaseCol>Pending Redeems:</BaseCol>
                <BaseCol>
                  {formatNum(props.pending)} {props.shareSymbol}
                </BaseCol>
              </BaseRow>

              <BaseRow gutter={[20, 20]} justify={'end'} style={{ marginTop: '1vh' }}>
                <BaseCol>
                  <BaseButton
                    type="default"
                    size="small"
                    style={{ minWidth: 100, marginBottom: '0rem', fontWeight: 'normal' }}
                    loading={isLoading}
                    onClick={cancel}
                    disabled={props.pending ? props.pending.requestAmount <= 0 : true}
                  >
                    {t('Cancel Request')}
                  </BaseButton>
                </BaseCol>
              </BaseRow>

              {/* Fee Section */}
              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '1vh' }}>
                <BaseCol>
                  <BaseSpace direction="horizontal" size={6} style={{ marginTop: '0vh', marginBottom: '0vh' }}>
                    <span style={{ fontSize: 'small', fontWeight: 'normal' }}>
                      {`Est Order Fees`}
                      <Popover trigger="click" content={props.tooltipContent} showArrow={false} placement={'topLeft'}>
                        {' '}
                        <a>{`(?)`}</a>
                      </Popover>
                      {`:`}
                    </span>
                  </BaseSpace>
                </BaseCol>

                <BaseSpin spinning={props.spinning}>
                  <BaseCol style={{ textAlign: 'right', margin: '0vh' }}>
                    <P.ValueText style={props.orderFees.length > 0 ? { fontSize: 'small' } : { fontSize: 'medium' }}>
                      {props.orderFees} sUSD
                    </P.ValueText>
                  </BaseCol>
                </BaseSpin>
              </BaseRow>

              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '0vh' }}>
                <BaseCol>
                  {/*<BaseSpace direction="horizontal" size={6} style={{ marginTop: '0vh', marginBottom: '0vh' }}>*/}
                  <span
                    style={{ fontSize: 'small', fontWeight: 'normal' }}
                  >{`Offboarding Fee: (${props.offboardingFees}%)`}</span>
                  {/*</BaseSpace>*/}
                </BaseCol>

                <BaseSpin spinning={props.spinning}>
                  <BaseCol style={{ textAlign: 'right', margin: '0vh' }}>
                    <P.ValueText
                      style={props.offboardingFees.length > 0 ? { fontSize: 'small' } : { fontSize: 'medium' }}
                    >
                      {(
                        (Number(props.offboardingFees) *
                          (inputValue
                            ? roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6) * Number(props.inav) * 0.97
                            : 0)) /
                        100
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{' '}
                      sUSD
                    </P.ValueText>
                  </BaseCol>
                </BaseSpin>
              </BaseRow>

              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '0vh' }}>
                <BaseCol>
                  {/*<BaseSpace direction="horizontal" size={6} style={{ marginTop: '0vh', marginBottom: '0vh' }}>*/}
                  <Popover trigger="hover" content={props.tooltipContent}>
                    <span style={{ fontSize: 'small', fontWeight: 'normal' }}>{`Est Total`}</span>
                  </Popover>
                  {/*</BaseSpace>*/}
                </BaseCol>

                <BaseSpin spinning={props.spinning}>
                  <BaseCol style={{ textAlign: 'right', margin: '0vh' }}>
                    <P.ValueText
                      style={props.offboardingFees.length > 0 ? { fontSize: 'small' } : { fontSize: 'medium' }}
                    >
                      {(
                        (Number(props.offboardingFees) *
                          (inputValue
                            ? roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6) * Number(props.inav) * 0.97
                            : 0)) /
                          100 +
                        Number(props.orderFees)
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{' '}
                      sUSD
                    </P.ValueText>
                  </BaseCol>
                </BaseSpin>
              </BaseRow>

              {/*  Approve Modal*/}
              <BaseModal
                title={t('Approve')}
                centered
                open={isApproveModalOpen}
                onOk={approve}
                onCancel={() => {
                  setIsApproveModalOpen(false);
                  setIsLoading(false);
                }}
                okText={t('Approve')}
                size="medium"
              >
                <p>{t(`The YIEDL Protocol requires permission to interact with your ${props.shareSymbol} tokens.`)}</p>
              </BaseModal>

              {/*  Redeem Modal*/}
              <BaseModal
                title={t('Redeem')}
                centered
                open={isMRModalOpen}
                onOk={redemption}
                onCancel={() => {
                  setIsMRModalOpen(false);
                  setIsLoading(false);
                }}
                okText={t('Redeem')}
                size="medium"
              >
                <p>
                  {t(`Confirm redemption request of
              ${ethers.utils.formatUnits(inputValue, DECIMALS)} 
              ${props.shareSymbol}?`)}
                </p>
                <p>
                  {
                    // <span style={{ color: themeObject[theme].error }}>
                    //   {Number(props.minVal) > inputValue
                    //     ? `You are requesting a redemption amount lower than ${Number(props.minVal).toLocaleString(
                    //         undefined,
                    //         { maximumFractionDigits: 0 },
                    //       )} shares.
                    //           This redemption can only be processed when the total amount of pending shares from all participants meets the minimum.
                    //           Your exchange rate will vary according to the final processing time.
                    //           Are you sure you want to proceed?`
                    //     : ''}
                    // </span>
                  }
                </p>
              </BaseModal>
            </>
          ) : (
            `Please connect wallet to redeem.`
          )}
        </DashboardCard>
      </BaseSpin>
    </>
  );
};
