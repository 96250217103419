import React from 'react';
import { Layout, Typography } from 'antd';

const { Footer } = Layout;
const { Link } = Typography;

const AppFooter: React.FC = () => {
  return (
    <Footer style={{ textAlign: 'right', fontSize: 'small', position: 'sticky', bottom: 0 }}>
      <Link href={'/termsofuse'}>Terms of Use</Link>
      {' | '}
      <Link href={'/privacypolicy'}>Privacy Policy</Link>
    </Footer>
  );
};

export default AppFooter;
