export const BNB_VAULT_ADDRESSES = [
  '0x4eDda16AB4f4cc46b160aBC42763BA63885862a4',
  '0x4eDda16AB4f4cc46b160aBC42763BA63885862a4',
  '0x4eDda16AB4f4cc46b160aBC42763BA63885862a4',
];

export const ORACLE_CONNECTOR_ADDRESS = '0x6ed6161cE1e3BaB0aC1b82563b9eA37467c97f78';
export const DISCOUNT_TOKEN_ADDRESS = '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d';
export const DISCOUNT_POLICY_ADDRESS = '0xAFcC65FB87b4942c80684Ae7cbf92c765914ABaC';

export const BNB_ASSET_ADDRESSES = {
  ADA: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
  BCH: '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf',
  BETH: '0x250632378e573c6be1ac2f97fcdf00515d0aa91b',
  BNB: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  BSW: '0x965f527d9159dce6288a2219db51fc6eef120dd1',
  BTCB: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  C98: '0xaec945e04baf28b135fa7c640f624f8d90f1c3a6',
  CAKE: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  DAI: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  DOGE: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
  DOT: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  ETH: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  FIL: '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
  FRAX: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
  FTM: '0xad29abb318791d579433d831ed122afeaf29dcfe',
  INJ: '0xa2b726b1145a4773f68593cf171187d8ebe4d495',
  LINK: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
  MATIC: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
  RDNT: '0xf7de7e8a6bd59ed41a4b5fe50278b3b7f31384df',
  SOL: '0x570a5d26f7765ecb712c0924e4de545b89fd43df',
  TUSD: '0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9',
  UNI: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
  USDC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  USDT: '0x55d398326f99059ff775485246999027b3197955',
  XRP: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
  XVS: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
};

export const BNB_ASSET_NAMES = {
  '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47': 'ADA',
  '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf': 'BCH',
  '0x250632378e573c6be1ac2f97fcdf00515d0aa91b': 'BETH',
  '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee': 'BNB',
  '0x965f527d9159dce6288a2219db51fc6eef120dd1': 'BSW',
  '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c': 'BTCB',
  '0xaec945e04baf28b135fa7c640f624f8d90f1c3a6': 'C98',
  '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82': 'CAKE',
  '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3': 'DAI',
  '0xba2ae424d960c26247dd6c32edc70b295c744c43': 'DOGE',
  '0x7083609fce4d1d8dc0c979aab8c869ea2c873402': 'DOT',
  '0x2170ed0880ac9a755fd29b2688956bd959f933f8': 'ETH',
  '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153': 'FIL',
  '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40': 'FRAX',
  '0xad29abb318791d579433d831ed122afeaf29dcfe': 'FTM',
  '0xa2b726b1145a4773f68593cf171187d8ebe4d495': 'INJ',
  '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd': 'LINK',
  '0xcc42724c6683b7e57334c4e856f4c9965ed682bd': 'MATIC',
  '0xf7de7e8a6bd59ed41a4b5fe50278b3b7f31384df': 'RDNT',
  '0x570a5d26f7765ecb712c0924e4de545b89fd43df': 'SOL',
  '0x40af3827f39d0eacbf4a168f8d4ee67c121d11c9': 'TUSD',
  '0xbf5140a22578168fd562dccf235e5d43a02ce9b1': 'UNI',
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d': 'USDC',
  '0x55d398326f99059ff775485246999027b3197955': 'USDT',
  '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe': 'XRP',
  '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63': 'XVS',
};
