import React, { useState } from 'react';
import { MintCard, IMintCardProps } from '@app/components/vaults-dashboard/mintCard/MintCard/MintCard';
import { RedeemCard, IRedeemCardProps } from '@app/components/vaults-dashboard/redeemCard/RedeemCard/RedeemCard';
import { Tabs } from 'antd';
import { SwapPage } from '@app/pages/SwapPage';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { VAULT_IDX } from '@app/lib/contracts';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

interface ITrippleCardProps {
  mintCardProps: IMintCardProps;
  redeemCardProps: IRedeemCardProps;
  tripleSelected: number;
  selectSwap: () => void;
  selectMint: () => void;
  selectRedeem: () => void;
}

export const TripleCard: React.FC<ITrippleCardProps> = (props) => {
  // const { t } = useTranslation();
  return (
    <div>
      <BaseCol span={24} style={{ marginBottom: '2vh', display: 'flex', justifyContent: 'flex-end' }}>
        <BaseRadio.Group defaultValue={props.tripleSelected} buttonStyle="solid" size="small">
          <BaseRadio.Button onClick={props.selectSwap} value={0}>
            Swap
          </BaseRadio.Button>
          <BaseRadio.Button onClick={props.selectMint} value={1}>
            Mint
          </BaseRadio.Button>
          <BaseRadio.Button onClick={props.selectRedeem} value={2}>
            Redeem
          </BaseRadio.Button>
        </BaseRadio.Group>
      </BaseCol>

      {props.tripleSelected == 0 ? (
        <SwapPage loading={props.mintCardProps.spinning} />
      ) : props.tripleSelected == 1 ? (
        <MintCard {...props.mintCardProps} />
      ) : (
        <RedeemCard {...props.redeemCardProps} />
      )}

      {/*<Tabs defaultActiveKey="swap" centered={true}>*/}
      {/*  <Tabs.TabPane tab="Swap" key="swap">*/}
      {/*    <SwapPage loading={props.mintCardProps.spinning} />*/}
      {/*  </Tabs.TabPane>*/}
      {/*  <Tabs.TabPane tab="Mint" key="mint">*/}
      {/*    <MintCard {...props.mintCardProps} />*/}
      {/*  </Tabs.TabPane>*/}
      {/*  <Tabs.TabPane tab="Redeem" key="redeem">*/}
      {/*    <RedeemCard {...props.redeemCardProps} />*/}
      {/*  </Tabs.TabPane>*/}
      {/*</Tabs>*/}
    </div>
  );
};
