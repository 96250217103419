import React from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Web3Button } from '@web3modal/react';
import { ThemePicker } from '@app/components/header/components/settingsDropdown/settingsOverlay/ThemePicker/ThemePicker';

interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {
  return (
    <BaseRow justify="space-between" align="middle">
      <BaseCol>
        <BaseRow align="middle">
          {/*<BaseCol>*/}
          {/*  <NotificationsDropdown />*/}
          {/*</BaseCol>*/}
          <S.BurgerCol>
            <S.MobileBurger onClick={toggleSider} isCross={isSiderOpened} />
          </S.BurgerCol>

          {/*<BaseCol>*/}
          {/*  <HeaderSearch />*/}
          {/*</BaseCol>*/}

          <BaseCol>
            {/*<SettingsDropdown />*/}
            <ThemePicker />
          </BaseCol>

          <BaseCol>
            {/*<ProfileDropdown />*/}
            <Web3Button />
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </BaseRow>
  );
};
