import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { BaseSteps } from '@app/components/common/BaseSteps/BaseSteps';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { AumCard } from '@app/components/vaults-dashboard/aumCard/AumCard';
import { StatisticsCard } from '@app/components/vaults-dashboard/statisticsCards/statisticsCard/StatisticsCard/StatisticsCard';
import { StatisticColor } from '@app/constants/config/statistics';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { useResponsive } from '@app/hooks/useResponsive';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { ITheme } from '@app/styles/themes/types';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Portfolio } from '@app/lib/contracts';

function getPnlPct(pnlStr: any, aum: number) {
  const pnl = Number(pnlStr.replaceAll(',', ''));
  // const aum = Number(aumStr.replaceAll(",", ""))
  const initialAum = aum - pnl;
  return ((pnl * 100) / initialAum).toLocaleString(undefined, { maximumFractionDigits: 2 });
}

// function processSummaries(info: any, selector: number) {
//   console.log(info);
//   if (info) {
//     const obj =
//       selector == 0 ? info.Y_UPDOWN : selector == 1 ? info.Y_NEUTRAL : selector == 2 ? info.Y_PUP : info.Y_PDOWN;
//     return [
//       {
//         longPct: sumOneSide(obj.formatted, 1),
//         flatPct: sumOneSide(obj.formatted, 0),
//         shortPct: sumOneSide(obj.formatted, -1),
//       },
//     ];
//   }
// }

function getInfoObject(info: any, selector: number) {
  if (info && info.dydx_updown) {
    return selector == 0
      ? info.dydx_updown.formatted
      : selector == 1
      ? info.dydx_neutral.formatted
      : selector == 2
      ? info.dydx_up.formatted
      : info.dydx_down.formatted;
  }
}

function convert(pf: Portfolio | null) {
  const dataSource: TokenHoldingsType[] = [];
  if (pf) {
    for (let i = 0; i < pf.assets.length; i++) {
      const pnl = pf.sizes[i] * (pf.prices[i] - pf.aeps[i]);
      const pct = (100 * pf.values[i]) / pf.aum;
      dataSource.push({
        asset: pf.assetSymbols[i],
        size: pf.sizes[i].toLocaleString(undefined, { minimumFractionDigits: 2, maximumSignificantDigits: 4 }),
        value: pf.values[i].toLocaleString(undefined, { minimumFractionDigits: 2, maximumSignificantDigits: 4 }),
        // pnl: pnl.toLocaleString(undefined, { minimumFractionDigits: 2, maximumSignificantDigits: 4 }),
        pnl: pnl,
        pct: pct,
      });
    }

    dataSource.push({
      asset: 'sUSD',
      size: pf.flatUsds.toLocaleString(undefined, { minimumFractionDigits: 2, maximumSignificantDigits: 4 }),
      value: pf.flatUsds.toLocaleString(undefined, { minimumFractionDigits: 2, maximumSignificantDigits: 4 }),
      pnl: 0,
      pct: (100 * pf.flatUsds) / pf.aum,
    });
  }

  dataSource.sort((a, b) => a.asset.localeCompare(b.asset));

  return dataSource;
}

interface TokenHoldingsType {
  asset: string;
  size: string;
  // price: string;
  value: string;
  pnl: number;
  pct: number;
}

interface ITransactionsPageProps {
  portfolios: Array<Portfolio | null>;
  selector: number;
  loading: boolean;
  processingStatus: Array<number>;
}

const HoldingsTableCard: React.FC<ITransactionsPageProps> = (props) => {
  const { isTablet, isDesktop } = useResponsive();
  const { t } = useTranslation();

  const tokenHoldingsColumn: ColumnsType<TokenHoldingsType> = [
    {
      title: 'Asset',
      dataIndex: 'asset',
      key: 'asset',
      fixed: 'left',
      sorter: (a, b) => a.asset.localeCompare(b.asset),
    },
    {
      title: 'Allocation (%)',
      dataIndex: 'pct',
      key: 'pct',
      sorter: (a, b) => a.pct - b.pct,
      render: (num) => (
        <span>{num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumSignificantDigits: 4 })}</span>
      ),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Value (USD)',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'PnL (USD)',
      dataIndex: 'pnl',
      key: 'pnl',
      sorter: (a, b) => a.pnl - b.pnl,
      render: (num) => (
        <span
          style={{
            color: num > 0 ? BASE_COLORS.lightgreen : num < 0 ? BASE_COLORS.red : 'default',
          }}
        >
          {num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumSignificantDigits: 4 })}
        </span>
      ),
    },
  ];

  return (
    <>
      <BaseCol span={24}>
        {/*<S.Card title={t('Token Holdings')}>*/}
        <BaseTable
          columns={tokenHoldingsColumn}
          // dataSource={getInfoObject(props.portfolio, props.selector)}
          dataSource={convert(props.portfolios[props.selector])}
          scroll={{ x: true }}
          pagination={false}
          loading={{
            spinning: props.loading || props.processingStatus[props.selector] != 0,
            size: 'large',
            style: { fontStyle: 'italic' },
            tip:
              props.processingStatus[props.selector] == 1
                ? 'Rotating portfolio. Please check back in a few minutes.'
                : props.processingStatus[props.selector] == 2
                ? 'Requests being processed. Please check back in a few minutes.'
                : '',
          }}
          size={'small'}
        />
        {/*</S.Card>*/}
      </BaseCol>

      {/*  /!*<S.Card title={t('Request Records')}>*!/*/}
      {/*  /!*  {props.requestRecords.length == 0 ? (*!/*/}
      {/*  /!*    `You no request records at this time.`*!/*/}
      {/*  /!*  ) : (*!/*/}
      {/*  /!*    // <D.ScrollWrapper >*!/*/}
      {/*  /!*    <BaseTable columns={requestColumns} dataSource={props.requestRecords} scroll={{ x: 800 }} />*!/*/}
      {/*  /!*    // </D.ScrollWrapper>*!/*/}
      {/*  /!*  )}*!/*/}
      {/*  /!*</S.Card>*!/*/}
      {/*</BaseCol>*/}
    </>
  );
};

export default HoldingsTableCard;
