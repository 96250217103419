export type VaultItems = {
  Vault: string;
  OpsManager: string;
};

export type AssetItems = {
  [assetName: string]: string;
};

export type AddressItems = {
  Deployments: {
    Router: string;
    BlacklistPolicy: string;
    OpsHelper: string;
    NavRecords: string;
    Vaults: {
      Vault: string;
      OpsManager: string;
    }[];
  };
  Assets: {
    [key: string]: string;
  };
  MarketSettings: string;
  Symbol: {
    [key: string]: string;
  };
};

export const MainnetItems: AddressItems = {
  Deployments: {
    Router: '0x706D83717f0F57ae1b3FDb0274fEFa7A20a60A55',
    BlacklistPolicy: '0x46573ab5ffD3dF6bC546d41A67aF787c923CA1BB',
    OpsHelper: '0xC3E29b0FaE1C239f68639b67a42cAed726a86bF6',
    NavRecords: '0xEE7fEC3657304394e61cEb5dE43e0eF401582050', //'0xa6a9BaF99f9e893fD44068284b5e0861d8eCF4C0', //'0x117652A513f5C48AbB316a7e34e78f5c234A2841', // testnet
    Vaults: [
      // Updown
      {
        Vault: '0x90A039797E93f2c671DE25DD24E5333b5e8F9Ab3',
        OpsManager: '0x7fB9Be6824f34Ea4B026C3d2514BFB690a066500',
      },
      // Neutral
      {
        Vault: '0x90A039797E93f2c671DE25DD24E5333b5e8F9Ab3',
        OpsManager: '0x7fB9Be6824f34Ea4B026C3d2514BFB690a066500',
      },
      // Up
      {
        Vault: '0x2494A64ea1B3AB49b0A9F185087E77BC2049863C',
        OpsManager: '0xb7BA0796F58BE4ef03f318e3624992D279D25F95',
      },
      // Down
      {
        Vault: '0xE6f8f2af4e9a5C2D71Ba37FfE7646d7201ff6d42',
        OpsManager: '0x3126fa715BaF45023166B6Cd871dBeB19b785811',
      },
    ],
  },
  Assets: {
    SUSD: '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
    ONEINCH: '0xd5fAaa459e5B3c118fD85Fc0fD67f56310b1618D',
    AAVE: '0x5374761526175B59f1E583246E20639909E189cE',
    ADA: '0xF9DD29D2Fd9B38Cd90E390C797F1B7E0523f43A9',
    ALGO: '0x96f2842007021a4C5f06Bcc72961701D66Ff8465',
    APE: '0x5B6BeB79E959Aac2659bEE60fE0D0885468BF886',
    APT: '0x9615B6BfFf240c44D3E33d0cd9A11f563a2e8D8B',
    ARB: '0x509072A5aE4a87AC89Fc8D64D94aDCb44Bd4b88e',
    ATOM: '0xbB16C7B3244DFA1a6BF83Fcce3EE4560837763CD',
    // AUD: '0x9De146b5663b82F44E5052dEDe2aA3Fd4CBcDC99',
    AVAX: '0xc203A12F298CE73E44F7d45A4f59a43DBfFe204D',
    AXS: '0x3a52b21816168dfe35bE99b7C5fc209f17a0aDb1',
    BAL: '0x71f42cA320b3e9A8e4816e26De70c9b69eAf9d24',
    BCH: '0x96690aAe7CB7c4A9b5Be5695E94d72827DeCC33f',
    BLUR: '0xa1Ace9ce6862e865937939005b1a6c5aC938A11F',
    BNB: '0x0940B0A96C5e1ba33AEE331a9f950Bb2a6F2Fb25',
    BTC: '0x59b007E9ea8F89b069c43F8f45834d30853e3699',
    CELO: '0x2292865b2b6C837B7406E819200CE61c1c4F8d43',
    COMP: '0xb7059Ed9950f2D9fDc0155fC0D79e63d4441e806',
    CRV: '0xD5fBf7136B86021eF9d0BE5d798f948DcE9C0deA',
    DOGE: '0x98cCbC721cc05E28a125943D69039B39BE6A21e9',
    DOT: '0x8B9B5f94aac2316f048025B3cBe442386E85984b',
    DYDX: '0x139F94E4f0e1101c1464a321CBA815c34d58B5D9',
    ENJ: '0x88C8316E5CCCCE2E27e5BFcDAC99f1251246196a',
    EOS: '0x50a40d947726ac1373DC438e7aaDEde9b237564d',
    ETC: '0x4bF3C1Af0FaA689e3A808e6Ad7a8d89d07BB9EC7',
    // ETHBTC: '0xD5FcCd43205CEF11FbaF9b38dF15ADbe1B186869',
    ETH: '0x2B3bb4c683BFc5239B029131EEf3B1d214478d93',
    // EUR: '0x87AE62c5720DAB812BDacba66cc24839440048d1',
    FIL: '0x2C5E2148bF3409659967FE3684fd999A76171235',
    FLOKI: '0x5ed8D0946b59d015f5A60039922b870537d43689',
    FLOW: '0x27665271210aCff4Fab08AD9Bb657E91866471F0',
    FTM: '0xC18f85A6DD3Bcd0516a1CA08d3B1f0A4E191A2C4',
    FXS: '0x2fD9a39ACF071Aa61f92F3D7A98332c68d6B6602',
    // GBP: '0x1dAd8808D8aC58a0df912aDC4b215ca3B93D6C49',
    GMX: '0x33d4613639603c845e61A02cd3D2A78BE7d513dc',
    ICP: '0x105f7F2986A2414B4007958b836904100a53d1AD',
    INJ: '0x852210F0616aC226A486ad3387DBF990e690116A',
    KNC: '0x152Da6a8F32F25B56A32ef5559d4A2A96D09148b',
    LDO: '0xaa94C874b91ef16C8B56A1c5B2F34E39366bD484',
    LINK: '0x31A1659Ca00F617E86Dc765B6494Afe70a5A9c1A',
    LTC: '0xB25529266D9677E9171BEaf333a0deA506c5F99A',
    MATIC: '0x074B8F19fc91d6B2eb51143E1f186Ca0DDB88042',
    MAV: '0x572F816F21F56D47e4c4fA577837bd3f58088676',
    MKR: '0xf7d9Bd13F877171f6C7f93F71bdf8e380335dc12',
    NEAR: '0xC8fCd6fB4D15dD7C455373297dEF375a08942eCe',
    ONE: '0x86BbB4E38Ffa64F263E84A0820138c5d938BA86E',
    OP: '0x442b69937a0daf9D46439a71567fABE6Cb69FBaf',
    PEPE: '0x3D3f34416f60f77A0a6cC8e32abe45D32A7497cb',
    PERP: '0xaF2E4c337B038eaFA1dE23b44C163D0008e49EaD',
    RNDR: '0x91cc4a83d026e5171525aFCAEd020123A653c2C9',
    RPL: '0xfAD0835dAD2985b25ddab17eace356237589E5C7',
    RUNE: '0xEAf0191bCa9DD417202cEf2B18B7515ABff1E196',
    SEI: '0x66fc48720f09Ac386608FB65ede53Bb220D0D5Bc',
    SHIB: '0x69F5F465a46f324Fb7bf3fD7c0D5c00f7165C7Ea',
    SOL: '0x0EA09D97b4084d859328ec4bF8eBCF9ecCA26F1D',
    // STETHETH: '0x08388dC122A956887c2F736Aaec4A0Ce6f0536Ce',
    STETH: '0xD91Db82733987513286B81e7115091d96730b62A',
    SUI: '0x09F9d7aaa6Bef9598c3b676c0E19C9786Aa566a8',
    SUSHI: '0xdcCDa0cFBEE25B33Ff4Ccca64467E89512511bf6',
    TRX: '0x031A448F59111000b96F016c37e9c71e57845096',
    UMA: '0xb815Eb8D3a9dA3EdDD926225c0FBD3A566e8C749',
    UNI: '0x4308427C463CAEAaB50FFf98a9deC569C31E4E87',
    USDT: '0x1681212A0Edaf314496B489AB57cB3a5aD7a833f',
    WLD: '0x77DA808032dCdd48077FA7c57afbF088713E09aD',
    // XAG: '0xdcB8438c979fA030581314e5A5Df42bbFEd744a0',
    // XAU: '0x549dbDFfbd47bD5639f9348eBE82E63e2f9F777A',
    XLM: '0xfbbBFA96Af2980aE4014d5D5A2eF14bD79B2a299',
    XMR: '0x2ea06E73083f1b3314Fa090eaE4a5F70eb058F2e',
    XRP: '0x6110DF298B411a46d6edce72f5CAca9Ad826C1De',
    XTZ: '0xC645A757DD81C69641e010aDD2Da894b4b7Bc921',
    YFI: '0x6940e7C6125a177b052C662189bb27692E88E9Cb',
    ZEC: '0xf8aB6B9008f2290965426d3076bC9d2EA835575e',
    ZIL: '0x01a43786C2279dC417e7901d45B917afa51ceb9a',
    ZRX: '0x76BB1Edf0C55eC68f4C8C7fb3C076b811b1a9b9f',
  },
  MarketSettings: '0x649F44CAC3276557D03223Dbf6395Af65b11c11c',
  Symbol: {
    '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9': 'SUSD',
    '0xd5fAaa459e5B3c118fD85Fc0fD67f56310b1618D': '1INCH',
    '0x5374761526175B59f1E583246E20639909E189cE': 'AAVE',
    '0xF9DD29D2Fd9B38Cd90E390C797F1B7E0523f43A9': 'ADA',
    '0x96f2842007021a4C5f06Bcc72961701D66Ff8465': 'ALGO',
    '0x5B6BeB79E959Aac2659bEE60fE0D0885468BF886': 'APE',
    '0x9615B6BfFf240c44D3E33d0cd9A11f563a2e8D8B': 'APT',
    '0x509072A5aE4a87AC89Fc8D64D94aDCb44Bd4b88e': 'ARB',
    '0xbB16C7B3244DFA1a6BF83Fcce3EE4560837763CD': 'ATOM',
    '0x9De146b5663b82F44E5052dEDe2aA3Fd4CBcDC99': 'AUD',
    '0xc203A12F298CE73E44F7d45A4f59a43DBfFe204D': 'AVAX',
    '0x3a52b21816168dfe35bE99b7C5fc209f17a0aDb1': 'AXS',
    '0x71f42cA320b3e9A8e4816e26De70c9b69eAf9d24': 'BAL',
    '0x96690aAe7CB7c4A9b5Be5695E94d72827DeCC33f': 'BCH',
    '0xa1Ace9ce6862e865937939005b1a6c5aC938A11F': 'BLUR',
    '0x0940B0A96C5e1ba33AEE331a9f950Bb2a6F2Fb25': 'BNB',
    '0x59b007E9ea8F89b069c43F8f45834d30853e3699': 'BTC',
    '0x2292865b2b6C837B7406E819200CE61c1c4F8d43': 'CELO',
    '0xb7059Ed9950f2D9fDc0155fC0D79e63d4441e806': 'COMP',
    '0xD5fBf7136B86021eF9d0BE5d798f948DcE9C0deA': 'CRV',
    '0x98cCbC721cc05E28a125943D69039B39BE6A21e9': 'DOGE',
    '0x8B9B5f94aac2316f048025B3cBe442386E85984b': 'DOT',
    '0x139F94E4f0e1101c1464a321CBA815c34d58B5D9': 'DYDX',
    '0x88C8316E5CCCCE2E27e5BFcDAC99f1251246196a': 'ENJ',
    '0x50a40d947726ac1373DC438e7aaDEde9b237564d': 'EOS',
    '0x4bF3C1Af0FaA689e3A808e6Ad7a8d89d07BB9EC7': 'ETC',
    '0xD5FcCd43205CEF11FbaF9b38dF15ADbe1B186869': 'ETHBTC',
    '0x2B3bb4c683BFc5239B029131EEf3B1d214478d93': 'ETH',
    '0x87AE62c5720DAB812BDacba66cc24839440048d1': 'EUR',
    '0x2C5E2148bF3409659967FE3684fd999A76171235': 'FIL',
    '0x5ed8D0946b59d015f5A60039922b870537d43689': 'FLOKI',
    '0x27665271210aCff4Fab08AD9Bb657E91866471F0': 'FLOW',
    '0xC18f85A6DD3Bcd0516a1CA08d3B1f0A4E191A2C4': 'FTM',
    '0x2fD9a39ACF071Aa61f92F3D7A98332c68d6B6602': 'FXS',
    '0x1dAd8808D8aC58a0df912aDC4b215ca3B93D6C49': 'GBP',
    '0x33d4613639603c845e61A02cd3D2A78BE7d513dc': 'GMX',
    '0x105f7F2986A2414B4007958b836904100a53d1AD': 'ICP',
    '0x852210F0616aC226A486ad3387DBF990e690116A': 'INJ',
    '0x152Da6a8F32F25B56A32ef5559d4A2A96D09148b': 'KNC',
    '0xaa94C874b91ef16C8B56A1c5B2F34E39366bD484': 'LDO',
    '0x31A1659Ca00F617E86Dc765B6494Afe70a5A9c1A': 'LINK',
    '0xB25529266D9677E9171BEaf333a0deA506c5F99A': 'LTC',
    '0x074B8F19fc91d6B2eb51143E1f186Ca0DDB88042': 'MATIC',
    '0x572F816F21F56D47e4c4fA577837bd3f58088676': 'MAV',
    '0xf7d9Bd13F877171f6C7f93F71bdf8e380335dc12': 'MKR',
    '0xC8fCd6fB4D15dD7C455373297dEF375a08942eCe': 'NEAR',
    '0x86BbB4E38Ffa64F263E84A0820138c5d938BA86E': 'ONE',
    '0x442b69937a0daf9D46439a71567fABE6Cb69FBaf': 'OP',
    '0x3D3f34416f60f77A0a6cC8e32abe45D32A7497cb': 'PEPE',
    '0xaF2E4c337B038eaFA1dE23b44C163D0008e49EaD': 'PERP',
    '0x91cc4a83d026e5171525aFCAEd020123A653c2C9': 'RNDR',
    '0xfAD0835dAD2985b25ddab17eace356237589E5C7': 'RPL',
    '0xEAf0191bCa9DD417202cEf2B18B7515ABff1E196': 'RUNE',
    '0x66fc48720f09Ac386608FB65ede53Bb220D0D5Bc': 'SEI',
    '0x69F5F465a46f324Fb7bf3fD7c0D5c00f7165C7Ea': 'SHIB',
    '0x0EA09D97b4084d859328ec4bF8eBCF9ecCA26F1D': 'SOL',
    '0x08388dC122A956887c2F736Aaec4A0Ce6f0536Ce': 'STETHETH',
    '0xD91Db82733987513286B81e7115091d96730b62A': 'STETH',
    '0x09F9d7aaa6Bef9598c3b676c0E19C9786Aa566a8': 'SUI',
    '0xdcCDa0cFBEE25B33Ff4Ccca64467E89512511bf6': 'SUSHI',
    '0x031A448F59111000b96F016c37e9c71e57845096': 'TRX',
    '0xb815Eb8D3a9dA3EdDD926225c0FBD3A566e8C749': 'UMA',
    '0x4308427C463CAEAaB50FFf98a9deC569C31E4E87': 'UNI',
    '0x1681212A0Edaf314496B489AB57cB3a5aD7a833f': 'USDT',
    '0x77DA808032dCdd48077FA7c57afbF088713E09aD': 'WLD',
    '0xdcB8438c979fA030581314e5A5Df42bbFEd744a0': 'XAG',
    '0x549dbDFfbd47bD5639f9348eBE82E63e2f9F777A': 'XAU',
    '0xfbbBFA96Af2980aE4014d5D5A2eF14bD79B2a299': 'XLM',
    '0x2ea06E73083f1b3314Fa090eaE4a5F70eb058F2e': 'XMR',
    '0x6110DF298B411a46d6edce72f5CAca9Ad826C1De': 'XRP',
    '0xC645A757DD81C69641e010aDD2Da894b4b7Bc921': 'XTZ',
    '0x6940e7C6125a177b052C662189bb27692E88E9Cb': 'YFI',
    '0xf8aB6B9008f2290965426d3076bC9d2EA835575e': 'ZEC',
    '0x01a43786C2279dC417e7901d45B917afa51ceb9a': 'ZIL',
    '0x76BB1Edf0C55eC68f4C8C7fb3C076b811b1a9b9f': 'ZRX',
  },
};

export const TestnetItems: AddressItems = {
  Deployments: {
    Router: '0x1c9374977b962e865898C01A14129169D34d9970', //'0x3a2573F8aF6217FAB9fEC659a7b2a9A331F036F9',
    BlacklistPolicy: '0xB38a08bE566d3Dff026fF4dC8d4323d3bF7CCeF6', //'0x3250fEE3901247fF70196ABB8fCD7e3190AE3D27',
    OpsHelper: '0x1ef80632107EBE944FDa9d7284d902d7Ec0BB175', // '0x2fff380C4997F15d49B271De58Ef2EA4033A4f77', //'0x25d6eCc72dD1A260689B6f42c8B1b68531470919',
    NavRecords: '0x3AC5605808036C81714d7a9A786FaE1F19509133',
    Vaults: [
      // Updown
      {
        Vault: '0xd78d77bB62E9bCDc003f84cf90f5Ed2538f01419', // '0xA8e11543Cb1bb1BB958865C0E32c58f71d87E73f', //'0xc9d5500830Db479cc5Db902f5D91e68879C5D125',
        OpsManager: '0x68698bEc4f14df2dcBe90163163BAb63ECd85e61', // '0xe0eea914F057CF4Ca218e255E4a49d073d261BF5', //'0x36daa5C17c45010613E42bdB3979c25Dc15AD8e1',
      },
      // Neutral
      {
        Vault: '0xB0B11EDC2Aa78eDA4A6bE77fC8Cd3f0601BF262A',
        OpsManager: '0xCB5645DDD12e028F3CfAfdE21e771540438b7B15',
      },
      // Up
      {
        Vault: '0x2C0992A9a3Ce2d17873682b652D03CaDD346629f', // '0x7a1a85161675581Fb8a611d13a2d463Ac3424687',
        OpsManager: '0xFfa59Bf8431A3F6962a0bEA8E74F9EE3E02532EE', // '0xccb65d629e9F573f3b389A74D39489DE059163F5',
      },
      // Down
      {
        Vault: '0x14fbe03Cf62EadABAb9ad3aDF01A8b3f0CC9fd90',
        OpsManager: '0xB810d283F6de50cf214f749C9B11eBf8aF49DfEb',
      },
    ],
  },
  Assets: {
    SUSD: '0xeBaEAAD9236615542844adC5c149F86C36aD1136',
    ONEINCH: '0x98D9C1A153a8396db8D928e5a375560d72961852',
    AAVE: '0x3410215D8A0BD57dAc5911785F2A832402D5c828',
    ADA: '0xa28bd9971a920563828E3e26681Cb4820A829300',
    ALGO: '0xB4601F5A308071f0583C3F5D481B29Da63287ff0',
    APE: '0xeff373efBD352531F680575796AE14764bE0391B',
    APT: '0x1E236dA716cbB7bC6C4246b8b07263BEb7522f70',
    ARB: '0xa101C726335bDA98E5Fd73967B31063D5aa48aD8',
    ATOM: '0xcCdc541a12CA359216913c1893C080d951874346',
    // AUD: '0x95b78e2E07090587754f3088Ef8a8232f1Ab7E47',
    AVAX: '0xe140356AB1F0558e020610C9C6BccdAA4FDDE2f4',
    AXS: '0x9CE0556a563f18AeA0E89F407B0b1710F095956f',
    BAL: '0x493DbABc3e2173463A51a4d7a8c25009cA105D59',
    BCH: '0x7de607f45ea85512689566D786e513aF384D6CBb',
    BLUR: '0xA21e82290b468CC3A26319132E1f382004E83a76',
    BNB: '0x307072038D47bAE97CaE56C0eA87F2a5f0CD8389',
    BTC: '0xd5844EA3701a4507C27ebc5EBA733E1Aa2915B31',
    CELO: '0x18ceb55c8f4cb4Bb4d0285A28bc60dD4deC05719',
    COMP: '0xA678885BcED8d184132eB35377b5D5927a41d597',
    CRV: '0xF1c1862101916d19d3EF7721B09f02854EAc1266',
    DOGE: '0x3A4D5262b10C670a06550FCf7346cd408343B3FB',
    DOT: '0xC069D9cb017af5697eA33C6fcEd0DBAa1F706198',
    DYDX: '0xb97e868a340BA00C10557c234C9F17cC41B0f667',
    ENJ: '0x2E7d38c390fA8aB9bE5fFeACB29160b79Fa50F27',
    EOS: '0xbBb1d14d35D0247E2b833cab54e8E8B4a71029c3',
    ETC: '0x2FBB6cbe38889Dd11b7759C890079396e2e58334',
    // ETHBTC: '0x52636AfbA57448f4A4a17c2BD0D629F64735f0A1',
    ETH: '0x111BAbcdd66b1B60A20152a2D3D06d36F8B5703c',
    // EUR: '0x9C54994933205E33628A8870c05AFF0878b1A56b',
    FIL: '0xEC9AB8399E4Ba4fcb7E03A6cc5d5Dd485b72fC85',
    FLOKI: '0xB9E66de34695c790972F7D865b45D2A0b08a2356',
    FLOW: '0xcFA34059b55E1a1c820e4D62A6CA4f8e00522eBB',
    FTM: '0x70362529cCfF83f586EB48e978eF3b60384cE050',
    FXS: '0xeC8CD1e4B391a4D90bD0D44fD1C163f2Dd9A8182',
    // GBP: '0x534181B37bdaFdD1E28104Bc5117184F40e1056F',
    GMX: '0x662Ce20c34f9Eff955bADbdcFA7641D095a96252',
    ICP: '0xc02A7b898096D197Aedbd028aDa1dB6f8F50B469',
    INJ: '0x584576Cf989c0Aac6cC059102E8E70Ec87d1a1F2',
    KNC: '0xa12BE8272bBf3138A8cbc2a2C118915192BE1797',
    LDO: '0x1DB4EBe25E1081487D89492a8b7fd38f0CBA22Fa',
    LINK: '0x6141dcfF3494921e1C4Cdb115daD20C6656f6EFA',
    LTC: '0xd2e7D43f0A4c482DAb4cB1E76EAD47Dc7B79Da4B',
    MATIC: '0x7bBEa20899d358ed6d877f32af1BCb525a5fCF31',
    MAV: '0x5DedE7103b2205545aF09a6e243126D60801D14F',
    MKR: '0x91aE32606C8929769AFd54397F6515b9509f3EBa',
    NEAR: '0xf503e527854b510C1952425d4b61c6bba40028CE',
    ONE: '0x653312A4de349a3E8F752794285857665A961373',
    OP: '0x4926222EDDa82965Aa08080f281928f8cba5922A',
    PEPE: '0x0E7C0B288C14E29eb3125C0812687E1d4F14F6bC',
    PERP: '0x945e9913f1E31E6a8A73fad220899B96B854A77A',
    RNDR: '0x1455B8D6E727e71995e638166394A79aD2AC3558',
    RPL: '0x452D1e4960d330fDE2B5D7Ec94dCC52F0608D78e',
    RUNE: '0x4D96363516F75f597719662Da273A6D6fC5BB109',
    SEI: '0x035A18d0D7c6E29BB6cabB459C1d1ccCdBfe77A2',
    SHIB: '0xBF0dEA3A088bAdd3A40ef8A0C5F148CDB686AA6F',
    SOL: '0x62068eBDCEbcB0eB984aBfEa4c7f9A244050e0Ca',
    // STETHETH: '0x3A512e9050EA62BF4444b12ad2B066c03384d7b3',
    STETH: '0x896dEc99176a0fc3160CB5796ADe25ACe8f2A7D4',
    SUI: '0xCE09B4b71F46cC6Fa9b137943aE1803395A6B2fC',
    SUSHI: '0x64b9F72F7D5a78B597A108aaE5fE2dCB17a6B1F3',
    TRX: '0xB2A2a1Cf6f59Cc711Eb2eC81765aC65e4e4bB3f5',
    UMA: '0xf31C13ef6D7B6De0902a1dD0B82723AF1445BCBf',
    UNI: '0xaF11B4281259D7Ae31F945a2911Ba75347C2799d',
    USDT: '0x90cCe6Ea3c6CE1280A679E2c0007A2bA4Ae0E31F',
    WLD: '0x843E61b441f9c7Da31495227F9914B9053f2A043',
    // XAG: '0x78D1232449387571D652E2a893DC0feaC6E92436',
    // XAU: '0xcC312F5Bac1C36CC70AbcbE76De913633Af88FFB',
    XLM: '0x293a54bA6928B70591c201D46D4866c843A288E9',
    XMR: '0x915074a4193A703e10fa73CE605F3773B2f522A0',
    XRP: '0xa649754A8Ad0Be3fa745A592aa435bD4F9908a1a',
    XTZ: '0x27166831c59156CC5bB560474cf52A59F3187228',
    YFI: '0xd2AB53d5D618c16801D4Dc018Dee8aE6B902BA01',
    ZEC: '0x2c936245aE304C12492e2fD11f9f6ca811Dca6B2',
    ZIL: '0xeBBF262CBF76BD434873C278C05249A863ed93f3',
    ZRX: '0x2E6c40D36fbAe7c924d1Bd9c673F0de8a3734698',
  },
  MarketSettings: '0xedf10514EF611e3808622f24e236b83cB9E51dCe',
  Symbol: {
    '0xeBaEAAD9236615542844adC5c149F86C36aD1136': 'SUSD',
    '0x98D9C1A153a8396db8D928e5a375560d72961852': '1INCH',
    '0x3410215D8A0BD57dAc5911785F2A832402D5c828': 'AAVE',
    '0xa28bd9971a920563828E3e26681Cb4820A829300': 'ADA',
    '0xB4601F5A308071f0583C3F5D481B29Da63287ff0': 'ALGO',
    '0xeff373efBD352531F680575796AE14764bE0391B': 'APE',
    '0x1E236dA716cbB7bC6C4246b8b07263BEb7522f70': 'APT',
    '0xa101C726335bDA98E5Fd73967B31063D5aa48aD8': 'ARB',
    '0xcCdc541a12CA359216913c1893C080d951874346': 'ATOM',
    '0x95b78e2E07090587754f3088Ef8a8232f1Ab7E47': 'AUD',
    '0xe140356AB1F0558e020610C9C6BccdAA4FDDE2f4': 'AVAX',
    '0x9CE0556a563f18AeA0E89F407B0b1710F095956f': 'AXS',
    '0x493DbABc3e2173463A51a4d7a8c25009cA105D59': 'BAL',
    '0x7de607f45ea85512689566D786e513aF384D6CBb': 'BCH',
    '0xA21e82290b468CC3A26319132E1f382004E83a76': 'BLUR',
    '0x307072038D47bAE97CaE56C0eA87F2a5f0CD8389': 'BNB',
    '0xd5844EA3701a4507C27ebc5EBA733E1Aa2915B31': 'BTC',
    '0x18ceb55c8f4cb4Bb4d0285A28bc60dD4deC05719': 'CELO',
    '0xA678885BcED8d184132eB35377b5D5927a41d597': 'COMP',
    '0xF1c1862101916d19d3EF7721B09f02854EAc1266': 'CRV',
    '0x3A4D5262b10C670a06550FCf7346cd408343B3FB': 'DOGE',
    '0xC069D9cb017af5697eA33C6fcEd0DBAa1F706198': 'DOT',
    '0xb97e868a340BA00C10557c234C9F17cC41B0f667': 'DYDX',
    '0x2E7d38c390fA8aB9bE5fFeACB29160b79Fa50F27': 'ENJ',
    '0xbBb1d14d35D0247E2b833cab54e8E8B4a71029c3': 'EOS',
    '0x2FBB6cbe38889Dd11b7759C890079396e2e58334': 'ETC',
    '0x52636AfbA57448f4A4a17c2BD0D629F64735f0A1': 'ETHBTC',
    '0x111BAbcdd66b1B60A20152a2D3D06d36F8B5703c': 'ETH',
    '0x9C54994933205E33628A8870c05AFF0878b1A56b': 'EUR',
    '0xEC9AB8399E4Ba4fcb7E03A6cc5d5Dd485b72fC85': 'FIL',
    '0xB9E66de34695c790972F7D865b45D2A0b08a2356': 'FLOKI',
    '0xcFA34059b55E1a1c820e4D62A6CA4f8e00522eBB': 'FLOW',
    '0x70362529cCfF83f586EB48e978eF3b60384cE050': 'FTM',
    '0xeC8CD1e4B391a4D90bD0D44fD1C163f2Dd9A8182': 'FXS',
    '0x534181B37bdaFdD1E28104Bc5117184F40e1056F': 'GBP',
    '0x662Ce20c34f9Eff955bADbdcFA7641D095a96252': 'GMX',
    '0xc02A7b898096D197Aedbd028aDa1dB6f8F50B469': 'ICP',
    '0x584576Cf989c0Aac6cC059102E8E70Ec87d1a1F2': 'INJ',
    '0xa12BE8272bBf3138A8cbc2a2C118915192BE1797': 'KNC',
    '0x1DB4EBe25E1081487D89492a8b7fd38f0CBA22Fa': 'LDO',
    '0x6141dcfF3494921e1C4Cdb115daD20C6656f6EFA': 'LINK',
    '0xd2e7D43f0A4c482DAb4cB1E76EAD47Dc7B79Da4B': 'LTC',
    '0x7bBEa20899d358ed6d877f32af1BCb525a5fCF31': 'MATIC',
    '0x5DedE7103b2205545aF09a6e243126D60801D14F': 'MAV',
    '0x91aE32606C8929769AFd54397F6515b9509f3EBa': 'MKR',
    '0xf503e527854b510C1952425d4b61c6bba40028CE': 'NEAR',
    '0x653312A4de349a3E8F752794285857665A961373': 'ONE',
    '0x4926222EDDa82965Aa08080f281928f8cba5922A': 'OP',
    '0x0E7C0B288C14E29eb3125C0812687E1d4F14F6bC': 'PEPE',
    '0x945e9913f1E31E6a8A73fad220899B96B854A77A': 'PERP',
    '0x1455B8D6E727e71995e638166394A79aD2AC3558': 'RNDR',
    '0x452D1e4960d330fDE2B5D7Ec94dCC52F0608D78e': 'RPL',
    '0x4D96363516F75f597719662Da273A6D6fC5BB109': 'RUNE',
    '0x035A18d0D7c6E29BB6cabB459C1d1ccCdBfe77A2': 'SEI',
    '0xBF0dEA3A088bAdd3A40ef8A0C5F148CDB686AA6F': 'SHIB',
    '0x62068eBDCEbcB0eB984aBfEa4c7f9A244050e0Ca': 'SOL',
    '0x3A512e9050EA62BF4444b12ad2B066c03384d7b3': 'STETHETH',
    '0x896dEc99176a0fc3160CB5796ADe25ACe8f2A7D4': 'STETH',
    '0xCE09B4b71F46cC6Fa9b137943aE1803395A6B2fC': 'SUI',
    '0x64b9F72F7D5a78B597A108aaE5fE2dCB17a6B1F3': 'SUSHI',
    '0xB2A2a1Cf6f59Cc711Eb2eC81765aC65e4e4bB3f5': 'TRX',
    '0xf31C13ef6D7B6De0902a1dD0B82723AF1445BCBf': 'UMA',
    '0xaF11B4281259D7Ae31F945a2911Ba75347C2799d': 'UNI',
    '0x90cCe6Ea3c6CE1280A679E2c0007A2bA4Ae0E31F': 'USDT',
    '0x843E61b441f9c7Da31495227F9914B9053f2A043': 'WLD',
    '0x78D1232449387571D652E2a893DC0feaC6E92436': 'XAG',
    '0xcC312F5Bac1C36CC70AbcbE76De913633Af88FFB': 'XAU',
    '0x293a54bA6928B70591c201D46D4866c843A288E9': 'XLM',
    '0x915074a4193A703e10fa73CE605F3773B2f522A0': 'XMR',
    '0xa649754A8Ad0Be3fa745A592aa435bD4F9908a1a': 'XRP',
    '0x27166831c59156CC5bB560474cf52A59F3187228': 'XTZ',
    '0xd2AB53d5D618c16801D4Dc018Dee8aE6B902BA01': 'YFI',
    '0x2c936245aE304C12492e2fD11f9f6ca811Dca6B2': 'ZEC',
    '0xeBBF262CBF76BD434873C278C05249A863ed93f3': 'ZIL',
    '0x2E6c40D36fbAe7c924d1Bd9c673F0de8a3734698': 'ZRX',
  },
};

export const LocalnetItems: AddressItems = {
  Deployments: {
    Router: '0x4581e6f6630ce21fe584b6971A542b3370d266c4',
    BlacklistPolicy: '0xDe2f8D8453Fb92E3fE39Fc8B75e35bbb6E208a81',
    OpsHelper: '0x88F37A045172107974a03070d792288322653940',
    NavRecords: '',
    Vaults: [
      {
        Vault: '0x796Fddce4F114Eb7B7210471378A1d502638b157',
        OpsManager: '0x8e4D4C9fCa72911E8E8244E7f52Fb3c0C329707b',
      },
    ],
  },
  Assets: {
    SUSD: '0xE7eD6747FaC5360f88a2EFC03E00d25789F69291',
    ASSET0: '0x6951b5Bd815043E3F842c1b026b0Fa888Cc2DD85',
    ASSET1: '0xe0aA552A10d7EC8760Fc6c246D391E698a82dDf9',
    ASSET2: '0x6b4BDe1086912A6Cb24ce3dB43b3466e6c72AFd3',
    ASSET3: '0x9E4c14403d7d9A8A782044E86a93CAE09D7B2ac9',
    ASSET4: '0xcCB53c9429d32594F404d01fbe9E65ED1DCda8D9',
    ASSET5: '0x420b1099B9eF5baba6D92029594eF45E19A04A4A',
    ASSET6: '0xa3B53dDCd2E3fC28e8E130288F2aBD8d5EE37472',
    ASSET7: '0xb6286fAFd0451320ad6A8143089b216C2152c025',
    ASSET8: '0x7a3d735ee6873f17Dbdcab1d51B604928dc10d92',
    ASSET9: '0x2c15A315610Bfa5248E4CbCbd693320e9D8E03Cc',
    ASSET10: '0xe692Cf21B12e0B2717C4bF647F9768Fa58861c8b',
    ASSET11: '0xe65A7a341978d59d40d30FC23F5014FACB4f575A',
    ASSET12: '0x30375B532345B01cB8c2AD12541b09E9Aa53A93d',
    ASSET13: '0x26f15335BB1C6a4C0B660eDd694a0555A9F1cce3',
    ASSET14: '0xFbD588c72B438faD4Cf7cD879c8F730Faa213Da0',
    ASSET15: '0xed00238F9A0F7b4d93842033cdF56cCB32C781c2',
    ASSET16: '0xDae02e4fE488952cFB8c95177154D188647a0146',
    ASSET17: '0xdCF93F11ef216cEC9C07fd31dD801c9b2b39Afb4',
    ASSET18: '0xBcb61491F1859f53438918F1A5aFCA542Af9D397',
    ASSET19: '0xD22363efee93190f82b52FCD62B7Dbcb920eF658',
    ASSET20: '0x4D1B781ce59B8C184F63B99D39d6719A522f46B5',
    ASSET21: '0xf9C8Cf55f2E520B08d869df7bc76aa3d3ddDF913',
    ASSET22: '0x654f70d8442EA18904FA1AD79114f7250F7E9336',
    ASSET23: '0xADeD61D42dE86f9058386D1D0d739d20C7eAfC43',
    ASSET24: '0x832698Daec363C9A7aB036C224Af5B21280b3AC6',
    ASSET25: '0xA95916C3D979400C7443961330b3092510a229Ba',
    ASSET26: '0x42E8D004c84E6B5Bad559D3b5CE7947AADb9E0bc',
    ASSET27: '0xF06D5f5BfFFCB6a52c84cfebc03AD35637728E73',
    ASSET28: '0x82c83b7f88aef2eD99d4869D547b6ED28e69C8df',
    ASSET29: '0x724Ca58E1e6e64BFB1E15d7Eec0fe1E5f581c7bD',
    ASSET30: '0x34b97ffa01dc0DC959c5f1176273D0de3be914C1',
    ASSET31: '0xc830Ad2FDfCC2f368fE5DeC93b1Dc72ecABb3691',
    ASSET32: '0xbc8eCccb89650c3E796e803CB009BF9b898CB359',
    ASSET33: '0x741e3E1f81041c62C2A97d0b6E567AcaB09A6232',
    ASSET34: '0x4B0FccF53589c1F185B35db88bB315a0bBF9a3e0',
    ASSET35: '0xFE0F4Cf81B5c0a6Fd65a610FD9488F33aE9095cB',
    ASSET36: '0x4e3E7dC9D84dA7BE8f017f4C36153A61341736d4',
    ASSET37: '0x0AC45e945A008D3fc19da8f591be8601C1F63130',
    ASSET38: '0x5847798CE8c89e3Fff59AE5fA30BEC0d406b5687',
    ASSET39: '0x0C60536783db9ED5A2B216970B10FF2243d317dD',
    ASSET40: '0x0e19E87b363DD7a3af5c45C95ab6885367251B94',
    ASSET41: '0xaA7e46855e0506401214c9b1C35f3d889669609e',
    ASSET42: '0xa1910C6e0Fbd0E38cfBabAeC5D1C1D539F81CC63',
    ASSET43: '0x8BE16B874F47371C42498b499837Ed87D7661E86',
    ASSET44: '0x1aa96efd2B002541E830CB7f60e473AA24e31F9A',
    ASSET45: '0x8b1B440724DCe2EE9779B58af841Ec59F545838B',
    ASSET46: '0xC6D563d5c2243b27e7294511063f563ED701EA2C',
    ASSET47: '0xD537bF4b795b7D07Bd5F4bAf7017e3ce8360B1DE',
    ASSET48: '0x70bC6D873D110Da59a9c49E7485a27B0F605E5db',
    ASSET49: '0xFb7C5F938835aE34aF48c278C6763E134907Acdb',
    ASSET50: '0xb291024F4b2E4C3767A96aB44566a8e93cC828C5',
    ASSET51: '0xEfE66132727f3831AB4E020357B5Bf615076Df6A',
    ASSET52: '0xEFDE7eC452f9A203559896F64267809E248D044f',
    ASSET53: '0x93F6bA0E6DcB1Ec0F1aBD80Be31e025d6e64Ad97',
    ASSET54: '0x342216ff4b4D6899DDE6535D6E50b6Db71654bc4',
    ASSET55: '0xa5659Fa683b71C907cd346397c694b93aFF40b51',
    ASSET56: '0x286677F2b204be093ABBee3e35074352b339243B',
    ASSET57: '0xA83Fad7514B39b12C0bAcA71e0A7cc63D2Ff1941',
    ASSET58: '0x64B334435888bb5E44D890a7B319981c4Bb4B47d',
    ASSET59: '0xFB87C36009603855163ABe3BbF512D3F58fd3819',
    ASSET60: '0x0764A8da0FCAa127b31E13b79E9a273Fb5B2bF26',
    ASSET61: '0x2acC1AbFdC76E48e49c91f55512066B4DD6555Dd',
    ASSET62: '0xFd87ceEa79b211268Fb182a48E43644543c08709',
    ASSET63: '0x7e4ED6273177126c48503850a526B45bed16E747',
    ASSET64: '0xA2ea57c9C7ED08D48F5d92Dd774b6Fe55465d86f',
    ASSET65: '0x6a21483D01cFb6FAf0D5346C1e781337425D713B',
    ASSET66: '0x7BCbd5DCBE1d42458D8C5b2397C68d1819BE8066',
    ASSET67: '0xaE4059E2E23958f627eF75d2467343F4d8838709',
    ASSET68: '0x47a27C9881B5C5eC78c38FBBEE7DD0A5eb68633C',
    ASSET69: '0x63Cf24e22bbFAA28316Cca9b179eEC7810b8037B',
    ASSET70: '0x8dB41305cA5f2852135dC2B969ba6084A32D4700',
    ASSET71: '0x795af5f6648254fFAA8fCd35ff03a4dCEe5054c9',
    ASSET72: '0x8cdA0807D6251FED06361710b50f5592C6Af74f5',
    ASSET73: '0x2E51a0DB587eadCF8495B41Bf3FC9f4F706d0028',
    ASSET74: '0x6057028AEb3086440A01577fcD18C5eC77305c47',
    ASSET75: '0xe25EDE8b52d4DE741Bd61c30060a003f0F1151A5',
    ASSET76: '0xc859A0721d3EF2449556a85753B177Ac37bb3666',
    ASSET77: '0xfADA48d21dD3c18FA2511F84Ca81aB3c5Cfd0c9D',
    ASSET78: '0x741D86951a8b99159be52644e60de7826033Ee44',
    ASSET79: '0x953d9B708E446dcC9257d900C7cFc34Db2969A57',
    ASSET80: '0x4f53C468a3AaC1e528C2AB15f18D777D083d642E',
    ASSET81: '0x5F589a452BEF32ae54Fbc46968505867cEFbC537',
    ASSET82: '0x54BA761038f133140659d8a5a4375b479D7c5582',
    ASSET83: '0x5F6F5B3b1b4d2B448bcbffA9b92168469ab2e142',
    ASSET84: '0x6A547C98A4363b71F74472aD25595F4677a8b94B',
    ASSET85: '0x1c7f458653173531D50a0C6c85a8e390D09E5D4E',
    ASSET86: '0x12429A7C54a315b8325Ae6105417b04BEE258a27',
    ASSET87: '0xe372E377902f1872Aaa458989B229974896022D2',
    ASSET88: '0x062B7dAb37A00F0671Ad9D2Af2950CaC947FC35b',
    ASSET89: '0xBD01D701541D96399c338C9D66D4AE7bCAd878a2',
    ASSET90: '0x3b3F002a26fFcbdd72F58B4bafF73833a2420FFf',
    ASSET91: '0x9E2341550D43dac781A6c36fbdcBb3590bd1BC08',
    ASSET92: '0xE67cf20A24c85f0417070668123102247a6cfcC1',
    ASSET93: '0xFE78A958963c513B22337B669EdaF764477abd6E',
    ASSET94: '0x683554B7d4a805c1B0A6Da16bbb85707210Cce0E',
    ASSET95: '0xc44163a3C9a048c7EdC2A74299146173eaF436AF',
    ASSET96: '0xa2c283F11108596AB76e47af4e487Ec5D9d6c5BF',
    ASSET97: '0x2711D43E99752B654E6925861Aec015E1F635cBb',
    ASSET98: '0x2847751f6a9902c2AB6e3C319901aCaC184f81b6',
    ASSET99: '0xc9D90614566289F796Eb9272a5ed37B8e311eC38',
  },
  MarketSettings: '0x3194cBDC3dbcd3E11a07892e7bA5c3394048Cc87',
  Symbol: {
    '0xE7eD6747FaC5360f88a2EFC03E00d25789F69291': 'SUSD',
    '0x6951b5Bd815043E3F842c1b026b0Fa888Cc2DD85': 'ASSET0',
    '0xe0aA552A10d7EC8760Fc6c246D391E698a82dDf9': 'ASSET1',
    '0x6b4BDe1086912A6Cb24ce3dB43b3466e6c72AFd3': 'ASSET2',
    '0x9E4c14403d7d9A8A782044E86a93CAE09D7B2ac9': 'ASSET3',
    '0xcCB53c9429d32594F404d01fbe9E65ED1DCda8D9': 'ASSET4',
    '0x420b1099B9eF5baba6D92029594eF45E19A04A4A': 'ASSET5',
    '0xa3B53dDCd2E3fC28e8E130288F2aBD8d5EE37472': 'ASSET6',
    '0xb6286fAFd0451320ad6A8143089b216C2152c025': 'ASSET7',
    '0x7a3d735ee6873f17Dbdcab1d51B604928dc10d92': 'ASSET8',
    '0x2c15A315610Bfa5248E4CbCbd693320e9D8E03Cc': 'ASSET9',
    '0xe692Cf21B12e0B2717C4bF647F9768Fa58861c8b': 'ASSET10',
    '0xe65A7a341978d59d40d30FC23F5014FACB4f575A': 'ASSET11',
    '0x30375B532345B01cB8c2AD12541b09E9Aa53A93d': 'ASSET12',
    '0x26f15335BB1C6a4C0B660eDd694a0555A9F1cce3': 'ASSET13',
    '0xFbD588c72B438faD4Cf7cD879c8F730Faa213Da0': 'ASSET14',
    '0xed00238F9A0F7b4d93842033cdF56cCB32C781c2': 'ASSET15',
    '0xDae02e4fE488952cFB8c95177154D188647a0146': 'ASSET16',
    '0xdCF93F11ef216cEC9C07fd31dD801c9b2b39Afb4': 'ASSET17',
    '0xBcb61491F1859f53438918F1A5aFCA542Af9D397': 'ASSET18',
    '0xD22363efee93190f82b52FCD62B7Dbcb920eF658': 'ASSET19',
    '0x4D1B781ce59B8C184F63B99D39d6719A522f46B5': 'ASSET20',
    '0xf9C8Cf55f2E520B08d869df7bc76aa3d3ddDF913': 'ASSET21',
    '0x654f70d8442EA18904FA1AD79114f7250F7E9336': 'ASSET22',
    '0xADeD61D42dE86f9058386D1D0d739d20C7eAfC43': 'ASSET23',
    '0x832698Daec363C9A7aB036C224Af5B21280b3AC6': 'ASSET24',
    '0xA95916C3D979400C7443961330b3092510a229Ba': 'ASSET25',
    '0x42E8D004c84E6B5Bad559D3b5CE7947AADb9E0bc': 'ASSET26',
    '0xF06D5f5BfFFCB6a52c84cfebc03AD35637728E73': 'ASSET27',
    '0x82c83b7f88aef2eD99d4869D547b6ED28e69C8df': 'ASSET28',
    '0x724Ca58E1e6e64BFB1E15d7Eec0fe1E5f581c7bD': 'ASSET29',
    '0x34b97ffa01dc0DC959c5f1176273D0de3be914C1': 'ASSET30',
    '0xc830Ad2FDfCC2f368fE5DeC93b1Dc72ecABb3691': 'ASSET31',
    '0xbc8eCccb89650c3E796e803CB009BF9b898CB359': 'ASSET32',
    '0x741e3E1f81041c62C2A97d0b6E567AcaB09A6232': 'ASSET33',
    '0x4B0FccF53589c1F185B35db88bB315a0bBF9a3e0': 'ASSET34',
    '0xFE0F4Cf81B5c0a6Fd65a610FD9488F33aE9095cB': 'ASSET35',
    '0x4e3E7dC9D84dA7BE8f017f4C36153A61341736d4': 'ASSET36',
    '0x0AC45e945A008D3fc19da8f591be8601C1F63130': 'ASSET37',
    '0x5847798CE8c89e3Fff59AE5fA30BEC0d406b5687': 'ASSET38',
    '0x0C60536783db9ED5A2B216970B10FF2243d317dD': 'ASSET39',
    '0x0e19E87b363DD7a3af5c45C95ab6885367251B94': 'ASSET40',
    '0xaA7e46855e0506401214c9b1C35f3d889669609e': 'ASSET41',
    '0xa1910C6e0Fbd0E38cfBabAeC5D1C1D539F81CC63': 'ASSET42',
    '0x8BE16B874F47371C42498b499837Ed87D7661E86': 'ASSET43',
    '0x1aa96efd2B002541E830CB7f60e473AA24e31F9A': 'ASSET44',
    '0x8b1B440724DCe2EE9779B58af841Ec59F545838B': 'ASSET45',
    '0xC6D563d5c2243b27e7294511063f563ED701EA2C': 'ASSET46',
    '0xD537bF4b795b7D07Bd5F4bAf7017e3ce8360B1DE': 'ASSET47',
    '0x70bC6D873D110Da59a9c49E7485a27B0F605E5db': 'ASSET48',
    '0xFb7C5F938835aE34aF48c278C6763E134907Acdb': 'ASSET49',
    '0xb291024F4b2E4C3767A96aB44566a8e93cC828C5': 'ASSET50',
    '0xEfE66132727f3831AB4E020357B5Bf615076Df6A': 'ASSET51',
    '0xEFDE7eC452f9A203559896F64267809E248D044f': 'ASSET52',
    '0x93F6bA0E6DcB1Ec0F1aBD80Be31e025d6e64Ad97': 'ASSET53',
    '0x342216ff4b4D6899DDE6535D6E50b6Db71654bc4': 'ASSET54',
    '0xa5659Fa683b71C907cd346397c694b93aFF40b51': 'ASSET55',
    '0x286677F2b204be093ABBee3e35074352b339243B': 'ASSET56',
    '0xA83Fad7514B39b12C0bAcA71e0A7cc63D2Ff1941': 'ASSET57',
    '0x64B334435888bb5E44D890a7B319981c4Bb4B47d': 'ASSET58',
    '0xFB87C36009603855163ABe3BbF512D3F58fd3819': 'ASSET59',
    '0x0764A8da0FCAa127b31E13b79E9a273Fb5B2bF26': 'ASSET60',
    '0x2acC1AbFdC76E48e49c91f55512066B4DD6555Dd': 'ASSET61',
    '0xFd87ceEa79b211268Fb182a48E43644543c08709': 'ASSET62',
    '0x7e4ED6273177126c48503850a526B45bed16E747': 'ASSET63',
    '0xA2ea57c9C7ED08D48F5d92Dd774b6Fe55465d86f': 'ASSET64',
    '0x6a21483D01cFb6FAf0D5346C1e781337425D713B': 'ASSET65',
    '0x7BCbd5DCBE1d42458D8C5b2397C68d1819BE8066': 'ASSET66',
    '0xaE4059E2E23958f627eF75d2467343F4d8838709': 'ASSET67',
    '0x47a27C9881B5C5eC78c38FBBEE7DD0A5eb68633C': 'ASSET68',
    '0x63Cf24e22bbFAA28316Cca9b179eEC7810b8037B': 'ASSET69',
    '0x8dB41305cA5f2852135dC2B969ba6084A32D4700': 'ASSET70',
    '0x795af5f6648254fFAA8fCd35ff03a4dCEe5054c9': 'ASSET71',
    '0x8cdA0807D6251FED06361710b50f5592C6Af74f5': 'ASSET72',
    '0x2E51a0DB587eadCF8495B41Bf3FC9f4F706d0028': 'ASSET73',
    '0x6057028AEb3086440A01577fcD18C5eC77305c47': 'ASSET74',
    '0xe25EDE8b52d4DE741Bd61c30060a003f0F1151A5': 'ASSET75',
    '0xc859A0721d3EF2449556a85753B177Ac37bb3666': 'ASSET76',
    '0xfADA48d21dD3c18FA2511F84Ca81aB3c5Cfd0c9D': 'ASSET77',
    '0x741D86951a8b99159be52644e60de7826033Ee44': 'ASSET78',
    '0x953d9B708E446dcC9257d900C7cFc34Db2969A57': 'ASSET79',
    '0x4f53C468a3AaC1e528C2AB15f18D777D083d642E': 'ASSET80',
    '0x5F589a452BEF32ae54Fbc46968505867cEFbC537': 'ASSET81',
    '0x54BA761038f133140659d8a5a4375b479D7c5582': 'ASSET82',
    '0x5F6F5B3b1b4d2B448bcbffA9b92168469ab2e142': 'ASSET83',
    '0x6A547C98A4363b71F74472aD25595F4677a8b94B': 'ASSET84',
    '0x1c7f458653173531D50a0C6c85a8e390D09E5D4E': 'ASSET85',
    '0x12429A7C54a315b8325Ae6105417b04BEE258a27': 'ASSET86',
    '0xe372E377902f1872Aaa458989B229974896022D2': 'ASSET87',
    '0x062B7dAb37A00F0671Ad9D2Af2950CaC947FC35b': 'ASSET88',
    '0xBD01D701541D96399c338C9D66D4AE7bCAd878a2': 'ASSET89',
    '0x3b3F002a26fFcbdd72F58B4bafF73833a2420FFf': 'ASSET90',
    '0x9E2341550D43dac781A6c36fbdcBb3590bd1BC08': 'ASSET91',
    '0xE67cf20A24c85f0417070668123102247a6cfcC1': 'ASSET92',
    '0xFE78A958963c513B22337B669EdaF764477abd6E': 'ASSET93',
    '0x683554B7d4a805c1B0A6Da16bbb85707210Cce0E': 'ASSET94',
    '0xc44163a3C9a048c7EdC2A74299146173eaF436AF': 'ASSET95',
    '0xa2c283F11108596AB76e47af4e487Ec5D9d6c5BF': 'ASSET96',
    '0x2711D43E99752B654E6925861Aec015E1F635cBb': 'ASSET97',
    '0x2847751f6a9902c2AB6e3C319901aCaC184f81b6': 'ASSET98',
    '0xc9D90614566289F796Eb9272a5ed37B8e311eC38': 'ASSET99',
  },
};
