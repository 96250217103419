import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';
import { StatisticColor } from '@app/constants/config/statistics';
import * as S from '../statisticsCards/statisticsCard/StatisticsCard/StatisticsCard.styles';
import * as I from '../statisticsCards/statisticsCard/StatisticsInfo/StatisticsInfo.styles';
import * as P from '../statisticsCards/statisticsCard/StatisticsProgress/StatisticsProgress.styles';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { EXPLORER_URL } from '@app/lib/contracts';
import { BASE_COLORS } from '@app/styles/themes/constants';

interface ExploreCardProps {
  title: string;
  value: string;
  spinning: boolean;
  unit?: string;
  address: string;
}

export const ExploreCard: React.FC<ExploreCardProps> = (props: ExploreCardProps) => {
  const { isTablet: isTabletOrHigher } = useResponsive();

  const { t } = useTranslation();

  return (
    <S.StatisticCard padding="0.5rem" $color={'success'}>
      <a href={EXPLORER_URL + '/token/' + props.address} target="_blank" rel="noreferrer">
        <BaseRow wrap={false} gutter={[isTabletOrHigher ? 10 : 5, 0]}>
          <BaseCol flex={10}>
            <BaseRow justify="space-between" align="middle" wrap={false}>
              <BaseCol>
                <BaseSpace
                  wrap={true}
                  direction="horizontal"
                  size={6}
                  style={{ marginTop: '2vh', marginBottom: '2vh' }}
                >
                  <span style={{ fontSize: 'medium', fontWeight: 'bold' }}>{props.title}</span>
                  {/*<I.Text>{props.title}</I.Text>*/}
                </BaseSpace>
              </BaseCol>

              <BaseSpin spinning={props.spinning}>
                <BaseCol style={{ textAlign: 'right', width: '5vh', wordWrap: 'break-word' }}>
                  <P.ValueText>{props.value}</P.ValueText>
                  {/*<br />*/}
                  {/*<P.UnitText>{props.unit}</P.UnitText>*/}
                </BaseCol>
              </BaseSpin>
            </BaseRow>
          </BaseCol>
        </BaseRow>
      </a>
    </S.StatisticCard>
  );
};
