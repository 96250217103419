import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
// import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Dropdown, InputNumber, MenuProps, Popover, Select, Switch } from 'antd';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import {
  activeUser,
  callDeposit,
  callDepositNative,
  cancelDeposit,
  DECIMALS,
  deposit,
  DepositResponse,
  discountTokenAllow,
  getDiscountApproveStatus,
  getSpotBalanceBnb,
  getSpotBalanceERC20,
  getSpotNavs,
  getTokenApproveStatus,
  refresh,
  Request,
  router,
  spotDeposit,
  spotDepositNative,
  switchToBnb,
  tokenAllow,
  UNIT18,
  usdcAllow,
  usdcAllowance,
  VAULT_TYPE,
} from '@app/lib/contracts';
import { BigNumber, ethers } from 'ethers';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import * as P from '@app/components/vaults-dashboard/statisticsCards/statisticsCard/StatisticsProgress/StatisticsProgress.styles';
import { useResponsive } from '@app/hooks/useResponsive';
import { BNB_ASSET_ADDRESSES, BNB_VAULT_ADDRESSES } from '@app/lib/BnbItems';

interface Depositable {
  symbol: string;
  address: string;
  decimals: number;
}

const DEPOSITABLES: { [key: string]: Depositable } = {
  USDT: {
    symbol: 'USDT',
    address: BNB_ASSET_ADDRESSES.USDT,
    decimals: 18,
  },
  USDC: {
    symbol: 'USDC',
    address: BNB_ASSET_ADDRESSES.USDC,
    decimals: 18,
  },
  ETH: {
    symbol: 'ETH',
    address: BNB_ASSET_ADDRESSES.ETH,
    decimals: 18,
  },
  BNB: {
    symbol: 'BNB',
    address: BNB_ASSET_ADDRESSES.BNB,
    decimals: 18,
  },
};

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export interface ISpotMintCardProps {
  spinning: boolean;
  connected: boolean;
  shareSymbol: string;
  selectedSpotVault: number;
}

function roundDown(inputString: string, decimals: number) {
  decimals = decimals || 0;
  const number = Number(inputString);
  return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export const SpotMintCard: React.FC<ISpotMintCardProps> = (props) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [inputValue, setInputValue] = useState<BigNumber>(ethers.utils.parseUnits('0', DECIMALS));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalMessage, setModalMessage] = useState<string>('');
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);
  const [isMRModalOpen, setIsMRModalOpen] = useState<boolean>(false);
  const [allowanceBigNum, setAllowanceBigNum] = useState<ethers.BigNumber>(ethers.BigNumber.from(0));
  const { t } = useTranslation();

  const [depositable, setDepositable] = useState<Depositable>(DEPOSITABLES.USDC);
  const [userAssetBalance, setUserAssetBalance] = useState<string>(`0`);
  const [useDiscount, setUseDiscount] = useState<boolean>(false);
  const [discountStatus, setDiscountStatus] = useState<boolean>(false);
  const [tokenStatus, setTokenStatus] = useState<boolean>(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState<boolean>(false);
  const [needsAllowance, setNeedsAllowance] = useState<boolean>(false);
  const [estMinShares, setEstMinShares] = useState<string>('0');
  const [sharesToMint, setSharesToMint] = useState<string>('0');
  const [amountDec, setAmountDec] = useState<string>('0');
  const [usingDiscount, setUsingDiscount] = useState<boolean>(false);
  const [depositResponse, setDepositResponse] = useState<DepositResponse>({} as DepositResponse);

  const theme = useAppSelector((state) => state.theme.theme);
  const { isTablet: isTabletOrHigher } = useResponsive();

  // init user asset balance. At some point do this in App.tsx.
  useEffect(() => {
    async function init() {
      try {
        await onDropdown(depositable.symbol);
      } catch (error) {
        console.error('Failed to init:', error);
      }
    }
    init();
  }, []);

  const formatNumString = (numString: string) => {
    return Number(numString).toLocaleString(undefined, {
      maximumFractionDigits: 3,
    });
  };

  const formatNum = (request: Request) => {
    let res = '';
    if (request) {
      res = (request.requestAmount + request.requestFeeInUsd).toLocaleString(undefined, {
        // minimumFractionDigits: 2,
        // maximumSignificantDigits: 4,
        maximumFractionDigits: 3,
      });
    }
    return res;
  };

  const getDepositParams = async () => {
    setEstMinShares(`0`);
    setSharesToMint(`0`);
    setAmountDec(`0`);
    setUsingDiscount(false);

    setIsMRModalOpen(true);
    let res: DepositResponse;
    if (depositable.symbol != `BNB`) {
      res = await callDeposit(
        BNB_VAULT_ADDRESSES[props.selectedSpotVault],
        depositable.address,
        ethers.utils.formatEther(inputValue),
        useDiscount,
      );
    } else {
      res = await callDepositNative(
        BNB_VAULT_ADDRESSES[props.selectedSpotVault],
        ethers.utils.formatEther(inputValue),
        useDiscount,
      );
    }

    console.log('%%%%%%%%%%%', res);
    setEstMinShares(formatNumString(res.estMinShares));
    setSharesToMint(formatNumString(res.sharesToMint));
    setAmountDec(formatNumString(res.amountDec));
    setUsingDiscount(res.useDiscount);
    setDepositResponse(res);
  };

  const onDropdown = async (value: string) => {
    setIsLoading(true);
    setDepositable(DEPOSITABLES[value]);
    console.log('%%%%getting userAssetBalance', depositable.address, depositable.symbol);
    if (value != `BNB`) {
      setUserAssetBalance(await getSpotBalanceERC20(DEPOSITABLES[value].address));
    } else {
      setUserAssetBalance(await getSpotBalanceBnb());
    }
    console.log(`%%%%userAssetBalance: ${userAssetBalance}`);
    setIsLoading(false);
  };

  const onDiscountSwitch = (checked: boolean) => {
    console.log(`%switch to ${checked}`);
    setUseDiscount(checked);
  };

  const onSliderChange = (pctValue: number | null) => {
    // const balance = Number(props.usdcBal);
    const balanceBN = ethers.utils.parseUnits(userAssetBalance, DECIMALS);
    const percentageBN = ethers.utils.parseUnits(pctValue ? pctValue.toString() : '0', DECIMALS);
    const computed = balanceBN.mul(percentageBN).div(100).div(UNIT18);
    setInputValue(computed);
  };

  const onInputChange = (valueIn: number | null) => {
    // const balance = Number(props.usdcBal);
    // setInputValue(inputValue ? Number(inputValue.toFixed(18)) : 0);
    const val = ethers.utils.parseUnits(valueIn ? valueIn.toString() : '0', DECIMALS);
    setInputValue(val);
  };

  const onActionClick = async () => {
    setIsLoading(true);
    await switchToBnb();
    let discountApproved = true;
    await refresh();
    if (useDiscount) {
      discountApproved = await getDiscountApproveStatus(BNB_VAULT_ADDRESSES[props.selectedSpotVault], useDiscount);
      console.log('%%%%mint discountApproved', discountApproved);
      if (!discountApproved) {
        setIsDiscountModalOpen(true);
        setIsLoading(false);
      }
    }

    if (discountApproved && depositable.symbol != `BNB`) {
      const tokenApproved = await getTokenApproveStatus(
        BNB_VAULT_ADDRESSES[props.selectedSpotVault],
        depositable.address,
        inputValue,
      );
      // setAllowanceBigNum(allowanceBn);
      if (tokenApproved) {
        // allowance ok, no approval needed.
        await getDepositParams();
      } else {
        setIsApproveModalOpen(true);
      }
    } else if (!useDiscount || depositable.symbol == `BNB`) {
      await getDepositParams();
    }
    setIsLoading(false);
  };

  const approveDiscount = async () => {
    setIsDiscountModalOpen(false);

    try {
      const { transaction } = await discountTokenAllow(BNB_VAULT_ADDRESSES[props.selectedSpotVault]);
      const tokenApproved =
        depositable.symbol != `BNB`
          ? await getTokenApproveStatus(BNB_VAULT_ADDRESSES[props.selectedSpotVault], depositable.address, inputValue)
          : true;
      // Show notifications
      toast
        .promise(transaction, {
          pending: 'Processing YIEDL approval…',
          success: 'YIEDL Approval completed.',
          error: 'Error with YIEDL approval request.',
        })
        .then(async (result) => {
          if (tokenApproved) {
            await getDepositParams();
            // setIsMRModalOpen(true);
          } else {
            setIsApproveModalOpen(true);
          }
        });
    } catch (err: any) {
      console.log('***', err);
      if (err.code === 'ACTION_REJECTED') {
        toast.info('YIEDL Approval cancelled by user.', {
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: true,
        });
      } else {
        toast.error('Error with YIEDL approval request.');
      }
    }
  };

  const approve = async () => {
    setIsApproveModalOpen(false);
    // const approveDelta = ethers.constants.MaxUint256.sub(allowanceBigNum);
    if (depositable.symbol == `BNB`) {
      return;
    }
    try {
      const { transaction } = await tokenAllow(BNB_VAULT_ADDRESSES[props.selectedSpotVault], depositable.address);
      // Show notifications
      toast
        .promise(transaction, {
          pending: 'Processing approval…',
          success: 'Approval completed.',
          error: 'Error with approval request.',
        })
        .then(async (result) => {
          setIsApproveModalOpen(false);
          // setIsMRModalOpen(true);
          await getDepositParams();
        });
    } catch (err: any) {
      console.log('***', err);
      if (err.code === 'ACTION_REJECTED') {
        toast.info('Approval cancelled by user.', {
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: true,
        });
      } else {
        toast.error('Error with approval request.');
      }
    }
  };

  const mint = async () => {
    setIsMRModalOpen(false);
    let transaction: Promise<ethers.providers.TransactionReceipt>;

    try {
      setIsLoading(true);
      transaction =
        depositable.symbol == `BNB`
          ? (await spotDepositNative(props.selectedSpotVault, depositResponse)).transaction
          : (await spotDeposit(props.selectedSpotVault, depositResponse)).transaction;
    } catch (err: any) {
      console.log('%%%%%%%%', err);
      if (err.code === 'ACTION_REJECTED') {
        toast.info('Minting cancelled by user.', {
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: true,
        });
      } else {
        toast.error('Error minting.');
      }
      setIsLoading(false);
      return;
    }

    const loadingToastId = toast.loading('Please wait while your mint request is being processed.');
    try {
      await transaction;
    } catch (err: any) {
      toast.update(loadingToastId, {
        render: 'Error processing mint request.',
        type: toast.TYPE.ERROR,
        isLoading: false,
      });
      setIsLoading(false);
      return;
    }

    toast.update(loadingToastId, {
      render: `Mint request of ${ethers.utils.formatUnits(inputValue, DECIMALS)} ${depositable.symbol} complete.`,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 7000,
      closeButton: true,
      hideProgressBar: true,
    });
    setInputValue(BigNumber.from(0));
    setIsLoading(false);
    if (depositable.symbol != `BNB`) {
      setUserAssetBalance(await getSpotBalanceERC20(depositable.address));
    } else {
      setUserAssetBalance(await getSpotBalanceBnb());
    }
  };

  // const cancel = async () => {
  //   let transaction: Promise<ethers.providers.TransactionReceipt>;
  //
  //   try {
  //     setIsLoading(true);
  //     transaction = (await cancelDeposit(props.vaultType)).transaction;
  //   } catch (err: any) {
  //     console.log(err);
  //     if (err.code === 'ACTION_REJECTED') {
  //       toast.info('Cancellation request cancelled by user.');
  //     } else {
  //       toast.error('Error depositing.');
  //     }
  //     setIsLoading(false);
  //     return;
  //   }
  //
  //   const loadingToastId = toast.loading('Please wait while your cancellation request is being processed.');
  //   try {
  //     await transaction;
  //   } catch (err: any) {
  //     toast.update(loadingToastId, {
  //       render: 'Error processing cancel request.',
  //       type: toast.TYPE.ERROR,
  //       isLoading: false,
  //     });
  //     setIsLoading(false);
  //     return;
  //   }
  //
  //   toast.update(loadingToastId, {
  //     render: `Cancellation completed.`,
  //     type: toast.TYPE.SUCCESS,
  //     isLoading: false,
  //     autoClose: 2000,
  //     closeButton: true,
  //     hideProgressBar: true,
  //   });
  //   setInputValue(BigNumber.from(0));
  //   setIsLoading(false);
  // };

  return (
    <>
      <BaseSpin spinning={props.spinning}>
        <ToastContainer />
        <DashboardCard title={t(`Mint ${props.shareSymbol} Shares`)} bordered={true}>
          {props.connected ? (
            <>
              <BaseRow gutter={[20, 20]} justify={'space-between'}>
                <BaseCol>
                  <InputNumber
                    style={{ minWidth: 180 }}
                    min={0}
                    max={Number(userAssetBalance)}
                    onChange={onInputChange}
                    value={roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6)}
                  />
                  <span>
                    <Select
                      bordered={true}
                      loading={isLoading}
                      showArrow={true}
                      defaultValue="USDC"
                      style={{ width: 100, marginLeft: 10 }}
                      onChange={async (item) => {
                        await onDropdown(item);
                      }}
                      options={[
                        { value: 'USDC', label: 'USDC', disabled: false },
                        { value: 'USDT', label: 'USDT', disabled: false },
                        { value: 'BNB', label: 'BNB', disabled: false },
                        { value: 'ETH', label: 'ETH', disabled: false },
                      ]}
                    />
                  </span>
                  <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: 5 }}>
                    {/*<BaseCol>*/}
                    {/*  Use Discount*/}
                    {/*  <Switch*/}
                    {/*    style={{ marginLeft: 20 }}*/}
                    {/*    loading={isLoading}*/}
                    {/*    checkedChildren="yes"*/}
                    {/*    unCheckedChildren="no"*/}
                    {/*    defaultChecked={false}*/}
                    {/*    onChange={onDiscountSwitch}*/}
                    {/*  />*/}
                    {/*</BaseCol>*/}
                  </BaseRow>
                </BaseCol>

                <BaseCol>
                  <BaseButton
                    type="primary"
                    style={{ minWidth: 100, marginBottom: '1.5rem' }}
                    loading={isLoading}
                    onClick={onActionClick}
                    disabled={
                      (depositable.symbol === 'USDC'
                        ? inputValue.lt(ethers.utils.parseUnits('10', DECIMALS))
                        : depositable.symbol === 'USDT'
                        ? inputValue.lt(ethers.utils.parseUnits('10', DECIMALS))
                        : depositable.symbol === 'ETH'
                        ? inputValue.lt(ethers.utils.parseUnits('0.003', DECIMALS))
                        : inputValue.lt(ethers.utils.parseUnits('0.002', DECIMALS))) ||
                      inputValue.gt(ethers.utils.parseUnits(userAssetBalance, DECIMALS))
                    }
                  >
                    {t('Mint')}
                  </BaseButton>
                </BaseCol>
              </BaseRow>
              <BaseCol style={{ marginTop: '2vh' }}>
                <BaseSlider
                  style={{ marginLeft: '2vh', marginRight: '2vh' }}
                  tooltip={{ open: false }}
                  marks={{
                    0: '0%',
                    25: '25%',
                    50: '50%',
                    75: '75%',
                    100: '100%',
                  }}
                  onChange={onSliderChange}
                  value={
                    (100 * roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6)) / Number(userAssetBalance)
                  }
                  min={0}
                  max={100}
                />
              </BaseCol>

              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '2vh' }}>
                <BaseCol>Balance:</BaseCol>
                <BaseCol>
                  <BaseSpin spinning={isLoading}>
                    {formatNumString(userAssetBalance)} {depositable.symbol}
                  </BaseSpin>
                </BaseCol>
              </BaseRow>

              {/* Fee Section */}
              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '0vh' }}>
                <BaseCol>
                  <span style={{ fontSize: 'small', fontWeight: 'normal' }}>{`Protocol Fee:`}</span>
                </BaseCol>

                <BaseSpin spinning={props.spinning}>
                  <BaseCol style={{ textAlign: 'right', margin: '0vh' }}>
                    <P.ValueText style={{ fontSize: 'small' }}>{`0.5%`}</P.ValueText>
                  </BaseCol>
                </BaseSpin>
              </BaseRow>

              {/*<BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '0vh' }}>*/}
              {/*  <BaseCol>*/}
              {/*    <span style={{ fontSize: 'small', fontWeight: 'normal' }}>{`Fee after YIEDL Discount:`}</span>*/}
              {/*  </BaseCol>*/}

              {/*  <BaseSpin spinning={props.spinning}>*/}
              {/*    <BaseCol style={{ textAlign: 'right', margin: '0vh' }}>*/}
              {/*      <P.ValueText style={{ fontSize: 'small' }}>{`0.25%`}</P.ValueText>*/}
              {/*    </BaseCol>*/}
              {/*  </BaseSpin>*/}
              {/*</BaseRow>*/}

              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '0vh' }}>
                <BaseCol>
                  <span style={{ fontSize: 'small', fontWeight: 'normal' }}>{`Minimum amount:`}</span>
                </BaseCol>

                <BaseSpin spinning={props.spinning}>
                  <BaseCol style={{ textAlign: 'right', margin: '0vh' }}>
                    <P.ValueText style={{ fontSize: 'small' }}>{`$10`}</P.ValueText>
                  </BaseCol>
                </BaseSpin>
              </BaseRow>

              <BaseModal
                title={t(`Approve YIEDL for Discount`)}
                centered
                open={isDiscountModalOpen}
                onOk={approveDiscount}
                onCancel={() => {
                  setIsDiscountModalOpen(false);
                  setIsLoading(false);
                }}
                okText={t('Approve')}
                size="medium"
              >
                <p>{t(`Permission required to interact with your YIEDL tokens.`)}</p>
              </BaseModal>

              <BaseModal
                title={t(`Approve ${depositable.symbol}`)}
                centered
                open={isApproveModalOpen}
                onOk={approve}
                onCancel={() => {
                  setIsApproveModalOpen(false);
                  setIsLoading(false);
                }}
                okText={t('Approve')}
                size="medium"
              >
                <p>{t(`Permission required to interact with your ${depositable.symbol} tokens.`)}</p>
              </BaseModal>

              {/*  Deposit Modal*/}
              <BaseModal
                title={t('Mint')}
                centered
                open={isMRModalOpen}
                onOk={mint}
                onCancel={() => {
                  setIsMRModalOpen(false);
                  setIsLoading(false);
                }}
                okButtonProps={{
                  disabled: estMinShares === `0` || estMinShares === `-1`,
                }}
                cancelButtonProps={{
                  disabled: estMinShares === `0`,
                }}
                okText={t('Proceed')}
                size="medium"
              >
                {estMinShares === `0` ? (
                  <>
                    <p>Computing estimates. This may take up to 2 minutes.</p>
                    <p>{`Please do not navigate away or refresh the page.`}</p>
                    <BaseSpin></BaseSpin>
                  </>
                ) : estMinShares === `-1` ? (
                  <>
                    <p>Error computing. Please check that you have sufficient balances and allowances.</p>
                    <p>Otherwise, try a larger amount.</p>
                  </>
                ) : (
                  <>
                    <p>{`Input amount: ${amountDec} ${depositable.symbol}`}</p>
                    <p>{`Estimated shares to receive: ${sharesToMint}`}</p>
                    <p>{`Using discount: ${usingDiscount ? 'Yes' : 'No'}`}</p>
                  </>
                )}
              </BaseModal>
            </>
          ) : (
            `Please connect wallet to deposit.`
          )}
        </DashboardCard>
      </BaseSpin>
    </>
  );
};
