import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BigChart } from '../../components/vaults-dashboard/bigChart/BigChart';
import { SpotInfoCard } from '../../components/vaults-dashboard/infoCard/SpotInfoCard';
import { StatisticsCard } from '@app/components/vaults-dashboard/statisticsCards/statisticsCard/StatisticsCard/StatisticsCard';
import { StatisticColor } from '@app/constants/config/statistics';
import { AumCard } from '@app/components/vaults-dashboard/aumCard/AumCard';
import { IAppRouterProps } from '@app/components/router/AppRouter';

import {
  SPOT_IDX,
  VAULT_IDX,
  VAULT_TYPE,
  Y_NEUTRAL_PARAMS,
  Y_PDOWN_PARAMS,
  Y_PUP_PARAMS,
  Y_UPDOWN_PARAMS,
} from '@app/lib/contracts';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import HoldingsSummaryCard from '@app/components/vaults-dashboard/holdingsCard/HoldingsSummaryCard';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import HoldingsTableCard from '@app/components/vaults-dashboard/holdingsCard/HoldingsTableCard';
import { GenericCard } from '@app/components/vaults-dashboard/genericCard/GenericCard';
import { ShareCard } from '@app/components/vaults-dashboard/shareCard/ShareCard';
import { ExploreCard } from '@app/components/vaults-dashboard/shareCard/ExploreCard';
import { EstCard } from '@app/components/vaults-dashboard/estCard/EstCard';
import { DoubleSpotCard } from '@app/components/vaults-dashboard/tripleCard/TripleCard/DoubleSpotCard';
import { SpotExploreCard } from '@app/components/vaults-dashboard/shareCard/SpotExploreCard';
import { BNB_VAULT_ADDRESSES } from '@app/lib/BnbItems';
import SpotHoldingsSummaryCard from '@app/components/vaults-dashboard/holdingsCard/SpotHoldingsSummaryCard';
import SpotHoldingsTableCard from '@app/components/vaults-dashboard/holdingsCard/SpotHoldingsTableCard';

const RISK_HEADER = 'vaults.risk';
const RISK_COLORS = ['success', 'primary', 'border', 'warning', 'error'];
const NAV_MULTIPLER = 100;
const SHARE_SYMBOLS = ['Y-BULL', 'Y-iBTC', 'Y-iETH'];

const tooltipOrderFees = (
  <div style={{ fontSize: 'x-small', fontStyle: 'italic' }}>
    <p>Estimated amount of fees charged by the Synthetix protocol for transactions</p>
    <p>required to process this request.</p>
  </div>
);

// const tooltipOnboardingFees = (
//     <div>
//       <p>Estimated amount of fees charged by the Synthetix protocol for transactions</p>
//       <p>required to process this request.</p>
//     </div>
// );
//
// const tooltipOrderFees = (
//     <div>
//       <p>Estimated amount of fees charged by the Synthetix protocol for transactions</p>
//       <p>required to process this request.</p>
//     </div>
// );

interface RiskAttributes {
  riskValue: number;
}
const statistics: RiskAttributes[] = [{ riskValue: 3 }, { riskValue: 1 }, { riskValue: 5 }, { riskValue: 5 }];
const vault_types = [VAULT_TYPE.Y_UPDOWN, VAULT_TYPE.Y_NEUTRAL, VAULT_TYPE.Y_PUP, VAULT_TYPE.Y_PDOWN];

function quantize(value: string | number, decimals: number): string {
  return Number(value).toLocaleString(undefined, { maximumFractionDigits: decimals });
}

interface InfoCardProps {
  title: string;
  subtitle: string;
  description: string;
  linkUrl?: string;
}

const infos: Array<InfoCardProps> = [
  // {
  //   title: 'UPDOWN',
  //   subtitle: 'Weekly Rotation, 37 Assets',
  //   description:
  //     'Unbalanced asset allocation where predictions are provided by crowd-sourced machine-learning forecasts run by the Yiedl Competition. The asset allocation is done by buying the positive predictions and short selling the negative predictions. Each week, the total allocations of the long and short side depend on the stake weighted meta-model (could be long bias, short bias, or market neutral).',
  //   linkUrl: 'https://docs.yiedl.ai/rci-competition/scoring-and-reward-policy#vault-updown',
  // },

  {
    title: 'BULL',
    subtitle: 'Weekly Rotation, Variable Assets',
    description:
      '100% LONG only allocation. The predictions are provided by crowd-sourced machine-learning forecasts run by the Yiedl Competition. The asset allocation is done by buying the top half of the ranked crypto-assets (only the strongest ) predicted by the stake weighted meta-model.',
  },
  {
    title: 'iBTC',
    subtitle: 'Weekly Rotation, BTC and USDC',
    description:
      'USDC and BTC-only portfolio. The predictions are provided by crowd-sourced machine-learning forecasts run by the Yiedl Competition.',
  },
  {
    title: 'iETH',
    subtitle: 'Weekly Rotation, ETH and USDC',
    description:
      'USDC and ETH-only portfolio. The predictions are provided by crowd-sourced machine-learning forecasts run by the Yiedl Competition.',
  },
];

// const vaultParams = [Y_UPDOWN_PARAMS, Y_NEUTRAL_PARAMS, Y_PUP_PARAMS, Y_PDOWN_PARAMS];

const SpotDashboardPage: React.FC<IAppRouterProps> = (props) => {
  const { isTablet, isDesktop } = useResponsive();

  const { t } = useTranslation();
  const desktopLayout = (
    <BaseRow>
      <PageTitle>{t('Spot Vaults')}</PageTitle>
      <BaseCol span={24} style={{ marginBottom: '2vh', marginLeft: '4vh' }}>
        <BaseRadio.Group defaultValue={props.spotSelected} buttonStyle="solid" size="small">
          <BaseRadio.Button onClick={props.selectSUp} value={SPOT_IDX.S_UP}>
            BULL
          </BaseRadio.Button>
          {/*<BaseRadio.Button onClick={props.selectIBtc} value={SPOT_IDX.I_BTC}>*/}
          {/*  iBTC*/}
          {/*</BaseRadio.Button>*/}
          {/*<BaseRadio.Button onClick={props.selectIEth} value={SPOT_IDX.I_ETH}>*/}
          {/*  iETH*/}
          {/*</BaseRadio.Button>*/}
        </BaseRadio.Group>
      </BaseCol>

      <S.LeftSideCol xl={13} xxl={13}>
        <BaseRow gutter={[30, 30]}>
          <BaseCol span={24}>
            <SpotInfoCard
              title={infos[props.spotSelected].title}
              subtitle={infos[props.spotSelected].subtitle}
              description={infos[props.spotSelected].description}
              linkUrl={infos[props.spotSelected].linkUrl}
              selectedVault={props.spotSelected}
            />
          </BaseCol>

          <BaseCol span={24}>
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={12} md={12} order={(isTablet && 1) || 0}>
                <AumCard
                  title={'iNav'}
                  value={quantize(props.spotNavs[props.spotSelected] * NAV_MULTIPLER, 2)}
                  spinning={props.spinning}
                  unit={''}
                />
              </BaseCol>
              <BaseCol xs={12} md={12} order={(isTablet && 2) || 0}>
                <AumCard
                  title={'TVL'}
                  value={props.spotAllocations[props.spotSelected].aum.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}
                  spinning={props.spinning}
                  unit={'USD'}
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol span={24}>
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={12} md={8} order={(isTablet && 1) || 0}>
                <StatisticsCard
                  name={RISK_HEADER}
                  value={statistics[props.vaultSelected].riskValue}
                  color={`${RISK_COLORS[statistics[props.vaultSelected].riskValue - 1]}` as StatisticColor}
                  unit={'/ 5'}
                />
              </BaseCol>
              <BaseCol xs={12} md={8} order={(isTablet && 2) || 0}>
                <ShareCard
                  title={'Share Supply'}
                  value={Number(props.spotShareSupply[props.spotSelected]).toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}
                  spinning={props.spinning}
                  unit={''}
                />
              </BaseCol>
              {/*<BaseCol xs={12} md={8} order={(isTablet && 2) || 0}>*/}
              {/*  <ShareCard*/}
              {/*    title={'Share Holders'}*/}
              {/*    value={props.numShareholders[props.vaultSelected]}*/}
              {/*    spinning={props.spinning}*/}
              {/*    unit={''}*/}
              {/*  />*/}
              {/*</BaseCol>*/}
              <BaseCol xs={12} md={8} order={(isTablet && 3) || 0}>
                <SpotExploreCard
                  title={'Explore Token'}
                  value={'→'}
                  spinning={props.spinning}
                  address={BNB_VAULT_ADDRESSES[props.spotSelected]}
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol id="big-chart" span={24}>
            <BigChart
              dayHistories={props.dayHistories[props.vaultSelected]}
              weekHistories={props.weekHistories[props.vaultSelected]}
              monthHistories={props.monthHistories[props.vaultSelected]}
              month3Histories={props.month3Histories[props.vaultSelected]}
              yearHistories={props.yearHistories[props.vaultSelected]}
              allHistories={props.allHistories[props.vaultSelected]}
              spinning={props.spinning}
            />
          </BaseCol>
          <BaseCol span={24}>
            <SpotHoldingsSummaryCard
              portfolios={props.bnbPortfolios}
              selector={props.vaultSelected}
              loading={props.spinning}
              processingStatus={props.processingStatus}
            />
          </BaseCol>
          <BaseCol span={24} order={25}>
            <SpotHoldingsTableCard
              portfolios={props.bnbPortfolios}
              selector={props.vaultSelected}
              loading={props.spinning}
              processingStatus={props.processingStatus}
            />
          </BaseCol>
        </BaseRow>
      </S.LeftSideCol>

      <S.RightSideCol xl={11} xxl={11} style={{ marginTop: '0.3vh' }}>
        <>
          <DoubleSpotCard
            mintCardProps={{
              spinning: props.walletLoading,
              connected: props.connected,
              shareSymbol: SHARE_SYMBOLS[props.spotSelected],
              selectedSpotVault: props.spotSelected,
            }}
            redeemCardProps={{
              spinning: props.walletLoading,
              connected: props.connected,
              shareSymbol: SHARE_SYMBOLS[props.spotSelected],
              selectedSpotVault: props.spotSelected,
            }}
            tripleSelected={props.tripleSelected}
            selectSwap={props.selectSwap}
            selectMint={props.selectMint}
            selectRedeem={props.selectRedeem}
          />
        </>
      </S.RightSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <>
      <BaseCol span={24} style={{ marginBottom: '2vh', marginLeft: '0vh' }}>
        <BaseRadio.Group defaultValue={props.vaultSelected} buttonStyle="solid" size="small">
          <BaseRadio.Group defaultValue={props.spotSelected} buttonStyle="solid" size="small">
            <BaseRadio.Button onClick={props.selectSUp} value={SPOT_IDX.S_UP}>
              BULL
            </BaseRadio.Button>
            {/*<BaseRadio.Button onClick={props.selectIBtc} value={SPOT_IDX.I_BTC}>*/}
            {/*  iBTC*/}
            {/*</BaseRadio.Button>*/}
            {/*<BaseRadio.Button onClick={props.selectIEth} value={SPOT_IDX.I_ETH}>*/}
            {/*  iETH*/}
            {/*</BaseRadio.Button>*/}
          </BaseRadio.Group>
        </BaseRadio.Group>
      </BaseCol>
      <BaseRow gutter={[20, 20]}>
        <BaseCol span={24}>
          <SpotInfoCard
            title={infos[props.spotSelected].title}
            subtitle={infos[props.spotSelected].subtitle}
            description={infos[props.spotSelected].description}
            linkUrl={infos[props.spotSelected].linkUrl}
            selectedVault={props.spotSelected}
          />
        </BaseCol>
        <BaseCol span={24} order={(isTablet && 1) || 0}>
          <DoubleSpotCard
            mintCardProps={{
              spinning: props.walletLoading,
              connected: props.connected,
              shareSymbol: SHARE_SYMBOLS[props.spotSelected],
              selectedSpotVault: props.spotSelected,
            }}
            redeemCardProps={{
              spinning: props.walletLoading,
              connected: props.connected,
              shareSymbol: SHARE_SYMBOLS[props.spotSelected],
              selectedSpotVault: props.spotSelected,
            }}
            tripleSelected={props.tripleSelected}
            selectSwap={props.selectSwap}
            selectMint={props.selectMint}
            selectRedeem={props.selectRedeem}
          />
        </BaseCol>
        <BaseCol xs={12} md={12} order={(isTablet && 5) || 0}>
          <AumCard
            title={'iNav'}
            value={quantize(props.spotNavs[props.spotSelected] * NAV_MULTIPLER, 2)}
            spinning={props.spinning}
            unit={''}
          />
        </BaseCol>
        <BaseCol xs={12} md={12} order={(isTablet && 6) || 0}>
          <AumCard
            title={'TVL'}
            value={props.spotAllocations[props.spotSelected].aum.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
            spinning={props.spinning}
            unit={'USD'}
          />
        </BaseCol>
        <BaseCol xs={12} md={8} order={(isTablet && 7) || 0}>
          <StatisticsCard
            name={RISK_HEADER}
            value={statistics[props.vaultSelected].riskValue}
            color={`${RISK_COLORS[statistics[props.vaultSelected].riskValue - 1]}` as StatisticColor}
            unit={'/ 5'}
          />
        </BaseCol>

        <BaseCol xs={12} md={8} order={(isTablet && 8) || 0}>
          <ShareCard
            title={'Share Supply'}
            value={Number(props.spotShareSupply[props.spotSelected]).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
            spinning={props.spinning}
            unit={''}
          />
        </BaseCol>

        <BaseCol xs={12} md={8} order={(isTablet && 10) || 0}>
          <SpotExploreCard
            title={'Explore Token'}
            value={'→'}
            spinning={props.spinning}
            address={BNB_VAULT_ADDRESSES[props.spotSelected]}
          />
        </BaseCol>

        <BaseCol id="big-chart" md={24} xs={24} order={(isTablet && 15) || 0}>
          <BigChart
            dayHistories={props.dayHistories[props.vaultSelected]}
            weekHistories={props.weekHistories[props.vaultSelected]}
            monthHistories={props.monthHistories[props.vaultSelected]}
            month3Histories={props.month3Histories[props.vaultSelected]}
            yearHistories={props.yearHistories[props.vaultSelected]}
            allHistories={props.allHistories[props.vaultSelected]}
            spinning={props.spinning}
          />
        </BaseCol>
        <BaseCol span={24} order={(isTablet && 20) || 0}>
          <SpotHoldingsSummaryCard
            portfolios={props.bnbPortfolios}
            selector={props.vaultSelected}
            loading={props.spinning}
            processingStatus={props.processingStatus}
          />
        </BaseCol>
        <BaseCol span={24} order={(isTablet && 21) || 0}>
          <SpotHoldingsTableCard
            portfolios={props.bnbPortfolios}
            selector={props.vaultSelected}
            loading={props.spinning}
            processingStatus={props.processingStatus}
          />
        </BaseCol>
      </BaseRow>
    </>
  );

  return (
    <>
      <PageTitle>{t('common.vaults')}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default SpotDashboardPage;
