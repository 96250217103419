import React, { useState } from 'react';
import * as S from './InfoCard.styles';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import updownLogo from '@app/assets/tokens/updown_sm.png';
import neutralLogo from '@app/assets/tokens/neutral_sm.png';
import upLogo from '@app/assets/tokens/up_sm.png';
import downLogo from '@app/assets/tokens/down_sm.png';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { addSusd, addTokens } from '@app/lib/contracts';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Link } from 'react-router-dom';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

export interface InfoCardProps {
  title: string;
  subtitle?: string;
  description: string;
  linkUrl?: string;
  className?: string;
  selectedVault: number;
}

export const InfoCard: React.FC<InfoCardProps> = (props) => {
  const logos = [updownLogo, neutralLogo, upLogo, downLogo];
  const [addingTokens, setAddingTokens] = useState(false);

  return (
    <DashboardCard>
      <BaseRow align={'middle'} justify={'space-between'} style={{ paddingBottom: '1rem' }}>
        <S.Title>{props.title}</S.Title>
        <img src={logos[props.selectedVault]} alt="vaultLogo" style={{ height: '3rem' }} />
      </BaseRow>
      <S.Wrapper className={props.className}>
        <S.InfoWrapper>
          <S.InfoHeader>
            <S.Author>{props.subtitle}</S.Author>
          </S.InfoHeader>
          <S.Description>{props.description}</S.Description>
          <S.DateTime>
            <a href={'https://competition.yiedl.ai'} target="_blank" rel="noreferrer">
              Competition →
            </a>
            {/*<Link to={'/competition/airdrop'}>Onboard as Data Scientist →</Link>*/}
          </S.DateTime>
          <S.DateTime>
            {props.linkUrl && props.linkUrl.length > 4 ? (
              <p>
                <a href={props.linkUrl} target="_blank" rel="noreferrer">
                  Further Details →
                </a>
              </p>
            ) : null}
          </S.DateTime>
        </S.InfoWrapper>
        <BaseRow gutter={[10, 10]}>
          <BaseCol>
            <BaseButton
              size={'small'}
              style={{ margin: '1rem', width: '100%', placeItems: 'center' }}
              loading={addingTokens}
              onClick={() => {
                setAddingTokens(true);
                addTokens(props.title).finally(() => setAddingTokens(false));
              }}
            >
              Import Y-{props.title}
            </BaseButton>
          </BaseCol>
          <BaseCol>
            <BaseButton
              size={'small'}
              style={{ margin: '1rem', width: '100%', placeItems: 'center' }}
              loading={addingTokens}
              onClick={() => {
                setAddingTokens(true);
                addSusd().finally(() => setAddingTokens(false));
              }}
            >
              Import sUSD
            </BaseButton>
          </BaseCol>
        </BaseRow>
      </S.Wrapper>
    </DashboardCard>
  );
};
