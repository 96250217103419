import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { BaseSteps } from '@app/components/common/BaseSteps/BaseSteps';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import * as S from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { AumCard } from '@app/components/vaults-dashboard/aumCard/AumCard';
import { StatisticsCard } from '@app/components/vaults-dashboard/statisticsCards/statisticsCard/StatisticsCard/StatisticsCard';
import { StatisticColor } from '@app/constants/config/statistics';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { useResponsive } from '@app/hooks/useResponsive';
import { GenericCard } from '@app/components/vaults-dashboard/genericCard/GenericCard';
import { Portfolio } from '@app/lib/contracts';

function sumOneSide(pf: Portfolio | null, type: number) {
  let res;
  res = 0;
  if (pf) {
    if (type == 0) {
      res = (100 * pf.flatUsds) / pf.aum;
    } else if (type == 1) {
      for (let i = 0; i < pf.assets.length; i++) {
        if (pf.sizes[i] > 0) {
          res += pf.values[i];
        }
      }
      res = (100 * res) / pf.aum;
    } else if (type == -1) {
      for (let i = 0; i < pf.assets.length; i++) {
        if (pf.sizes[i] < 0) {
          res += pf.values[i];
        }
      }
      res = (100 * res) / pf.aum;
    }

    return res.toLocaleString(undefined, { minimumFractionDigits: 2, maximumSignificantDigits: 4 });
  }
  return '';
}

function getPnlPct(pnlStr: any, aum: number) {
  const pnl = Number(pnlStr.replaceAll(',', ''));
  // const aum = Number(aumStr.replaceAll(",", ""))
  const initialAum = aum - pnl;
  return ((pnl * 100) / initialAum).toLocaleString(undefined, { maximumFractionDigits: 2 });
}

// function processSummaries(info: any, selector: number) {
//   console.log(info);
//   if (info) {
//     const obj =
//       selector == 0 ? info.Y_UPDOWN : selector == 1 ? info.Y_NEUTRAL : selector == 2 ? info.Y_PUP : info.Y_PDOWN;
//     return [
//       {
//         longPct: sumOneSide(obj.formatted, 1),
//         flatPct: sumOneSide(obj.formatted, 0),
//         shortPct: sumOneSide(obj.formatted, -1),
//       },
//     ];
//   }
// }

function getInfoObject(info: any, selector: number) {
  if (info && info.dydx_updown) {
    return selector == 0
      ? info.dydx_updown.formatted
      : selector == 1
      ? info.dydx_neutral.formatted
      : selector == 2
      ? info.dydx_up.formatted
      : info.dydx_down.formatted;
  }
}

interface ITransactionsPageProps {
  portfolios: Array<Portfolio | null>;
  selector: number;
  loading: boolean;
  processingStatus: Array<number>;
}

const HoldingsSummaryCard: React.FC<ITransactionsPageProps> = (props) => {
  const { isTablet, isDesktop } = useResponsive();
  const { t } = useTranslation();
  return (
    <>
      <DashboardCard title={'Current Allocation'}>
        <BaseCol span={24}>
          <BaseRow gutter={[30, 30]}>
            <BaseCol xs={12} md={8} order={(isTablet && 1) || 0}>
              <GenericCard
                title={'Long'}
                value={sumOneSide(props.portfolios[props.selector], 1)}
                spinning={props.loading || props.processingStatus[props.selector] != 0}
                tip={
                  props.processingStatus[props.selector] == 1
                    ? ''
                    : props.processingStatus[props.selector] == 2
                    ? ''
                    : ''
                }
                unit={'%'}
              />
            </BaseCol>
            <BaseCol xs={12} md={8} order={(isTablet && 2) || 0}>
              <GenericCard
                title={'Flat'}
                value={sumOneSide(props.portfolios[props.selector], 0)}
                spinning={props.loading || props.processingStatus[props.selector] != 0}
                tip={
                  props.processingStatus[props.selector] == 1
                    ? ''
                    : props.processingStatus[props.selector] == 2
                    ? ''
                    : ''
                }
                unit={'%'}
              />
            </BaseCol>
            <BaseCol xs={12} md={8} order={(isTablet && 3) || 0}>
              <GenericCard
                title={'Short'}
                value={sumOneSide(props.portfolios[props.selector], -1)}
                spinning={props.loading || props.processingStatus[props.selector] != 0}
                tip={
                  props.processingStatus[props.selector] == 1
                    ? ''
                    : props.processingStatus[props.selector] == 2
                    ? ''
                    : ''
                }
                unit={'%'}
              />
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </DashboardCard>

      {/*  /!*<S.Card title={t('Request Records')}>*!/*/}
      {/*  /!*  {props.requestRecords.length == 0 ? (*!/*/}
      {/*  /!*    `You no request records at this time.`*!/*/}
      {/*  /!*  ) : (*!/*/}
      {/*  /!*    // <D.ScrollWrapper >*!/*/}
      {/*  /!*    <BaseTable columns={requestColumns} dataSource={props.requestRecords} scroll={{ x: 800 }} />*!/*/}
      {/*  /!*    // </D.ScrollWrapper>*!/*/}
      {/*  /!*  )}*!/*/}
      {/*  /!*</S.Card>*!/*/}
      {/*</BaseCol>*/}
    </>
  );
};

export default HoldingsSummaryCard;
