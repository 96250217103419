import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseChart } from '@app/components/common/charts/BaseChart';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { ACTUAL_DATA_EPOCH_MS } from '@app/lib/contracts';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Simulate } from 'react-dom/test-utils';
import compositionEnd = Simulate.compositionEnd;
import { GenericCard } from '@app/components/vaults-dashboard/genericCard/GenericCard';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BASE_COLORS } from '@app/styles/themes/constants';

const YEAR_MS = 52 * 7 * 24 * 60 * 60 * 1000;

interface MetricsType {
  minCAGR: string;
  maxCAGR: string;
  currentCAGR: string;
  minCalmar: string;
  maxCalmar: string;
  currentCalmar: string;
  minProfitFactor: string;
  maxProfitFactor: string;
  profitFactor: string;
  minSharpeRatio: string;
  maxSharpeRatio: string;
  sharpeRatio: string;
  maxDD: string;
}

const computeMetrics = (inavs: Array<{ x: number; y: number }>) => {
  // CAGR, Calmar, DD
  let minCAGR = Number.POSITIVE_INFINITY;
  let maxCAGR = Number.NEGATIVE_INFINITY;
  let currentCAGR = Number.NaN;
  let maxNavSoFar = Number.NEGATIVE_INFINITY;
  let maxDD = 0;
  let latestDD = 0;

  let minCalmar = Number.POSITIVE_INFINITY;
  let maxCalmar = Number.NEGATIVE_INFINITY;
  let currentCalmar = Number.NaN;

  const firstInav = inavs[0].y;
  for (let i = 1; i < inavs.length; i++) {
    const thisInav = inavs[i].y * 1;

    maxNavSoFar = Math.max(maxNavSoFar, thisInav);
    latestDD = thisInav / maxNavSoFar - 1;
    maxDD = Math.min(maxDD, latestDD);

    const cagr = (thisInav / firstInav) ** (YEAR_MS / (inavs[i].x - inavs[0].x)) - 1;
    const calmar = maxDD !== 0 ? cagr / Math.abs(maxDD) : null;

    minCAGR = minCAGR > cagr ? cagr : minCAGR;
    maxCAGR = Math.max(maxCAGR, cagr);
    currentCAGR = cagr;

    if (calmar !== null) {
      minCalmar = Math.min(minCalmar, calmar);
      maxCalmar = Math.max(maxCalmar, calmar);
      currentCalmar = calmar;
    }
  }

  // profit factor and sharpe ratio
  let minProfitFactor = Number.POSITIVE_INFINITY;
  let maxProfitFactor = Number.NEGATIVE_INFINITY;
  let profitFactor = Number.NaN;
  let minSharpeRatio = Number.POSITIVE_INFINITY;
  let maxSharpeRatio = Number.NEGATIVE_INFINITY;
  let sharpeRatio = Number.NaN;

  let returns = 0;
  let returnsSquared = 0;
  let returnsCount = 0;
  let returnsSum = 0;
  let returnsSumSquared = 0;

  if (inavs.length < 2) {
    minProfitFactor = 1;
    maxProfitFactor = 1;
    profitFactor = 1;
  } else {
    let profit = 0;
    let loss = 0;

    for (let i = 1; i < inavs.length; i++) {
      const thisInav = inavs[i].y * 1;
      const prevInav = inavs[i - 1].y * 1;

      const diff = thisInav - prevInav;

      returns = diff;
      returnsSquared = diff * diff;
      returnsSum += diff;
      returnsSumSquared += diff * diff;
      returnsCount += 1;

      const mean = returnsSum / returnsCount;
      const meanOfSquares = returnsSumSquared / returnsCount;
      const variance = meanOfSquares - mean * mean;
      const stdDev = Math.sqrt(variance);

      sharpeRatio = (mean / stdDev) * Math.sqrt(365);
      minSharpeRatio = Math.min(minSharpeRatio, sharpeRatio);
      maxSharpeRatio = Math.max(maxSharpeRatio, sharpeRatio);

      if (diff > 0) {
        profit += diff;
      } else {
        loss -= diff;
      }

      profitFactor = loss === 0 ? 1 : profit / loss;
      minProfitFactor = Math.min(minProfitFactor, profitFactor);
      maxProfitFactor = Math.max(maxProfitFactor, profitFactor);
    }
  }

  const result = [
    minCAGR,
    maxCAGR,
    currentCAGR * 100,
    minCalmar,
    maxCalmar,
    currentCalmar,
    minProfitFactor,
    maxProfitFactor,
    profitFactor,
    minSharpeRatio,
    maxSharpeRatio,
    sharpeRatio,
    maxDD * 100,
  ];

  const resultString = result.map((r) => r.toLocaleString(undefined, { maximumFractionDigits: 2 }));
  const metrics: MetricsType = {
    minCAGR: resultString[0],
    maxCAGR: resultString[1],
    currentCAGR: resultString[2],
    minCalmar: resultString[3],
    maxCalmar: resultString[4],
    currentCalmar: resultString[5],
    minProfitFactor: resultString[6],
    maxProfitFactor: resultString[7],
    profitFactor: resultString[8],
    minSharpeRatio: resultString[9],
    maxSharpeRatio: resultString[10],
    sharpeRatio: resultString[11],
    maxDD: resultString[12],
  };

  return metrics;
};

interface IBigChartProps {
  dayHistories: Array<Array<{ x: number; y: number }>>;
  weekHistories: Array<Array<{ x: number; y: number }>>;
  monthHistories: Array<Array<{ x: number; y: number }>>;
  month3Histories: Array<Array<{ x: number; y: number }>>;
  yearHistories: Array<Array<{ x: number; y: number }>>;
  allHistories: Array<Array<{ x: number; y: number }>>;
  spinning: boolean;
}

export const BigChart: React.FC<IBigChartProps> = (props: IBigChartProps) => {
  const [timeframe, setTimeFrame] = useState(0);
  const [metrics, setMetrics] = useState(computeMetrics(props.dayHistories[1]));

  const { t } = useTranslation();

  const theme = useAppSelector((state) => state.theme.theme);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // const runAnimation = useCallback(() => {}, [theme]);

  const navSelector = (timeframe: number) => {
    return timeframe == 0
      ? props.dayHistories[1]
      : timeframe == 1
      ? props.weekHistories[1]
      : timeframe == 2
      ? props.monthHistories[1]
      : timeframe == 3
      ? props.month3Histories[1]
      : timeframe == 4
      ? props.yearHistories[1]
      : props.allHistories[1];
  };

  const returnsSelector = (timeframe: number) => {
    return timeframe == 0
      ? props.dayHistories[2]
      : timeframe == 1
      ? props.weekHistories[2]
      : timeframe == 2
      ? props.monthHistories[2]
      : timeframe == 3
      ? props.month3Histories[2]
      : timeframe == 4
      ? props.yearHistories[2]
      : props.allHistories[2];
  };

  // console.log('%%%%%% props.dayHistories[1]', props.dayHistories[1]);
  // console.log('%%%%%% props.dayHistories[2]', props.dayHistories[2]);
  //   console.log('%%%%%% props.allHistories[2]', props.allHistories[2]);

  const option = {
    // color: themeObject[theme]['success'],
    animationDuration: 700,
    tooltip: {
      formatter: function (params: any) {
        const date = new Date(params[0].axisValue);
        const dateFormatted = date.toLocaleTimeString(undefined, {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
        // You can also include params[0].marker if you want the color marker to appear
        // If you want to exclude the marker, just use the date and value in the return string
        return `<b>${params[0].value[1].toLocaleString(undefined, {
          minimumFractionDigits: 4,
        })} %</b><br/>${dateFormatted}`;
      },

      textStyle: {
        align: 'right', // Align text to the right
      },
      // // Define the rich property for custom styles
      // rich: {
      //   time: {
      //     color: '#fff',
      //     lineHeight: 20,
      //     align: 'right',
      //   },
      //   value: {
      //     color: '#fff',
      //     lineHeight: 20,
      //     align: 'right',
      //     fontWeight: 'bold',
      //     fontSize: 50,
      //   },
      // },

      // order: 'valueDesc',
      trigger: 'axis',
    },
    xAxis: {
      // data: selector(timeframe)?.map((item) =>
      //   new Date(item.x).toLocaleTimeString(undefined, {
      //     year: 'numeric',
      //     month: 'short',
      //     day: 'numeric',
      //     hour: '2-digit',
      //     minute: '2-digit',
      //   }),
      // ),
      boundaryGap: false,
      type: 'time',
      axisLabel: {
        show: true,
        // // interval: 'auto', // timeframe == 0 ? 99 : timeframe == 3 ? 79 : 55,
        // interval: 99,
        hideOverlap: true,
        rotate: 25,
        textStyle: {
          fontSize: 9,
        },

        formatter: (value: any) => {
          // Format the timestamp value as needed
          return new Date(value).toLocaleTimeString(undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            // minute: '2-digit',
          });
        },
      },
      axisTick: {
        show: false,
      },
      // nameLocation: 'middle',
    },
    yAxis: {
      type: 'value',
      // name: '% CHANGE',
      nameLocation: 'middle',
      nameGap: 40,
      nameRotate: 90,
      scale: true,
      animation: true,
      animationDurationUpdate: 0,
      splitLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
    },
    // grid: {
    //   left: 65,
    //   right: 150,
    //   top: 20,
    //   bottom: 30,
    // },
    grid: {
      containLabel: true,
      left: 65,
      bottom: 10,
      right: 30,
    },
    series: [
      {
        name: '% Change',
        data: returnsSelector(timeframe)?.map((item) => ({
          value: [item.x, item.y * 100],
        })),
        type: 'line',
        smooth: true,
        symbol: 'none',
        animation: true,
        animationDuration: 1000,
        animationDurationUpdate: 0,
      },
    ],

    visualMap: {
      show: false, // Wether to show the legend or not (default: true)

      dimension: 0, // Which dimension to use to create the visual map. Use the index of the dimension
      pieces: [
        {
          min: -9999, // Normally not needed but doesn't work without that (1)
          lt: ACTUAL_DATA_EPOCH_MS,
          // color: themeObject[theme]['textLight'],
          color: themeObject[theme]['primary'],
        },
        {
          gte: ACTUAL_DATA_EPOCH_MS,
          color: themeObject[theme]['success'],
        },
      ],
      outOfRange: {
        color: 'antiquewhite',
      },
    },
  };

  const metricsColumn: ColumnsType<MetricsType> = [
    {
      title: 'CAGR (%)',
      dataIndex: 'currentCAGR',
      key: 'currentCAGR',
      // fixed: 'left',
      // render: (text) => <b>{text}</b>,
    },
    {
      title: 'Profit Factor',
      dataIndex: 'profitFactor',
      key: 'profitFactor',
    },
    {
      title: 'Sharpe Ratio',
      dataIndex: 'sharpeRatio',
      key: 'sharpeRatio',
    },
    {
      title: 'Calmar Ratio',
      dataIndex: 'currentCalmar',
      key: 'currentCalmar',
    },
    {
      title: 'Max Drawdown (%)',
      dataIndex: 'maxDD',
      key: 'maxDD',
    },
  ];

  return (
    <BaseCard padding="0 0 0.875rem">
      <BaseRow
        gutter={[20, 20]}
        align="middle"
        style={{ justifyContent: 'space-between', paddingLeft: '2vh', paddingRight: '2vh', paddingTop: '2vh' }}
      >
        <BaseCol>
          <h1 style={{ fontWeight: 'bold' }}>{`% Returns`}</h1>
        </BaseCol>
        <BaseCol>
          <BaseRadio.Group defaultValue={timeframe} buttonStyle="outline" size="small">
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(0);
                setMetrics(computeMetrics(props.dayHistories[1]));
              }}
              value={0}
            >
              1d
            </BaseRadio.Button>
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(1);
                setMetrics(computeMetrics(props.weekHistories[1]));
              }}
              value={1}
            >
              1w
            </BaseRadio.Button>
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(2);
                setMetrics(computeMetrics(props.monthHistories[1]));
              }}
              value={2}
            >
              1m
            </BaseRadio.Button>
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(3);
                setMetrics(computeMetrics(props.month3Histories[1]));
              }}
              value={3}
            >
              3m
            </BaseRadio.Button>
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(4);
                setMetrics(computeMetrics(props.yearHistories[1]));
              }}
              value={4}
            >
              1y
            </BaseRadio.Button>
            <BaseRadio.Button
              onClick={() => {
                setTimeFrame(5);
                setMetrics(computeMetrics(props.allHistories[1]));
              }}
              value={5}
            >
              All
            </BaseRadio.Button>
          </BaseRadio.Group>
        </BaseCol>
      </BaseRow>

      <BaseSpin spinning={props.spinning || navSelector(timeframe)[0].y === 0}>
        {props.spinning ? null : <BaseChart option={option} height="24rem" />}
      </BaseSpin>

      <BaseSpin spinning={props.spinning}>
        {props.spinning ? null : (
          <BaseRow
            gutter={[20, 20]}
            align="middle"
            style={{ justifyContent: 'space-between', paddingLeft: '2vh', paddingRight: '2vh', paddingTop: '2vh' }}
          >
            <BaseCol span={24}>
              <h4 style={{ fontWeight: 'bold' }}>{`Vault Metrics`}</h4>
              <BaseTable
                style={{ fontSize: 'small' }}
                bordered={true}
                columns={metricsColumn}
                dataSource={[computeMetrics(navSelector(timeframe))]}
                scroll={{ x: true }}
                pagination={false}
                loading={{
                  spinning: props.spinning,
                  size: 'large',
                  style: { fontStyle: 'italic' },
                }}
                size={'small'}
              />
            </BaseCol>
          </BaseRow>
        )}
      </BaseSpin>
    </BaseCard>
  );
};
