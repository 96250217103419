import { useTranslation } from 'react-i18next';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
// import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSwitch } from '@app/components/common/BaseSwitch/BaseSwitch';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSlider } from '@app/components/common/BaseSlider/BaseSlider';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseRate } from '@app/components/common/BaseRate/BaseRate';
import { notificationController } from '@app/controllers/notificationController';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseCheckbox } from '@app/components/common/BaseCheckbox/BaseCheckbox';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { InputNumber, Popover, Row, Select, Slider, Space, Switch } from 'antd';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import {
  activeUser,
  router,
  VAULT_TYPE,
  redeem,
  shareAllowance,
  shareAllow,
  cancelDeposit,
  cancelRedeem,
  Request,
  DECIMALS,
  UNIT18,
  DepositResponse,
  getSpotVaultAllowance,
  spotShareAllow,
  getSpotBalanceERC20,
  getSpotBalanceBnb,
  getSpotVaultBalance,
  ethereumClient,
  discountTokenAllow,
  getTokenApproveStatus,
  tokenAllow,
  RedeemResponse,
  callRedeem,
  spotRedeem,
  refresh,
  getDiscountApproveStatus,
  getSpotVaultApproveStatus,
  switchToBnb,
} from '@app/lib/contracts';
import { BigNumber, ethers } from 'ethers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import * as P from '@app/components/vaults-dashboard/statisticsCards/statisticsCard/StatisticsProgress/StatisticsProgress.styles';
import { BNB_ASSET_ADDRESSES, BNB_VAULT_ADDRESSES } from '@app/lib/BnbItems';

interface Depositable {
  symbol: string;
  address: string;
  decimals: number;
}

const DEPOSITABLES: { [key: string]: Depositable } = {
  USDT: {
    symbol: 'USDT',
    address: BNB_ASSET_ADDRESSES.USDT,
    decimals: 18,
  },
  USDC: {
    symbol: 'USDC',
    address: BNB_ASSET_ADDRESSES.USDC,
    decimals: 18,
  },
  ETH: {
    symbol: 'ETH',
    address: BNB_ASSET_ADDRESSES.ETH,
    decimals: 18,
  },
  BNB: {
    symbol: 'BNB',
    address: BNB_ASSET_ADDRESSES.BNB,
    decimals: 18,
  },
};

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const normFile = (e = { fileList: [] }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

// export interface IRedeemCardProps {
//   shareBal: string;
//   spinning: boolean;
//   vaultType: VAULT_TYPE;
//   connected: boolean;
//   shareSymbol: string;
//   inav: number;
//   minVal: string;
//   pending: Request;
//   orderFees: string;
//   offboardingFees: string;
//   tooltipContent?: any;
//   minShares: string;
// }

export interface ISpotRedeemCardProps {
  spinning: boolean;
  connected: boolean;
  shareSymbol: string;
  selectedSpotVault: number;
}

function roundDown(inputString: string, decimals: number) {
  decimals = decimals || 0;
  const number = Number(inputString);
  return Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export const SpotRedeemCard: React.FC<ISpotRedeemCardProps> = (props) => {
  const [inputValue, setInputValue] = useState<BigNumber>(ethers.utils.parseUnits('0', DECIMALS));

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState<boolean>(false);
  const [isMRModalOpen, setIsMRModalOpen] = useState<boolean>(false);
  const [allowanceBigNum, setAllowanceBigNum] = useState<ethers.BigNumber>(ethers.BigNumber.from(0));
  const { t } = useTranslation();

  const [depositable, setDepositable] = useState<Depositable>(DEPOSITABLES.USDC);
  const [userAssetBalance, setUserAssetBalance] = useState<string>(`0`);
  const [useDiscount, setUseDiscount] = useState<boolean>(false);
  const [discountStatus, setDiscountStatus] = useState<boolean>(false);
  const [tokenStatus, setTokenStatus] = useState<boolean>(false);
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState<boolean>(false);
  const [needsAllowance, setNeedsAllowance] = useState<boolean>(false);
  const [estMinShares, setEstMinShares] = useState<string>('0');
  const [sharesToMint, setSharesToMint] = useState<string>('0');
  const [amountDec, setAmountDec] = useState<string>('0');
  const [usingDiscount, setUsingDiscount] = useState<boolean>(false);
  const [redeemResponse, setRedeemResponse] = useState<RedeemResponse>({} as RedeemResponse);

  ethereumClient.watchAccount(async () => {
    const shareBalance = await getSpotVaultBalance(props.selectedSpotVault);
    setUserAssetBalance(shareBalance);
  });

  useEffect(() => {
    async function init() {
      try {
        const shareBalance = await getSpotVaultBalance(props.selectedSpotVault);
        setUserAssetBalance(shareBalance);
      } catch (error) {
        console.error('Failed to init:', error);
      }
    }
    init();
  }, []);

  const formatNumString = (numString: string) => {
    return Number(numString).toLocaleString(undefined, { maximumFractionDigits: 3 });
  };

  const formatNum = (request: Request) => {
    let res = '';
    if (request) {
      res = (request.requestAmount + request.requestFeeInUsd).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumSignificantDigits: 4,
      });
    }
    return res;
  };

  // const onChange = (newValue: number | null) => {
  //   setInputValue(newValue ? newValue : 0);
  //   console.log(newValue, inputValue);
  // };

  const getRedeemParams = async () => {
    setEstMinShares(`0`);
    setSharesToMint(`0`);
    setAmountDec(`0`);
    setUsingDiscount(false);

    setIsMRModalOpen(true);
    const res = await callRedeem(
      BNB_VAULT_ADDRESSES[props.selectedSpotVault],
      ethers.utils.formatEther(inputValue),
      depositable.address,
      useDiscount,
    );
    console.log('%%%%%%%% %%%%% redeem response:', res);
    setEstMinShares(formatNumString(res.estMinRcvToken));
    setSharesToMint(formatNumString(res.tokensToReturn));
    setAmountDec(formatNumString(res.shareAmountDec));
    setUsingDiscount(res.useDiscount);
    setRedeemResponse(res);
  };

  const onDropdown = async (value: string) => {
    setIsLoading(true);
    setDepositable(DEPOSITABLES[value]);
    console.log('%%%% set new depositable', depositable.address, depositable.symbol);
    // if (value != `BNB`) {
    //   setUserAssetBalance(await getSpotBalanceERC20(DEPOSITABLES[value].address));
    // } else {
    //   setUserAssetBalance(await getSpotBalanceBnb());
    // }
    // console.log(`%%%%userAssetBalance: ${userAssetBalance}`);
    setIsLoading(false);
  };

  const onDiscountSwitch = (checked: boolean) => {
    console.log(`%switch to ${checked}`);
    setUseDiscount(checked);
  };

  const onSliderChange = (pctValue: number | null) => {
    const balanceBN = ethers.utils.parseUnits(userAssetBalance, DECIMALS);
    const percentageBN = ethers.utils.parseUnits(pctValue ? pctValue.toString() : '0', DECIMALS);
    const computed = balanceBN.mul(percentageBN).div(100).div(UNIT18);
    setInputValue(computed);
  };

  const onInputChange = (valueIn: number | null) => {
    // const balance = Number(props.usdcBal);
    // setInputValue(inputValue ? Number(inputValue.toFixed(18)) : 0);
    const val = ethers.utils.parseUnits(valueIn ? valueIn.toString() : '0', DECIMALS);
    setInputValue(val);
  };

  const onActionClick = async () => {
    setIsLoading(true);
    await switchToBnb();
    let discountApproved = true;
    await refresh();
    if (useDiscount) {
      discountApproved = await getDiscountApproveStatus(BNB_VAULT_ADDRESSES[props.selectedSpotVault], useDiscount);
      console.log('%%%%% discountApproved: ', discountApproved);
      if (!discountApproved) {
        setIsDiscountModalOpen(true);
        setIsLoading(false);
      }
    }

    if (discountApproved) {
      const spotTokenApproved = await getSpotVaultApproveStatus(props.selectedSpotVault, inputValue);
      if (spotTokenApproved) {
        await getRedeemParams();
      } else {
        setIsApproveModalOpen(true);
      }
    } else if (!useDiscount) {
      await getRedeemParams();
    }
    setIsLoading(false);
  };

  const approveDiscount = async () => {
    setIsDiscountModalOpen(false);

    try {
      const { transaction } = await discountTokenAllow(BNB_VAULT_ADDRESSES[props.selectedSpotVault]);
      const tokenApproved =
        depositable.symbol != `BNB`
          ? await getTokenApproveStatus(
              BNB_VAULT_ADDRESSES[props.selectedSpotVault],
              BNB_VAULT_ADDRESSES[props.selectedSpotVault],
              inputValue,
            )
          : true;
      // Show notifications
      toast
        .promise(transaction, {
          pending: 'Processing YIEDL approval…',
          success: 'YIEDL Approval completed.',
          error: 'Error with YIEDL approval request.',
        })
        .then(async (result) => {
          if (tokenApproved) {
            await getRedeemParams();
            // setIsMRModalOpen(true);
          } else {
            setIsApproveModalOpen(true);
          }
        });
    } catch (err: any) {
      console.log('***', err);
      if (err.code === 'ACTION_REJECTED') {
        toast.info('YIEDL Approval cancelled by user.', {
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: true,
        });
      } else {
        toast.error('Error with YIEDL approval request.');
      }
    }
  };

  const approve = async () => {
    setIsApproveModalOpen(false);
    try {
      const { transaction } = await tokenAllow(
        BNB_VAULT_ADDRESSES[props.selectedSpotVault],
        BNB_VAULT_ADDRESSES[props.selectedSpotVault],
      );
      // Show notifications
      toast
        .promise(transaction, {
          pending: 'Processing approval…',
          success: 'Approval completed.',
          error: 'Error with approval request.',
        })
        .then(async (result) => {
          setIsApproveModalOpen(false);
          // setIsMRModalOpen(true);
          await getRedeemParams();
        });
    } catch (err: any) {
      console.log('***', err);
      if (err.code === 'ACTION_REJECTED') {
        toast.info('Approval cancelled by user.', {
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: true,
        });
      } else {
        toast.error('Error with approval request.');
      }
    }
  };

  const redemption = async () => {
    setIsMRModalOpen(false);
    let transaction: Promise<ethers.providers.TransactionReceipt>;

    try {
      setIsLoading(true);
      transaction = (await spotRedeem(props.selectedSpotVault, redeemResponse)).transaction;
    } catch (err: any) {
      console.log('%%%%%%%% redeem err', err);

      if (err.code === 'ACTION_REJECTED') {
        toast.info('Redeeming cancelled by user.', {
          autoClose: 3000,
          closeButton: true,
          hideProgressBar: true,
        });
      } else {
        toast.error('Error redeeming.');
      }
      setIsLoading(false);
      return;
    }

    const loadingToastId = toast.loading('Please wait while your redemption request is being processed.');
    try {
      await transaction;
    } catch (err: any) {
      toast.update(loadingToastId, {
        render: 'Error processing redemption request.',
        type: toast.TYPE.ERROR,
        isLoading: false,
      });
      setIsLoading(false);
      return;
    }

    toast.update(loadingToastId, {
      render: `Redemption request of ${ethers.utils.formatUnits(inputValue, DECIMALS)} ${props.shareSymbol} complete.`,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 7000,
      closeButton: true,
      hideProgressBar: true,
    });

    setInputValue(BigNumber.from(0));
    setIsLoading(false);
    setUserAssetBalance(await getSpotVaultBalance(props.selectedSpotVault));
  };

  const theme = useAppSelector((state) => state.theme.theme);

  return (
    <>
      <BaseSpin spinning={props.spinning}>
        <ToastContainer />
        <DashboardCard title={t(`Redeem ${props.shareSymbol} Shares`)} bordered={true}>
          {props.connected ? (
            <>
              <BaseRow gutter={[20, 20]} justify={'space-between'}>
                {/*<BaseButtonsForm.Item name="input-number" noStyle>*/}
                <BaseCol>
                  <InputNumber
                    style={{ minWidth: 180 }}
                    min={0}
                    max={Number(userAssetBalance)}
                    onChange={onInputChange}
                    value={roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6)}
                  />
                  {/*</BaseButtonsForm.Item>*/}
                  {/*<span style={{ fontSize: "small" }}> {t(`${props.shareSymbol}`)}</span>*/}
                  <span> {t(`${props.shareSymbol}`)}</span>
                  {/*<BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: 5 }}>*/}
                  {/*  /!*<BaseCol>Use Discount:</BaseCol>*!/*/}
                  {/*  <BaseCol>*/}
                  {/*    Use Discount*/}
                  {/*    <Switch*/}
                  {/*      style={{ marginLeft: 20 }}*/}
                  {/*      loading={isLoading}*/}
                  {/*      checkedChildren="yes"*/}
                  {/*      unCheckedChildren="no"*/}
                  {/*      defaultChecked={false}*/}
                  {/*      onChange={onDiscountSwitch}*/}
                  {/*    />*/}
                  {/*  </BaseCol>*/}
                  {/*</BaseRow>*/}
                </BaseCol>
                <BaseCol>
                  Receive redemption in:
                  <Select
                    bordered={true}
                    loading={isLoading}
                    showArrow={true}
                    defaultValue="USDC"
                    style={{ width: 100, marginLeft: 10 }}
                    onChange={async (item) => {
                      await onDropdown(item);
                    }}
                    options={[
                      { value: 'USDC', label: 'USDC', disabled: false },
                      { value: 'USDT', label: 'USDT', disabled: false },
                      { value: 'BNB', label: 'BNB', disabled: false },
                      { value: 'ETH', label: 'ETH', disabled: false },
                    ]}
                  />
                </BaseCol>
                <BaseCol>
                  <BaseButton
                    type="primary"
                    style={{ minWidth: 100 }}
                    loading={isLoading}
                    onClick={onActionClick}
                    disabled={inputValue.lte(0) || inputValue.gt(ethers.utils.parseUnits(userAssetBalance, DECIMALS))}
                  >
                    {t('Redeem')}
                  </BaseButton>
                </BaseCol>
              </BaseRow>
              {/*<BaseRow>*/}
              {/*  <BaseCol>*/}
              {/*    <span style={{ fontSize: 'small' }}>*/}
              {/*      {' '}*/}
              {/*      {`Est Value: ${(isNaN(Number(props.inav))*/}
              {/*        ? 0*/}
              {/*        : Number(ethers.utils.formatUnits(inputValue, DECIMALS)) * Number(props.inav) * 0.97*/}
              {/*      ).toLocaleString(undefined, {*/}
              {/*        maximumFractionDigits: 0,*/}
              {/*      })} USDC`}*/}
              {/*    </span>*/}
              {/*  </BaseCol>*/}
              {/*</BaseRow>*/}
              {/*</BaseButtonsForm.Item>*/}

              {/*<BaseButtonsForm.Item*/}
              {/*  name="slider"*/}
              {/*  // label={t('forms.validationFormLabels.slider')}*/}
              {/*>*/}
              <BaseCol style={{ marginTop: '2vh' }}>
                <BaseSlider
                  style={{ marginLeft: '2vh', marginRight: '2vh' }}
                  tooltip={{ open: false }}
                  marks={{
                    0: '0%',
                    25: '25%',
                    50: '50%',
                    75: '75%',
                    100: '100%',
                  }}
                  onChange={onSliderChange}
                  value={
                    (100 * roundDown(ethers.utils.formatUnits(inputValue, DECIMALS), 6)) / Number(userAssetBalance)
                  }
                  min={0}
                  max={100}
                />
              </BaseCol>

              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '2vh' }}>
                <BaseCol>Balance:</BaseCol>
                <BaseCol>
                  {formatNumString(userAssetBalance)} {props.shareSymbol}
                </BaseCol>
              </BaseRow>

              {/*<BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '1vh' }}>*/}
              {/*  <BaseCol>Pending Redeems:</BaseCol>*/}
              {/*  <BaseCol>*/}
              {/*    {formatNum(props.pending)} {props.shareSymbol}*/}
              {/*  </BaseCol>*/}
              {/*</BaseRow>*/}

              {/*<BaseRow gutter={[20, 20]} justify={'end'} style={{ marginTop: '1vh' }}>*/}
              {/*  <BaseCol>*/}
              {/*    <BaseButton*/}
              {/*      type="default"*/}
              {/*      size="small"*/}
              {/*      style={{ minWidth: 100, marginBottom: '0rem', fontWeight: 'normal' }}*/}
              {/*      loading={isLoading}*/}
              {/*      // onClick={cancel}*/}
              {/*      disabled={props.pending ? props.pending.requestAmount <= 0 : true}*/}
              {/*    >*/}
              {/*      {t('Cancel Request')}*/}
              {/*    </BaseButton>*/}
              {/*  </BaseCol>*/}
              {/*</BaseRow>*/}

              {/* Fee Section */}
              <BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '1vh' }}>
                <BaseCol>
                  {/*<BaseSpace direction="horizontal" size={6} style={{ marginTop: '0vh', marginBottom: '0vh' }}>*/}
                  <span style={{ fontSize: 'small', fontWeight: 'normal' }}>{`Protocol Fee:`}</span>
                  {/*</BaseSpace>*/}
                </BaseCol>

                <BaseSpin spinning={props.spinning}>
                  <BaseCol style={{ textAlign: 'right', margin: '0vh' }}>
                    <P.ValueText style={{ fontSize: 'small' }}>{`0.5%`}</P.ValueText>
                  </BaseCol>
                </BaseSpin>
              </BaseRow>

              {/*<BaseRow gutter={[20, 20]} justify={'space-between'} style={{ marginTop: '0vh' }}>*/}
              {/*  <BaseCol>*/}
              {/*    /!*<BaseSpace direction="horizontal" size={6} style={{ marginTop: '0vh', marginBottom: '0vh' }}>*!/*/}
              {/*    /!*<Popover trigger="hover" content={props.tooltipContent}>*!/*/}
              {/*    <span style={{ fontSize: 'small', fontWeight: 'normal' }}>{`Fee after YIEDL Discount:`}</span>*/}
              {/*    /!*</Popover>*!/*/}
              {/*    /!*</BaseSpace>*!/*/}
              {/*  </BaseCol>*/}

              {/*  <BaseSpin spinning={props.spinning}>*/}
              {/*    <BaseCol style={{ textAlign: 'right', margin: '0vh' }}>*/}
              {/*      <P.ValueText style={{ fontSize: 'small' }}>{`0.25%`}</P.ValueText>*/}
              {/*    </BaseCol>*/}
              {/*  </BaseSpin>*/}
              {/*</BaseRow>*/}

              {/*  Approve Modal*/}
              <BaseModal
                title={t(`Approve YIEDL for Discount`)}
                centered
                open={isDiscountModalOpen}
                onOk={approveDiscount}
                onCancel={() => {
                  setIsDiscountModalOpen(false);
                  setIsLoading(false);
                }}
                okText={t('Approve')}
                size="medium"
              >
                <p>{t(`Permission required to interact with your YIEDL tokens.`)}</p>
              </BaseModal>

              <BaseModal
                title={t(`Approve ${props.shareSymbol}`)}
                centered
                open={isApproveModalOpen}
                onOk={approve}
                onCancel={() => {
                  setIsApproveModalOpen(false);
                  setIsLoading(false);
                }}
                okText={t('Approve')}
                size="medium"
              >
                <p>{t(`Permission required to interact with your ${props.shareSymbol} tokens.`)}</p>
              </BaseModal>

              {/*  Deposit Modal*/}
              <BaseModal
                title={t('Redeem')}
                centered
                open={isMRModalOpen}
                onOk={redemption}
                onCancel={() => {
                  setIsMRModalOpen(false);
                  setIsLoading(false);
                }}
                okButtonProps={{
                  disabled: estMinShares === `0` || estMinShares === `-1`,
                }}
                cancelButtonProps={{
                  disabled: estMinShares === `0`,
                }}
                okText={t('Proceed')}
                size="medium"
              >
                {estMinShares === `0` ? (
                  <>
                    <p>Computing estimates. This may take up to 2 minutes.</p>
                    <p>{`Please do not navigate away or refresh the page.`}</p>
                    <BaseSpin></BaseSpin>
                  </>
                ) : estMinShares === `-1` ? (
                  <>
                    <p>Error computing. Please check that you have sufficient balances and allowances.</p>
                    <p>Otherwise, try a larger amount.</p>
                  </>
                ) : (
                  <>
                    <p>{`Input amount: ${amountDec} ${props.shareSymbol}`}</p>
                    <p>{`Estimated tokens to receive: ${sharesToMint} ${depositable.symbol}`}</p>
                    <p>{`Using discount: ${usingDiscount ? 'Yes' : 'No'}`}</p>
                  </>
                )}
              </BaseModal>
            </>
          ) : (
            `Please connect wallet to redeem.`
          )}
        </DashboardCard>
      </BaseSpin>
    </>
  );
};
