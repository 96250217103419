import React from 'react';
import * as S from './References.styles';
import { GithubOutlined, LinkedinOutlined, TwitterOutlined, MediumOutlined } from '@ant-design/icons';
import synthetixLogo from '@app/assets/synthetix-blue.svg';
import immunebytesLogo from '@app/assets/immunebytes.svg';
import chainalysis from '@app/assets/chainalysis.svg';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Divider, Space } from 'antd';
import { BaseDivider } from '@app/components/common/BaseDivider/BaseDivider';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { themeObject } from '@app/styles/themes/themeVariables';

interface ReferencesProps {
  isCollapsed: boolean;
}

export const References: React.FC<ReferencesProps> = (props) => {
  // console.log("collapsed", props.isCollapsed)
  const { isTablet, isDesktop } = useResponsive();
  const theme = useAppSelector((state) => state.theme.theme);

  return (
    // <S.ReferencesWrapper style={{ position: isDesktop ? 'absolute' : 'relative' }}>
    <BaseCol
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        flexWrap: 'nowrap',
        marginTop: '7rem',
        marginBottom: '1rem',
        position: isDesktop || isTablet ? 'static' : 'static',
        bottom: '0',
      }}
    >
      <BaseRow>
        <S.Icons
          style={{
            justifyContent: 'center',
            fontSize: props.isCollapsed ? '0.5rem' : '1rem',
          }}
        >
          Powered by &nbsp;
        </S.Icons>
      </BaseRow>
      <BaseRow align="middle">
        <a
          href={`https://synthetix.io`}
          rel="noopener noreferrer"
          target="_blank"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={synthetixLogo}
            style={{
              height: props.isCollapsed ? '0.3rem' : '0.6rem',
              padding: '0 1rem',
            }}
            alt="synthetix logo"
          />
        </a>
      </BaseRow>
      <BaseRow style={{ marginTop: '2vh', marginBottom: '0.3vh' }}>
        <S.Icons
          style={{
            justifyContent: 'center',
            fontSize: props.isCollapsed ? '0.5rem' : '1rem',
          }}
        >
          Audited by &nbsp;
        </S.Icons>
      </BaseRow>
      <BaseRow align="middle">
        <a
          href={`https://www.immunebytes.com`}
          rel="noopener noreferrer"
          target="_blank"
          style={{
            width: props.isCollapsed ? '5.5rem' : '8rem',
            padding: '0 1rem',
            marginTop: props.isCollapsed ? '-2.8rem' : '-4.8rem',
          }}
        >
          <img src={immunebytesLogo} alt="immunebytes logo" />
        </a>
      </BaseRow>

      <BaseRow style={{ marginTop: '2vh', marginBottom: '0vh' }}>
        <S.Icons
          style={{
            justifyContent: 'center',
            fontSize: props.isCollapsed ? '0.5rem' : '1rem',
          }}
        >
          Sanctions oracle &nbsp;
        </S.Icons>
      </BaseRow>
      <BaseRow align="middle">
        <a
          href={`https://go.chainalysis.com/chainalysis-oracle-docs.html`}
          rel="noopener noreferrer"
          target="_blank"
          style={{
            width: props.isCollapsed ? '5.5rem' : '10rem',
            padding: '0 1rem',
            marginTop: props.isCollapsed ? '-0rem' : '0rem',
          }}
        >
          <img src={chainalysis} alt="chainalysis logo" />
        </a>
      </BaseRow>

      <BaseRow style={{ marginTop: '2rem', marginLeft: '0.5rem' }} gutter={props.isCollapsed ? [5, 0] : [10, 10]}>
        {/*<S.Icons>*/}
        {/*  <BaseCol>*/}
        <a style={{ margin: '0.5rem' }} href="https://twitter.com/Yiedl_DAO" target="_blank" rel="noreferrer">
          <TwitterOutlined
            style={{
              fontSize: props.isCollapsed ? '1rem' : '1rem',
              color: theme == 'dark' ? undefined : 'white',
            }}
          />
        </a>
        {/*</BaseCol>*/}
        {/*<BaseCol>*/}
        <a
          style={{ margin: '0.5rem' }}
          href="https://www.linkedin.com/company/rocketcapitalinvestment"
          target="_blank"
          rel="noreferrer"
        >
          <LinkedinOutlined
            style={{
              fontSize: props.isCollapsed ? '1rem' : '1rem',
              color: theme == 'dark' ? undefined : 'white',
            }}
          />
        </a>

        <a style={{ margin: '0.5rem' }} href="https://medium.com/@yiedlai" target="_blank" rel="noreferrer">
          <MediumOutlined
            style={{
              fontSize: props.isCollapsed ? '1rem' : '1rem',
              color: theme == 'dark' ? undefined : 'white',
            }}
          />
        </a>

        <a
          style={{ margin: '0.5rem' }}
          href="https://github.com/rocketcapital-ai/vaults"
          target="_blank"
          rel="noreferrer"
        >
          <GithubOutlined
            style={{
              fontSize: props.isCollapsed ? '1rem' : '1rem',
              color: theme == 'dark' ? undefined : 'white',
            }}
          />
        </a>
        {/*</BaseCol>*/}
        {/*<BaseCol>*/}
        <a style={{ margin: '0.5rem' }} href="https://discord.com/invite/r6NbnCSrzn" target="_blank" rel="noreferrer">
          <FontAwesomeIcon
            icon={faDiscord}
            style={{
              fontSize: props.isCollapsed ? '1rem' : '1rem',
              color: theme == 'dark' ? undefined : 'white',
            }}
          />
        </a>
        {/*</BaseCol>*/}
        {/*</S.Icons>*/}
        {/*</BaseRow>*/}
        {/*<BaseRow style={{ marginTop: '0.2rem' }} gutter={props.isCollapsed ? [5, 0] : [10, 10]}>*/}
        {/*  <S.Icons>*/}
        {/*    <BaseCol>*/}
        <a style={{ margin: '0.5rem' }} href="https://docs.yiedl.ai/yiedl-vaults" target="_blank" rel="noreferrer">
          <FontAwesomeIcon
            icon={faBookOpen}
            style={{
              fontSize: props.isCollapsed ? '1rem' : '1rem',
              color: theme == 'dark' ? undefined : 'white',
            }}
          />
        </a>
        {/*</BaseCol>*/}
        {/*</S.Icons>*/}
      </BaseRow>
    </BaseCol>
    // </S.ReferencesWrapper>
  );
};
