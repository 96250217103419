import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '@app/hooks/useResponsive';
import { StatisticColor } from '@app/constants/config/statistics';
import * as S from '../statisticsCards/statisticsCard/StatisticsCard/StatisticsCard.styles';
import * as I from '../statisticsCards/statisticsCard/StatisticsInfo/StatisticsInfo.styles';
import * as P from '../statisticsCards/statisticsCard/StatisticsProgress/StatisticsProgress.styles';
import { themeObject } from '@app/styles/themes/themeVariables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';

interface AumCardProps {
  title: string;
  value: string;
  spinning: boolean;
  unit?: string;
  tip: string;
}

export const GenericCard: React.FC<AumCardProps> = (props: AumCardProps) => {
  const theme = useAppSelector((state) => state.theme.theme);
  const { isTablet: isTabletOrHigher } = useResponsive();

  const { t } = useTranslation();

  return (
    <S.StatisticCard padding="0.0rem" $color={'success'}>
      <BaseRow wrap={false} gutter={[isTabletOrHigher ? 10 : 5, 0]}>
        <BaseCol flex={10}>
          <BaseRow align="middle" wrap={false} gutter={[5, 0]}>
            <BaseCol>
              <BaseSpace direction="horizontal" size={10} style={{ marginTop: '0vh', marginBottom: '0vh' }}>
                <I.Title>{props.title}</I.Title>
              </BaseSpace>
            </BaseCol>

            <BaseSpin spinning={props.spinning} tip={props.tip}>
              <BaseCol style={{ textAlign: 'center' }}>
                <P.ValueText>{props.value}</P.ValueText>
                {/*<br />*/}
                <P.UnitText>{props.unit}</P.UnitText>
              </BaseCol>
            </BaseSpin>
          </BaseRow>
        </BaseCol>
      </BaseRow>
    </S.StatisticCard>
  );
};
