import React from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';

import neutralLogo from '@app/assets/tokens/neutral_sm.png';
import upLogo from '@app/assets/tokens/up_sm.png';
import downLogo from '@app/assets/tokens/down_sm.png';
import supLogo from '@app/assets/tokens/sup_sm.png';
import ibtcLogo from '@app/assets/tokens/ibtc_sm.png';
import iethLogo from '@app/assets/tokens/ieth_sm.png';
import bnbLogo from '@app/assets/tokens/bnb.png';
import opLogo from '@app/assets/tokens/op.png';

import { WagmiConfig } from 'wagmi';
import { Web3Modal } from '@web3modal/react';
import {
  ethereumClient,
  wagmiConfig,
  networkChains,
  projectId,
  VAULT_IDX,
  activeUser,
  Portfolio,
  Request,
  getPendingDeposits,
  getPendingRedeems,
  OperationsCache,
  MarketFeed,
  getMinUsdc,
  getCompletedRequests,
  CompletedRequests,
  SpotAllocations,
  DECIMALS,
  ACTUAL_DATA_EPOCH_MS,
  CHART_OFFSETS,
  SPOT_IDX,
  EMPTY_SPOT_ALLOCATIONS,
  BNB_ACTUAL_DATA_EPOCH_MS,
  BNB_CHART_OFFSETS,
  BNB_ACTUAL_CHART_OFFSETS,
  OverviewData,
  Y_PUP_PARAMS,
  Y_PDOWN_PARAMS,
  Y_NEUTRAL_PARAMS,
} from './lib/contracts';
import * as contracts from './lib/contracts';
import { useEffect } from 'react';
import { MarketResponseObject } from '@dydxprotocol/v3-client';
import { ethers } from 'ethers';
import { BNB_VAULT_ADDRESSES } from '@app/lib/BnbItems';

const MIN15_IN_MS = 1000 * 60 * 15;
const MIN30_IN_MS = 1000 * 60 * 30;
const HOUR_IN_MS = 1000 * 60 * 60;
const DAY_IN_MS = 1000 * 60 * 60 * 24;
const WEEK_IN_MS = DAY_IN_MS * 7;
const MONTH_IN_MS = DAY_IN_MS * 30;
const YEAR_MS = 52 * 7 * 24 * 60 * 60 * 1000;
const RISK_COLORS = ['success', 'primary', 'border', 'warning', 'error'];

interface MetricsType {
  minCAGR: string;
  maxCAGR: string;
  currentCAGR: string;
  minCalmar: string;
  maxCalmar: string;
  currentCalmar: string;
  minProfitFactor: string;
  maxProfitFactor: string;
  profitFactor: string;
  minSharpeRatio: string;
  maxSharpeRatio: string;
  sharpeRatio: string;
  maxDD: string;
}

const computeMetrics = (inavs: Array<{ x: number; y: number }>) => {
  // CAGR, Calmar, DD
  let minCAGR = Number.POSITIVE_INFINITY;
  let maxCAGR = Number.NEGATIVE_INFINITY;
  let currentCAGR = Number.NaN;
  let maxNavSoFar = Number.NEGATIVE_INFINITY;
  let maxDD = 0;
  let latestDD = 0;

  let minCalmar = Number.POSITIVE_INFINITY;
  let maxCalmar = Number.NEGATIVE_INFINITY;
  let currentCalmar = Number.NaN;

  const firstInav = inavs[0].y;
  for (let i = 1; i < inavs.length; i++) {
    const thisInav = inavs[i].y * 1;

    maxNavSoFar = Math.max(maxNavSoFar, thisInav);
    latestDD = thisInav / maxNavSoFar - 1;
    maxDD = Math.min(maxDD, latestDD);

    const cagr = (thisInav / firstInav) ** (YEAR_MS / (inavs[i].x - inavs[0].x)) - 1;
    const calmar = maxDD !== 0 ? cagr / Math.abs(maxDD) : null;

    minCAGR = minCAGR > cagr ? cagr : minCAGR;
    maxCAGR = Math.max(maxCAGR, cagr);
    currentCAGR = cagr;

    if (calmar !== null) {
      minCalmar = Math.min(minCalmar, calmar);
      maxCalmar = Math.max(maxCalmar, calmar);
      currentCalmar = calmar;
    }
  }

  // profit factor and sharpe ratio
  let minProfitFactor = Number.POSITIVE_INFINITY;
  let maxProfitFactor = Number.NEGATIVE_INFINITY;
  let profitFactor = Number.NaN;
  let minSharpeRatio = Number.POSITIVE_INFINITY;
  let maxSharpeRatio = Number.NEGATIVE_INFINITY;
  let sharpeRatio = Number.NaN;

  let returns = 0;
  let returnsSquared = 0;
  let returnsCount = 0;
  let returnsSum = 0;
  let returnsSumSquared = 0;

  if (inavs.length < 2) {
    minProfitFactor = 1;
    maxProfitFactor = 1;
    profitFactor = 1;
  } else {
    let profit = 0;
    let loss = 0;

    for (let i = 1; i < inavs.length; i++) {
      const thisInav = inavs[i].y * 1;
      const prevInav = inavs[i - 1].y * 1;

      const diff = thisInav - prevInav;

      returns = diff;
      returnsSquared = diff * diff;
      returnsSum += diff;
      returnsSumSquared += diff * diff;
      returnsCount += 1;

      const mean = returnsSum / returnsCount;
      const meanOfSquares = returnsSumSquared / returnsCount;
      const variance = meanOfSquares - mean * mean;
      const stdDev = Math.sqrt(variance);

      sharpeRatio = (mean / stdDev) * Math.sqrt(365);
      minSharpeRatio = Math.min(minSharpeRatio, sharpeRatio);
      maxSharpeRatio = Math.max(maxSharpeRatio, sharpeRatio);

      if (diff > 0) {
        profit += diff;
      } else {
        loss -= diff;
      }

      profitFactor = loss === 0 ? 1 : profit / loss;
      minProfitFactor = Math.min(minProfitFactor, profitFactor);
      maxProfitFactor = Math.max(maxProfitFactor, profitFactor);
    }
  }

  const result = [
    minCAGR,
    maxCAGR,
    currentCAGR * 100,
    minCalmar,
    maxCalmar,
    currentCalmar,
    minProfitFactor,
    maxProfitFactor,
    profitFactor,
    minSharpeRatio,
    maxSharpeRatio,
    sharpeRatio,
    maxDD * 100,
  ];

  const resultString = result.map((r) => r.toLocaleString(undefined, { maximumFractionDigits: 2 }));
  const metrics: MetricsType = {
    minCAGR: resultString[0],
    maxCAGR: resultString[1],
    currentCAGR: resultString[2],
    minCalmar: resultString[3],
    maxCalmar: resultString[4],
    currentCalmar: resultString[5],
    minProfitFactor: resultString[6],
    maxProfitFactor: resultString[7],
    profitFactor: resultString[8],
    minSharpeRatio: resultString[9],
    maxSharpeRatio: resultString[10],
    sharpeRatio: resultString[11],
    maxDD: resultString[12],
  };

  return metrics;
};

function bnbParseChartData(histArray: any[], aums: any[], shareVals: any[]) {
  const res = [];

  for (let idx = 0; idx < histArray.length; idx++) {
    const timestamps = histArray[idx][0];
    const navVals = histArray[idx][1];

    const aum: { x: number; y: number }[] = [];
    const historicalInav: { x: number; y: number }[] = [];
    const returnsRatio: { x: number; y: number }[] = [];
    let last_nav = 1;
    let first_nav = Number(ethers.utils.formatUnits(navVals[0], DECIMALS));

    // console.log("%%%%%%% ============", first_nav)

    const firstTimestamp = Number(timestamps[0]);
    if (firstTimestamp < ACTUAL_DATA_EPOCH_MS) {
      first_nav = first_nav;
    } else if (firstTimestamp < BNB_ACTUAL_DATA_EPOCH_MS) {
      first_nav = first_nav + BNB_ACTUAL_CHART_OFFSETS[idx];
    } else {
      first_nav = first_nav + BNB_ACTUAL_CHART_OFFSETS[idx] + BNB_CHART_OFFSETS[idx];
    }

    // first_nav = Number(timestamps[0]) >= BNB_ACTUAL_DATA_EPOCH_MS ? first_nav + BNB_CHART_OFFSETS[idx] : first_nav;
    for (let i = 0; i < timestamps.length; i++) {
      last_nav =
        Number(ethers.utils.formatUnits(navVals[i], DECIMALS)) > 0
          ? Number(ethers.utils.formatUnits(navVals[i], DECIMALS))
          : last_nav;
      const x_time = Number(timestamps[i]);

      if (x_time < ACTUAL_DATA_EPOCH_MS) {
        last_nav = last_nav;
      } else if (x_time < BNB_ACTUAL_DATA_EPOCH_MS) {
        last_nav = last_nav + BNB_ACTUAL_CHART_OFFSETS[idx];
      } else {
        last_nav = last_nav + BNB_ACTUAL_CHART_OFFSETS[idx] + BNB_CHART_OFFSETS[idx];
      }

      // last_nav = x_time >= BNB_ACTUAL_DATA_EPOCH_MS ? last_nav + BNB_CHART_OFFSETS[idx] : last_nav;

      // console.log("%%%%%%%% MAMA", x_time, last_nav, first_nav, (last_nav - first_nav) / first_nav);

      returnsRatio.push({
        x: x_time,
        y: (last_nav - first_nav) / first_nav,
      });

      historicalInav.push({
        x: x_time,
        y: last_nav,
      });
    }

    // add latest value
    const latestAum = aums[idx];
    const latestShares = shareVals[idx];
    const latestTime = Date.now();
    aum.push({ x: latestTime, y: latestAum });

    let latestNav = latestAum / latestShares;

    if (latestTime < ACTUAL_DATA_EPOCH_MS) {
      latestNav = latestNav;
    } else if (latestTime < BNB_ACTUAL_DATA_EPOCH_MS) {
      latestNav = latestNav + BNB_ACTUAL_CHART_OFFSETS[idx];
    } else {
      latestNav = latestNav + BNB_ACTUAL_CHART_OFFSETS[idx] + BNB_CHART_OFFSETS[idx];
    }

    // latestNav = latestTime >= BNB_ACTUAL_DATA_EPOCH_MS ? latestNav + BNB_CHART_OFFSETS[idx] : latestNav;
    returnsRatio.push({ x: latestTime, y: (latestNav - first_nav) / first_nav });
    historicalInav.push({ x: latestTime, y: latestNav });
    returnsRatio.sort((a, b) => a.x - b.x);
    res.push([aum, historicalInav, returnsRatio]);
  }

  return res;
}

function parseChartData(histArray: any[], aums: any[], shareVals: any[], inavs: any[]) {
  const res = [];

  for (let idx = 0; idx < histArray.length; idx++) {
    const timestamps = histArray[idx][0];
    const navVals = histArray[idx][1];

    const aum: { x: number; y: number }[] = [];
    const historicalInav: { x: number; y: number }[] = [];
    const returnsRatio: { x: number; y: number }[] = [];
    let last_nav = 1;
    let first_nav = Number(ethers.utils.formatUnits(navVals[0], DECIMALS));

    first_nav = Number(timestamps[0]) >= ACTUAL_DATA_EPOCH_MS ? first_nav + CHART_OFFSETS[idx] : first_nav;
    for (let i = 0; i < timestamps.length; i++) {
      last_nav =
        Number(ethers.utils.formatUnits(navVals[i], DECIMALS)) > 0
          ? Number(ethers.utils.formatUnits(navVals[i], DECIMALS))
          : last_nav;
      const x_time = Number(timestamps[i]);
      last_nav = x_time >= ACTUAL_DATA_EPOCH_MS ? last_nav + CHART_OFFSETS[idx] : last_nav;

      returnsRatio.push({
        x: x_time,
        y: (last_nav - first_nav) / first_nav,
      });

      historicalInav.push({
        x: x_time,
        y: last_nav,
      });
    }

    // add latest value
    const latestAum = aums[idx];
    const latestShares = shareVals[idx];
    const latestTime = Date.now();
    aum.push({ x: latestTime, y: latestAum });

    let latestNav = latestAum / latestShares;
    latestNav = latestTime >= ACTUAL_DATA_EPOCH_MS ? latestNav + CHART_OFFSETS[idx] : latestNav;
    returnsRatio.push({ x: latestTime, y: (latestNav - first_nav) / first_nav });
    historicalInav.push({ x: latestTime, y: latestNav });
    returnsRatio.sort((a, b) => a.x - b.x);
    res.push([aum, historicalInav, returnsRatio]);
  }

  return res;
}

function changePct(array: Array<any>) {
  const end = array[array.length - 1].y;
  const num = 100 * end;
  return num > 0
    ? '+' + num.toLocaleString(undefined, { maximumFractionDigits: 2 })
    : num.toLocaleString(undefined, { maximumFractionDigits: 2 });
}

function pctFormatter(input: string) {
  const num = Number(input);
  return num > 0
    ? '+' + num.toLocaleString(undefined, { maximumFractionDigits: 2 })
    : num.toLocaleString(undefined, { maximumFractionDigits: 2 });
}

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  const [connected, setConnected] = React.useState(false);
  const [spinning, setSpinning] = React.useState(true);
  const [walletLoading, setWalletLoading] = React.useState(true);
  const [requestRecords, setRequestRecords] = React.useState([]);
  const [processedRecords, setProcessedRecords] = React.useState([]);
  const [numShareholders, setNumShareholders] = React.useState(['~', '~', '~', '~']);
  const [shareSupply, setShareSupply] = React.useState([0, 0, 0, 0]);
  const [aum, setAum] = React.useState([0, 0, 0, 0]);
  const [iNav, setINav] = React.useState([100, 100, 100, 100]);
  const [shareBals, setShareBals] = React.useState(['~', '~', '~', '~']);
  const [totalVal, setTotalVal] = React.useState('~');
  const [pendingDepositState, setPendingDepositState] = React.useState(0);
  const [pendingWithdrawState, setPendingWithdrawState] = React.useState(0);
  const [status, setStatus] = React.useState([true, true, true]);
  const [minDeposit, setMinDeposit] = React.useState(['~', '~', '~', '~']);
  const [minShares, setMinShares] = React.useState(['~', '~', '~', '~']);
  const [maxUsdc, setMaxUsdc] = React.useState(['~', '~', '~', '~']);
  const [overviewData, setOverviewData] = React.useState<Array<OverviewData>>([]);

  const [spotAllocations, setSpotAllocations] = React.useState<Array<SpotAllocations>>([
    EMPTY_SPOT_ALLOCATIONS,
    EMPTY_SPOT_ALLOCATIONS,
    EMPTY_SPOT_ALLOCATIONS,
  ]);
  const [spotNavs, setSpotNavs] = React.useState<Array<number>>([]);
  const [spotShareSupply, setSpotShareSupply] = React.useState<Array<number>>([]);
  const [spotShareBals, setSpotShareBals] = React.useState(['~', '~', '~']);
  const [onboardingFees, setOnboardingFees] = React.useState(['~', '~', '~', '~']);
  const [offboardingFees, setOffboardingFees] = React.useState(['~', '~', '~', '~']);
  const [orderFees, setOrderFees] = React.useState(['~', '~', '~', '~']);

  const [userPendingDeposits, setUserPendingDeposits] = React.useState<Array<Request>>([]);
  const [userPendingRedeems, setUserPendingRedeems] = React.useState<Array<Request>>([]);

  const [userCompletedRequests, setUserCompletedRequests] = React.useState<Array<CompletedRequests>>([]);

  const [userDepositsUnderProcessing, setUserDepositsUnderProcessing] = React.useState<Array<boolean>>([
    false,
    false,
    false,
    false,
  ]);

  const [userRedeemsUnderProcessing, setUserRedeemsUnderProcessing] = React.useState<Array<boolean>>([
    false,
    false,
    false,
    false,
  ]);

  const [portfolios, setPortfolios] = React.useState<Array<Portfolio | null>>([null, null, null, null]);
  const [bnbPortfolios, setBnbPortfolios] = React.useState<Array<Portfolio | null>>([null, null, null]);
  const [opsCache, setOpsCache] = React.useState<Array<OperationsCache | null>>([null, null, null, null]);
  const [processingStatus, setProcessingStatus] = React.useState<Array<number>>([0, 0, 0, 0]);

  const [vaultSelected, setVaultSelected] = React.useState(VAULT_IDX.Y_PUP);
  const [tripleSelected, setTripleSelected] = React.useState(0);

  const [spotSelected, setSpotSelected] = React.useState(SPOT_IDX.S_UP);

  const [weekHistory, setWeekHistory] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );
  const [dayHistory, setDayHistory] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );
  const [monthHistory, setMonthHistory] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );

  const [month3History, setMonth3History] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );

  const [yearHistory, setYearHistory] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );

  const [allHistory, setAllHistory] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );

  const [bnb_weekHistory, bnb_setWeekHistory] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );
  const [bnb_dayHistory, bnb_setDayHistory] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );
  const [bnb_monthHistory, bnb_setMonthHistory] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );

  const [bnb_month3History, bnb_setMonth3History] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );

  const [bnb_yearHistory, bnb_setYearHistory] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );

  const [bnb_allHistory, bnb_setAllHistory] = React.useState(
    Array(4).fill([[{ x: 0, y: 0 }], [{ x: 0, y: 0 }], [{ x: 0, y: 0 }]]),
  );

  const [marketFeed, setMarketFeed] = React.useState<MarketFeed[]>([]);
  const [info, setInfo] = React.useState({});
  const [usdcBal, setUsdcBal] = React.useState('0');

  function handleRecords(req: any, pro: any) {
    setRequestRecords(req);
    setProcessedRecords(pro);
  }
  useEffect(() => {
    (async () => {
      await contracts.setup();
      await contracts.refresh();
      // const res = await getMinUsdc();
      // console.log('======== MIN DEPOSITS ========', res);
      // console.log(`=== vaultNumShareHolders:`, await contracts.vaultNumShareHolders(), ` ======`);
      // console.log(`=== getPortfolio:`, await contracts.getPortfolio(), ` ======`);
      // console.log(`=== getOpsCache:`, await contracts.getOpsCache(), ` ======`);
      // console.log(`=== getMinUsdc:`, await contracts.getMinUsdc(), ` ======`);
      // console.log(`=== getMinShares:`, await contracts.getMinShares(), ` ======`);
      // console.log(`=== getMarketFeed:`, await contracts.getMarketFeed(), ` ======`);
      // console.log(`=== getDayHistory:`, await contracts.getDayHistory(), ` ======`);
      // console.log(`=== getWeekHistory:`, await contracts.getWeekHistory(), ` ======`);
      // console.log(`=== getMonthHistory:`, await contracts.getMonthHistory(), ` ======`);
      // setMinDeposit(res);
    })();
  }, []);

  useEffect(() => {
    let inavList: string[];
    (async () => {
      setConnected(await contracts.isConnected());
      await contracts.setup();
      await contracts.refresh();

      setConnected(await contracts.isConnected());
      setSpinning(true);
      setWalletLoading(true);
      await Promise.all([
        contracts.vaultNumShareHolders(), // 0
        contracts.getPortfolio(), // 1
        contracts.getOpsCache(), // 2
        contracts.getMinUsdc(), // 3
        contracts.getMinShares(), // 4
        contracts.getMarketFeed(), // 5
        contracts.getDayHistory(), // 6
        contracts.getWeekHistory(), // 7
        contracts.getMonthHistory(), // 8
        contracts.getOrderFees(), // 9
        contracts.hasDepositsUnderProcessing(), // 10
        contracts.hasRedeemsUnderProcessing(), // 11
        contracts.getProcessingStatuses(), // 12
        contracts.getFeeInfo(), // 13
        contracts.get3MonthHistory(), // 14
        contracts.getYearHistory(), // 15
        contracts.getAllHistory(), // 16
        contracts.getSpotAllocations(), // 17
        contracts.getSpotNavs(), // 18
        contracts.getSpotShareSupply(), // 19

        contracts.bnbGetDayHistory(), // 20
        contracts.bnbGetWeekHistory(), // 21
        contracts.bnbGetMonthHistory(), // 22
        contracts.bnbGet3MonthHistory(), // 23
        contracts.bnbGetYearHistory(), // 24
        contracts.bnbGetAllHistory(), // 25

        contracts.getPortfolio(), // 26
      ]).then((res) => {
        setNumShareholders(res[0]);

        const resPortfolios = res[1];
        setPortfolios(resPortfolios);

        const shareSupplies = resPortfolios.map((p) => p.totalShares);
        setShareSupply(shareSupplies);

        // console.log('shareSupplies', shareSupplies);

        const aums = resPortfolios.map((p) => p.aum);
        setAum(aums);

        // console.log('aums', aums);

        const inavs = resPortfolios.map((p) => {
          return p.aum / p.totalShares;
        });

        // console.log('inavs', inavs);

        setINav(inavs);

        setOpsCache(res[2]);
        setMinDeposit(res[3]);

        setMinShares(res[4]);
        setMarketFeed(res[5]);

        const synAllData = parseChartData(res[15], aums, shareSupplies, inavs);
        const synNavData = synAllData.map((d) => d[1]);
        const synUpMetrics: MetricsType = computeMetrics(synNavData[VAULT_IDX.Y_PUP]);
        const synUp: OverviewData = {
          imgSrc: upLogo,
          name: 'UP',
          address: Y_PUP_PARAMS.vaultAddress,
          aum: aums[VAULT_IDX.Y_PUP].toLocaleString(undefined, {
            maximumFractionDigits: 0,
          }),
          inav: (100 * inavs[VAULT_IDX.Y_PUP]).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }),
          strategy: 'Long',
          type: 'Perps',
          chain: opLogo,
          d7: changePct(synAllData[VAULT_IDX.Y_PUP][2]),
          riskObj: [3, RISK_COLORS[3 - 1], theme],
          cagr: pctFormatter(synUpMetrics.currentCAGR),
          sharpe: synUpMetrics.sharpeRatio,
          calmar: synUpMetrics.currentCalmar,
          rating: '..',
          age: '135',
          drawdown: pctFormatter(synUpMetrics.maxDD),
          stake: '186176',
          rb: '0.64',
        };

        const synDownMetrics: MetricsType = computeMetrics(synNavData[VAULT_IDX.Y_PDOWN]);
        const synDown: OverviewData = {
          imgSrc: downLogo,
          name: 'DOWN',
          address: Y_PDOWN_PARAMS.vaultAddress,
          aum: aums[VAULT_IDX.Y_PDOWN].toLocaleString(undefined, {
            maximumFractionDigits: 0,
          }),
          inav: (100 * inavs[VAULT_IDX.Y_PDOWN]).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }),
          strategy: 'Short',
          type: 'Perps',
          chain: opLogo,
          d7: changePct(synAllData[VAULT_IDX.Y_PDOWN][2]),
          riskObj: [5, RISK_COLORS[5 - 1], theme],
          cagr: pctFormatter(synDownMetrics.currentCAGR),
          sharpe: synDownMetrics.sharpeRatio,
          calmar: synDownMetrics.currentCalmar,
          rating: '..',
          age: '135',
          drawdown: pctFormatter(synDownMetrics.maxDD),
          stake: '189672',
          rb: '2.33',
        };

        const synNeutralMetrics: MetricsType = computeMetrics(synNavData[VAULT_IDX.Y_NEUTRAL]);
        const synNeutral: OverviewData = {
          imgSrc: neutralLogo,
          name: 'NEUTRAL',
          address: Y_NEUTRAL_PARAMS.vaultAddress,
          aum: aums[VAULT_IDX.Y_NEUTRAL].toLocaleString(undefined, {
            maximumFractionDigits: 0,
          }),
          inav: (100 * inavs[VAULT_IDX.Y_NEUTRAL]).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }),
          strategy: 'Market-Neutral',
          type: 'Perps',
          chain: opLogo,
          d7: changePct(synAllData[VAULT_IDX.Y_NEUTRAL][2]),
          riskObj: [1, RISK_COLORS[1 - 1], theme],
          cagr: pctFormatter(synNeutralMetrics.currentCAGR),
          sharpe: synNeutralMetrics.sharpeRatio,
          calmar: synNeutralMetrics.currentCalmar,
          rating: '..',
          age: '135',
          drawdown: pctFormatter(synNeutralMetrics.maxDD),
          stake: '375962',
          rb: '16.56',
        };

        setDayHistory(parseChartData(res[6], aums, shareSupplies, inavs));
        setWeekHistory(parseChartData(res[7], aums, shareSupplies, inavs));
        setMonthHistory(parseChartData(res[8], aums, shareSupplies, inavs));

        setMonth3History(parseChartData(res[14], aums, shareSupplies, inavs));
        setYearHistory(parseChartData(res[15], aums, shareSupplies, inavs));
        setAllHistory(parseChartData(res[16], aums, shareSupplies, inavs));

        setOrderFees(res[9]);
        setUserDepositsUnderProcessing(res[10]);
        setUserRedeemsUnderProcessing(res[11]);

        setProcessingStatus(res[12]);

        setOnboardingFees(res[13][0]);
        setOffboardingFees(res[13][1]);

        setSpotAllocations(res[17]);
        setSpotNavs(res[18]);
        setSpotShareSupply(res[19]);
        const spotAums = res[17].map((p) => p.aum);

        const bnbAllData = bnbParseChartData(res[25], spotAums, res[19]);
        const bnbNavData = bnbAllData.map((d) => d[1]);
        const bnbSupMetrics: MetricsType = computeMetrics(bnbNavData[0]);
        const bnbSup: OverviewData = {
          imgSrc: supLogo,
          name: 'BULL',
          address: BNB_VAULT_ADDRESSES[0],
          aum: spotAums[0].toLocaleString(undefined, {
            maximumFractionDigits: 0,
          }),
          inav: (100 * res[18][0]).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }),
          strategy: 'Long',
          type: 'Spot',
          chain: bnbLogo,
          d7: changePct(bnbAllData[0][2]),
          riskObj: [3, RISK_COLORS[3 - 1], theme],
          cagr: pctFormatter(bnbSupMetrics.currentCAGR),
          sharpe: bnbSupMetrics.sharpeRatio,
          calmar: bnbSupMetrics.currentCalmar,
          rating: '..',
          age: '135',
          drawdown: pctFormatter(bnbSupMetrics.maxDD),
          stake: '186176',
          rb: '0.64',
        };

        const bnbIbtcMetrics: MetricsType = computeMetrics(bnbNavData[1]);
        const bnbIbtc: OverviewData = {
          imgSrc: ibtcLogo,
          name: 'iBTC',
          address: BNB_VAULT_ADDRESSES[1],
          aum: spotAums[1].toLocaleString(undefined, {
            maximumFractionDigits: 0,
          }),
          inav: (100 * res[18][1]).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }),
          strategy: 'BTC Long',
          type: 'Spot',
          chain: bnbLogo,
          d7: changePct(bnbAllData[1][2]),
          riskObj: [3, RISK_COLORS[3 - 1], theme],
          cagr: pctFormatter(bnbIbtcMetrics.currentCAGR),
          sharpe: bnbIbtcMetrics.sharpeRatio,
          calmar: bnbIbtcMetrics.currentCalmar,
          rating: '..',
          age: '135',
          drawdown: pctFormatter(bnbIbtcMetrics.maxDD),
          stake: '2419',
          rb: '12.23',
        };

        const bnbIethMetrics: MetricsType = computeMetrics(bnbNavData[2]);
        const bnbIeth: OverviewData = {
          imgSrc: iethLogo,
          name: 'iETH',
          address: BNB_VAULT_ADDRESSES[2],
          aum: spotAums[2].toLocaleString(undefined, {
            maximumFractionDigits: 0,
          }),
          inav: (100 * res[18][2]).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }),
          strategy: 'ETH Long',
          type: 'Spot',
          chain: bnbLogo,
          d7: changePct(bnbAllData[2][2]),
          riskObj: [3, RISK_COLORS[3 - 1], theme],
          cagr: pctFormatter(bnbIethMetrics.currentCAGR),
          sharpe: bnbIethMetrics.sharpeRatio,
          calmar: bnbIethMetrics.currentCalmar,
          rating: '..',
          age: '135',
          drawdown: pctFormatter(bnbIethMetrics.maxDD),
          stake: '1356',
          rb: '3.76',
        };

        const overallData = [
          // bnbSup,
          synUp,
          synDown,
          synNeutral,
          // bnbIbtc, bnbIeth
        ];
        setOverviewData(overallData);

        bnb_setDayHistory(bnbParseChartData(res[20], spotAums, res[19]));
        bnb_setWeekHistory(bnbParseChartData(res[21], spotAums, res[19]));
        bnb_setMonthHistory(bnbParseChartData(res[22], spotAums, res[19]));
        bnb_setMonth3History(bnbParseChartData(res[23], spotAums, res[19]));
        bnb_setYearHistory(bnbParseChartData(res[24], spotAums, res[19]));
        bnb_setAllHistory(bnbParseChartData(res[25], spotAums, res[19]));

        setBnbPortfolios(res[26]);
      });

      setConnected(await contracts.isConnected());

      if (await contracts.isConnected()) {
        await contracts.refresh();
        // const [rr, pr] = await contracts.getRecords();
        // handleRecords(rr, pr);
        const userVaultBalances = await contracts.getShareBals();
        setShareBals(userVaultBalances);

        const userUsdc = await contracts.getSusdBal();
        setUsdcBal(userUsdc);

        const totalPortfolioValue =
          Number(userVaultBalances[contracts.VAULT_IDX.Y_UPDOWN]) * Number(iNav[contracts.VAULT_IDX.Y_UPDOWN]) +
          Number(userVaultBalances[contracts.VAULT_IDX.Y_NEUTRAL]) * Number(iNav[contracts.VAULT_IDX.Y_NEUTRAL]) +
          Number(userVaultBalances[contracts.VAULT_IDX.Y_PUP]) * Number(iNav[contracts.VAULT_IDX.Y_PUP]) +
          Number(userVaultBalances[contracts.VAULT_IDX.Y_PDOWN]) * Number(iNav[contracts.VAULT_IDX.Y_PDOWN]);
        setTotalVal(totalPortfolioValue.toFixed(2));

        const pendingDeposits = await getPendingDeposits();
        const pendingRedeems = await getPendingRedeems();
        setUserPendingDeposits(pendingDeposits);
        setUserPendingRedeems(pendingRedeems);

        const completedRequests = await getCompletedRequests();
        setUserCompletedRequests(completedRequests);
      } else {
        handleRecords([], []);
        setShareBals(['~', '~', '~', '~']);
        setTotalVal('~');
      }

      setConnected(await contracts.isConnected());
      setSpinning(false);
      setWalletLoading(false);

      setInterval(async () => {
        setConnected(await contracts.isConnected());
        await Promise.all([
          contracts.vaultNumShareHolders(), // 0
          contracts.getPortfolio(), // 1
          contracts.getOpsCache(), // 2
          contracts.getMinUsdc(), // 3
          contracts.getMinShares(), // 4
          contracts.getMarketFeed(), // 5
          contracts.getDayHistory(), // 6
          contracts.getWeekHistory(), // 7
          contracts.getMonthHistory(), // 8
          contracts.getOrderFees(), // 9
          contracts.hasDepositsUnderProcessing(), // 10
          contracts.hasRedeemsUnderProcessing(), // 11
          contracts.getProcessingStatuses(), // 12
          contracts.get3MonthHistory(), // 13
          contracts.getYearHistory(), // 14
          contracts.getAllHistory(), // 15
          contracts.getSpotAllocations(), // 16
          contracts.getSpotNavs(), // 17
          contracts.getSpotShareSupply(), // 18

          contracts.bnbGetDayHistory(), // 19
          contracts.bnbGetWeekHistory(), // 20
          contracts.bnbGetMonthHistory(), // 21
          contracts.bnbGet3MonthHistory(), // 22
          contracts.bnbGetYearHistory(), // 23
          contracts.bnbGetAllHistory(), // 24

          contracts.bnbGetPortfolio(), // 25
        ]).then((res) => {
          setNumShareholders(res[0]);

          const resPortfolios = res[1];
          setPortfolios(resPortfolios);

          const shareSupplies = resPortfolios.map((p) => p.totalShares);
          setShareSupply(shareSupplies);

          const aums = resPortfolios.map((p) => p.aum);
          setAum(aums);

          const inavs = resPortfolios.map((p) => {
            return p.aum / p.totalShares;
          });
          setINav(inavs);

          setOpsCache(res[2]);
          setMinDeposit(res[3]);
          setMinShares(res[4]);
          setMarketFeed(res[5]);

          const synAllData = parseChartData(res[15], aums, shareSupplies, inavs);
          const synNavData = synAllData.map((d) => d[1]);
          const synUpMetrics: MetricsType = computeMetrics(synNavData[VAULT_IDX.Y_PUP]);
          const synUp: OverviewData = {
            imgSrc: upLogo,
            name: 'UP',
            address: Y_PUP_PARAMS.vaultAddress,
            aum: aums[VAULT_IDX.Y_PUP].toLocaleString(undefined, {
              maximumFractionDigits: 0,
            }),
            inav: (100 * inavs[VAULT_IDX.Y_PUP]).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }),
            strategy: 'Long',
            type: 'Perps',
            chain: opLogo,
            d7: changePct(synAllData[VAULT_IDX.Y_PUP][2]),
            riskObj: [3, RISK_COLORS[3 - 1], theme],
            cagr: pctFormatter(synUpMetrics.currentCAGR),
            sharpe: synUpMetrics.sharpeRatio,
            calmar: synUpMetrics.currentCalmar,
            rating: '..',
            age: '135',
            drawdown: pctFormatter(synUpMetrics.maxDD),
            stake: '186176',
            rb: '0.64',
          };

          const synDownMetrics: MetricsType = computeMetrics(synNavData[VAULT_IDX.Y_PDOWN]);
          const synDown: OverviewData = {
            imgSrc: downLogo,
            name: 'DOWN',
            address: Y_PDOWN_PARAMS.vaultAddress,
            aum: aums[VAULT_IDX.Y_PDOWN].toLocaleString(undefined, {
              maximumFractionDigits: 0,
            }),
            inav: (100 * inavs[VAULT_IDX.Y_PDOWN]).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }),
            strategy: 'Short',
            type: 'Perps',
            chain: opLogo,
            d7: changePct(synAllData[VAULT_IDX.Y_PDOWN][2]),
            riskObj: [5, RISK_COLORS[5 - 1], theme],
            cagr: pctFormatter(synDownMetrics.currentCAGR),
            sharpe: synDownMetrics.sharpeRatio,
            calmar: synDownMetrics.currentCalmar,
            rating: '..',
            age: '135',
            drawdown: pctFormatter(synDownMetrics.maxDD),
            stake: '189672',
            rb: '2.33',
          };

          const synNeutralMetrics: MetricsType = computeMetrics(synNavData[VAULT_IDX.Y_NEUTRAL]);
          const synNeutral: OverviewData = {
            imgSrc: neutralLogo,
            name: 'NEUTRAL',
            address: Y_NEUTRAL_PARAMS.vaultAddress,
            aum: aums[VAULT_IDX.Y_NEUTRAL].toLocaleString(undefined, {
              maximumFractionDigits: 0,
            }),
            inav: (100 * inavs[VAULT_IDX.Y_NEUTRAL]).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }),
            strategy: 'Market-Neutral',
            type: 'Perps',
            chain: opLogo,
            d7: changePct(synAllData[VAULT_IDX.Y_NEUTRAL][2]),
            riskObj: [1, RISK_COLORS[1 - 1], theme],
            cagr: pctFormatter(synNeutralMetrics.currentCAGR),
            sharpe: synNeutralMetrics.sharpeRatio,
            calmar: synNeutralMetrics.currentCalmar,
            rating: '..',
            age: '135',
            drawdown: pctFormatter(synNeutralMetrics.maxDD),
            stake: '375962',
            rb: '16.56',
          };

          setDayHistory(parseChartData(res[6], aums, shareSupplies, inavs));
          setWeekHistory(parseChartData(res[7], aums, shareSupplies, inavs));
          setMonthHistory(parseChartData(res[8], aums, shareSupplies, inavs));

          setMonth3History(parseChartData(res[13], aums, shareSupplies, inavs));
          setYearHistory(parseChartData(res[14], aums, shareSupplies, inavs));
          setAllHistory(parseChartData(res[15], aums, shareSupplies, inavs));

          setOrderFees(res[9]);

          setUserDepositsUnderProcessing(res[10]);
          setUserRedeemsUnderProcessing(res[11]);

          setProcessingStatus(res[12]);

          setSpotAllocations(res[16]);
          setSpotNavs(res[17]);
          setSpotShareSupply(res[18]);

          const spotAums = res[16].map((p) => p.aum);

          const bnbAllData = bnbParseChartData(res[24], spotAums, res[18]);
          const bnbNavData = bnbAllData.map((d) => d[1]);
          const bnbSupMetrics: MetricsType = computeMetrics(bnbNavData[0]);
          const bnbSup: OverviewData = {
            imgSrc: supLogo,
            name: 'BULL',
            address: BNB_VAULT_ADDRESSES[0],
            aum: spotAums[0].toLocaleString(undefined, {
              maximumFractionDigits: 0,
            }),
            inav: (100 * res[17][0]).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }),
            strategy: 'Long',
            type: 'Spot',
            chain: bnbLogo,
            d7: changePct(bnbAllData[0][2]),
            riskObj: [3, RISK_COLORS[3 - 1], theme],
            cagr: pctFormatter(bnbSupMetrics.currentCAGR),
            sharpe: bnbSupMetrics.sharpeRatio,
            calmar: bnbSupMetrics.currentCalmar,
            rating: '..',
            age: '135',
            drawdown: pctFormatter(bnbSupMetrics.maxDD),
            stake: '186176',
            rb: '0.64',
          };

          const bnbIbtcMetrics: MetricsType = computeMetrics(bnbNavData[1]);
          const bnbIbtc: OverviewData = {
            imgSrc: ibtcLogo,
            name: 'iBTC',
            address: BNB_VAULT_ADDRESSES[1],
            aum: spotAums[1].toLocaleString(undefined, {
              maximumFractionDigits: 0,
            }),
            inav: (100 * res[17][1]).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }),
            strategy: 'BTC Long',
            type: 'Spot',
            chain: bnbLogo,
            d7: changePct(bnbAllData[1][2]),
            riskObj: [3, RISK_COLORS[3 - 1], theme],
            cagr: pctFormatter(bnbIbtcMetrics.currentCAGR),
            sharpe: bnbIbtcMetrics.sharpeRatio,
            calmar: bnbIbtcMetrics.currentCalmar,
            rating: '..',
            age: '135',
            drawdown: pctFormatter(bnbIbtcMetrics.maxDD),
            stake: '2419',
            rb: '12.23',
          };

          const bnbIethMetrics: MetricsType = computeMetrics(bnbNavData[2]);
          const bnbIeth: OverviewData = {
            imgSrc: iethLogo,
            name: 'iETH',
            address: BNB_VAULT_ADDRESSES[2],
            aum: spotAums[2].toLocaleString(undefined, {
              maximumFractionDigits: 0,
            }),
            inav: (100 * res[17][2]).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }),
            strategy: 'ETH Long',
            type: 'Spot',
            chain: bnbLogo,
            d7: changePct(bnbAllData[2][2]),
            riskObj: [3, RISK_COLORS[3 - 1], theme],
            cagr: pctFormatter(bnbIethMetrics.currentCAGR),
            sharpe: bnbIethMetrics.sharpeRatio,
            calmar: bnbIethMetrics.currentCalmar,
            rating: '..',
            age: '135',
            drawdown: pctFormatter(bnbIethMetrics.maxDD),
            stake: '1356',
            rb: '3.76',
          };

          const overallData = [
            // bnbSup,
            synUp,
            synDown,
            synNeutral,
            // bnbIbtc, bnbIeth
          ];
          setOverviewData(overallData);

          bnb_setDayHistory(bnbParseChartData(res[19], spotAums, res[18]));
          bnb_setWeekHistory(bnbParseChartData(res[20], spotAums, res[18]));
          bnb_setMonthHistory(bnbParseChartData(res[21], spotAums, res[18]));
          bnb_setMonth3History(bnbParseChartData(res[22], spotAums, res[18]));
          bnb_setYearHistory(bnbParseChartData(res[23], spotAums, res[18]));
          bnb_setAllHistory(bnbParseChartData(res[24], spotAums, res[18]));

          setBnbPortfolios(res[25]);
        });

        if (await contracts.isConnected()) {
          await contracts.refresh();
          // const [rr, pr] = await contracts.getRecords();
          // handleRecords(rr, pr);
          const userVaultBalances = await contracts.getShareBals();
          setShareBals(userVaultBalances);

          const userUsdc = await contracts.getSusdBal();
          setUsdcBal(userUsdc);

          const totalPortfolioValue =
            Number(userVaultBalances[contracts.VAULT_IDX.Y_UPDOWN]) * Number(iNav[contracts.VAULT_IDX.Y_UPDOWN]) +
            Number(userVaultBalances[contracts.VAULT_IDX.Y_NEUTRAL]) * Number(iNav[contracts.VAULT_IDX.Y_NEUTRAL]) +
            Number(userVaultBalances[contracts.VAULT_IDX.Y_PUP]) * Number(iNav[contracts.VAULT_IDX.Y_PUP]) +
            Number(userVaultBalances[contracts.VAULT_IDX.Y_PDOWN]) * Number(iNav[contracts.VAULT_IDX.Y_PDOWN]);
          setTotalVal(totalPortfolioValue.toFixed(2));

          const pendingDeposits = await getPendingDeposits();
          const pendingRedeems = await getPendingRedeems();

          setUserPendingDeposits(pendingDeposits);
          setUserPendingRedeems(pendingRedeems);

          const completedRequests = await getCompletedRequests();
          setUserCompletedRequests(completedRequests);
        } else {
          handleRecords([], []);
          setShareBals(['~', '~', '~', '~']);
          setTotalVal('~');
        }
      }, 5000);

      setConnected(await contracts.isConnected());
      ethereumClient.watchAccount(async () => {
        setWalletLoading(true);
        await contracts.refresh();
        // const [rr, pr] = await contracts.getRecords();
        // handleRecords(rr, pr);
        if (await contracts.isConnected()) {
          const userVaultBalances = await contracts.getShareBals();
          setShareBals(userVaultBalances);

          const userUsdc = await contracts.getSusdBal();
          setUsdcBal(userUsdc);

          const totalPortfolioValue =
            Number(userVaultBalances[contracts.VAULT_IDX.Y_UPDOWN]) * Number(iNav[contracts.VAULT_IDX.Y_UPDOWN]) +
            Number(userVaultBalances[contracts.VAULT_IDX.Y_NEUTRAL]) * Number(iNav[contracts.VAULT_IDX.Y_NEUTRAL]) +
            Number(userVaultBalances[contracts.VAULT_IDX.Y_PUP]) * Number(iNav[contracts.VAULT_IDX.Y_PUP]) +
            Number(userVaultBalances[contracts.VAULT_IDX.Y_PDOWN]) * Number(iNav[contracts.VAULT_IDX.Y_PDOWN]);
          setTotalVal(totalPortfolioValue.toFixed(2));

          const pendingDeposits = await getPendingDeposits();
          const pendingRedeems = await getPendingRedeems();

          setUserPendingDeposits(pendingDeposits);
          setUserPendingRedeems(pendingRedeems);

          const completedRequests = await getCompletedRequests();
          setUserCompletedRequests(completedRequests);
        }

        // await Promise.all([
        //   // contracts.getRecords(), // todo: fit the other promises in here
        // ]).then((res) => {
        //   const [rr, pr] = res[0];
        //   handleRecords(rr, pr);
        // });

        setWalletLoading(false);
      });
    })();
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, []);

  usePWA();

  useAutoNightMode();

  useThemeWatcher();

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <meta name="theme-color" content={themeObject[theme].primary} />
        <GlobalStyle />
        <HelmetProvider>
          <ConfigProvider locale={language === 'en' ? enUS : deDe}>
            <AppRouter
              aums={aum}
              dayHistories={dayHistory}
              weekHistories={weekHistory}
              monthHistories={monthHistory}
              month3Histories={month3History}
              yearHistories={yearHistory}
              allHistories={allHistory}
              bnb_dayHistories={bnb_dayHistory}
              bnb_weekHistories={bnb_weekHistory}
              bnb_monthHistories={bnb_monthHistory}
              bnb_month3Histories={bnb_month3History}
              bnb_yearHistories={bnb_yearHistory}
              bnb_allHistories={bnb_allHistory}
              spinning={spinning}
              connected={connected}
              vaultSelected={vaultSelected}
              inavList={iNav}
              usdcBal={usdcBal}
              shareBals={shareBals}
              pendingDepositState={pendingDepositState}
              pendingWithdrawState={pendingWithdrawState}
              walletLoading={walletLoading}
              processedRecords={processedRecords}
              requestRecords={requestRecords}
              selectUpdown={() => {
                setVaultSelected(VAULT_IDX.Y_UPDOWN);
              }}
              selectNeutral={() => {
                setVaultSelected(VAULT_IDX.Y_NEUTRAL);
              }}
              selectUp={() => {
                setVaultSelected(VAULT_IDX.Y_PUP);
              }}
              selectDown={() => {
                setVaultSelected(VAULT_IDX.Y_PDOWN);
              }}
              selectSUp={() => {
                setSpotSelected(SPOT_IDX.S_UP);
              }}
              selectIBtc={() => {
                setSpotSelected(SPOT_IDX.I_BTC);
              }}
              selectIEth={() => {
                setSpotSelected(SPOT_IDX.I_ETH);
              }}
              spotSelected={spotSelected}
              info={info}
              shareSupply={shareSupply}
              numShareholders={numShareholders}
              minUsdc={minDeposit}
              minShares={minShares}
              maxUsdc={maxUsdc}
              marketFeed={marketFeed}
              portfolios={portfolios}
              bnbPortfolios={bnbPortfolios}
              pendingDeposit={userPendingDeposits}
              pendingRedeem={userPendingRedeems}
              completedRequests={userCompletedRequests}
              operationsCache={opsCache}
              onboardingFees={onboardingFees}
              offboardingFees={offboardingFees}
              orderFees={orderFees}
              userDepositsUnderProcessing={userDepositsUnderProcessing}
              userRedeemsUnderProcessing={userRedeemsUnderProcessing}
              processingStatus={processingStatus}
              tripleSelected={tripleSelected}
              selectSwap={() => {
                setTripleSelected(0);
              }}
              selectMint={() => {
                setTripleSelected(1);
              }}
              selectRedeem={() => {
                setTripleSelected(2);
              }}
              spotAllocations={spotAllocations}
              spotNavs={spotNavs}
              spotShareSupply={spotShareSupply}
              overviewData={overviewData}
            />
          </ConfigProvider>
        </HelmetProvider>
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        defaultChain={networkChains[0]}
        explorerRecommendedWalletIds={[
          'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // metamask
          // '163d2cf19babf05eb8962e9748f9ebe613ed52ebf9c8107c9a0f104bfcf161b3', // brave
          // '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // trustwallet
          // 'ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef', // imtoken
        ]}
        explorerExcludedWalletIds="ALL"
      />
    </>
  );
};

export default App;
