import React from 'react';
import {
  CompassOutlined,
  DashboardOutlined,
  FormOutlined,
  HomeOutlined,
  LayoutOutlined,
  LineChartOutlined,
  TableOutlined,
  UserOutlined,
  BlockOutlined,
  SwapOutlined,
  ClusterOutlined,
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Overview',
    key: 'overview',
    // TODO use path variable
    url: '/',
    icon: <HomeOutlined />,
  },
  {
    title: 'common.vaults',
    key: 'vaults',
    icon: <LineChartOutlined />,
    children: [
      {
        title: 'Perpetuals',
        key: 'perps-vaults',
        url: '/vaults',
      },
      // {
      //   title: 'Spot',
      //   key: 'spot-vaults',
      //   url: '/spot',
      // },
    ],
  },
  {
    title: 'Account',
    key: 'account',
    icon: <UserOutlined />,
    children: [
      {
        title: 'Portfolio',
        key: 'portfolio',
        url: '/account/portfolio',
      },
      {
        title: 'Transactions',
        key: 'transactions',
        url: '/account/transactions',
      },
    ],
  },
  // {
  //   title: 'Swap',
  //   key: 'swap',
  //   url: '/swap',
  //   icon: <SwapOutlined />,
  // },
  {
    title: 'AI Competition',
    key: 'competition',
    icon: <ClusterOutlined />,
    children: [
      {
        title: 'About',
        key: 'about',
        url: '/competition/about',
      },
      {
        title: 'Leaderboard',
        key: 'leaderboard',
        url: '/competition/leaderboard',
      },
      {
        title: 'Airdrop',
        key: 'airdrop',
        url: '/competition/airdrop',
      },
    ],
  },
];
