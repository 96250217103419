import React, { useMemo, useState } from 'react';
import { Overlay } from '../../../../common/Overlay/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './MainSider.styles';
import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';
import { References } from '@app/components/common/References/References';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { addTokens } from '@app/lib/contracts';
import { toast, ToastContainer } from 'react-toastify';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
  connected: boolean;
  // nextWindow: number;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  const { isDesktop, mobileOnly, tabletOnly } = useResponsive();

  const [addingTokens, setAddingTokens] = useState(false);
  const [gettingUsdc, setGettingUsdc] = useState(false);
  const [receivedUsdc, setReceivedUsdc] = useState(false);

  const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);

  const toggleSider = () => setCollapsed(!isCollapsed);

  return (
    <>
      <ToastContainer />
      <S.Sider
        trigger={null}
        collapsed={!isDesktop && isCollapsed}
        collapsedWidth={tabletOnly ? 80 : 0}
        collapsible={isCollapsible}
        width={190}
        {...props}
      >
        <SiderLogo isSiderCollapsed={isCollapsed} toggleSider={toggleSider} />
        <S.SiderContent>
          <SiderMenu setCollapsed={setCollapsed} />
          {props.connected ? (
            <div>
              {/*<BaseButton*/}
              {/*  size={'small'}*/}
              {/*  style={{ margin: '1rem' }}*/}
              {/*  loading={addingTokens}*/}
              {/*  onClick={() => {*/}
              {/*    setAddingTokens(true);*/}
              {/*    addTokens().finally(() => setAddingTokens(false));*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Import Tokens*/}
              {/*</BaseButton>*/}
              {/*<BaseButton*/}
              {/*  size={'small'}*/}
              {/*  style={{ margin: '1rem' }}*/}
              {/*  loading={gettingUsdc}*/}
              {/*  disabled={props.nextWindow > Date.now() / 1000 || receivedUsdc}*/}
              {/*  onClick={async () => {*/}
              {/*    setGettingUsdc(true);*/}

              {/*    faucetDrip()*/}
              {/*      .then(({ transaction }) => {*/}
              {/*        toast*/}
              {/*          .promise(transaction, {*/}
              {/*            pending: 'Processing test USDC transfer..',*/}
              {/*            success:*/}
              {/*              'Received 5000 test USDC. You can request more in 24 hours. Thank you for trying out YIEDL Vaults!',*/}
              {/*            error: 'You can only request test USDC once every 24 hours.',*/}
              {/*          })*/}
              {/*          .then(() => {*/}
              {/*            setReceivedUsdc(true);*/}
              {/*          });*/}
              {/*      })*/}
              {/*      .catch((err) => {*/}
              {/*        // toast.info('Cancelled by user.', {*/}
              {/*        //   autoClose: 1000,*/}
              {/*        //   closeButton: true,*/}
              {/*        //   hideProgressBar: true,*/}
              {/*        // });*/}
              {/*      })*/}
              {/*      .finally(() => {*/}
              {/*        setGettingUsdc(false);*/}
              {/*      });*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Get test USDC*/}
              {/*</BaseButton>*/}
            </div>
          ) : null}
          <References isCollapsed={!isDesktop && isCollapsed} />
        </S.SiderContent>
      </S.Sider>
      {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
