import React, { useState, useEffect } from 'react';
// import { Modal, Button } from 'antd';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Link } from 'react-router-dom';

const disclaimerLabel = 'disclaimer0.1';
const disclaimerHtml = (
  <div
    style={{
      maxHeight: '50vh',
      overflowY: 'auto',
    }}
  >
    <p>By clicking “I Agree” below, you acknowledge and accept the terms of this Agreement.</p>
    <p>By participating in Yiedl, a blockchain-based decentralized finance project, you understand that:</p>
    <ul>
      <li>YIEDL IS A SUITE OF EXPERIMENTAL BLOCKCHAIN-ORIENTED FUNCTIONALITIES.</li>
      <li>
        The smart contracts of the Yiedl Protocol have undergone an extensive audit conducted by ImmuneBytes, a
        pioneering security blockchain company. While ImmuneBytes{`'`} audit enhances the security level of the Yiedl
        Protocol, it does not provide a guarantee of security or correctness for the audited smart contract.
      </li>
      <li>
        Yiedl is utilized at your own risk, offered {`"as is,"`} and lacks security guarantees. The protocol comprises
        immutable code accessible through various user interfaces.
      </li>
      <li>
        The Yiedl protocol operates without a central entity; decisions are made by a dispersed group of participants
        who collectively govern and maintain the protocol.
      </li>
      <li>Yiedl DAO does not independently provide, operate, administer, or control any trading interfaces.</li>
      <li>Rules and parameters associated with the Yiedl protocol may change at any time.</li>
      <li>
        You can participate in the governance process by staking YIEDL tokens in accordance with the rules and
        parameters discussed by the community, and/or joining the Yiedl Discord and contributing to the conversation.
        The rules and parameters associated with the Yiedl protocol and Yiedl DAO governance are subject to change at
        any time.
      </li>
      <li>
        Your Yiedl use is subject to acceptance of the Yiedl Terms of Use available <Link to={'/termsofuse'}>here</Link>
        .
      </li>
      <li>
        This agreement does not imply the formation of a partnership; Yiedl is a decentralized protocol provided{' '}
        {`"as is."`}
      </li>
      <li>
        You release all current and future claims against Yiedl related to protocol use, YIEDL tokens, Yiedl DAO
        governance, or any other aspect of the protocol.
      </li>
      <li>
        You agree to indemnify and absolve Yiedl DAO and its affiliates from costs arising from your Yiedl protocol use.
      </li>
      <li>
        Applicable laws governing your Yiedl use may vary by jurisdiction, and seeking legal counsel is recommended for
        clarity.
      </li>
      <li>
        By accepting this contract, you confirm non-affiliation with countries such as Burma (Myanmar), Cuba, Iran,
        Sudan, Syria, the Western Balkans, Belarus, Côte d’Ivoire, Democratic Republic of the Congo, Iraq, Lebanon,
        Liberia, Libya, North Korea, China, Singapore, United States of America, Russia, certain sanctioned areas of
        Ukraine, Somalia, Venezuela, Yemen, or Zimbabwe (collectively, “Prohibited Jurisdictions”), and any jurisdiction
        listed as a Specially Designated National by the United States Office of Foreign Asset Control (“OFAC”). Use of
        a virtual private network to circumvent the restrictions set forth herein is prohibited. Yiedl has implemented a
        wallet screening tool provided by Chainalysis to block wallets from blacklisted countries.
      </li>
    </ul>
  </div>
);

const DisclaimerPopup: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already agreed to the disclaimer
    const hasAgreed = localStorage.getItem(disclaimerLabel);
    if (hasAgreed !== 'true') {
      setIsModalVisible(true);
    }
  }, []);

  const handleAgree = () => {
    // Set flag in local storage and hide the modal
    localStorage.setItem(disclaimerLabel, 'true');
    setIsModalVisible(false);
  };

  return (
    <BaseModal
      title="YIEDL Terms of Service"
      visible={isModalVisible}
      onOk={handleAgree}
      closable={false}
      footer={[
        <BaseButton key="agree" type="primary" onClick={handleAgree}>
          I Agree
        </BaseButton>,
      ]}
    >
      {disclaimerHtml}
    </BaseModal>
  );
};

export default DisclaimerPopup;
