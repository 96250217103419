import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { MapCard } from '@app/components/medical-dashboard/mapCard/MapCard';
import { ScreeningsCard } from '@app/components/medical-dashboard/screeningsCard/ScreeningsCard/ScreeningsCard';
import { ActivityCard } from '@app/components/medical-dashboard/activityCard/ActivityCard';
import { TreatmentCard } from '@app/components/medical-dashboard/treatmentCard/TreatmentCard';
import { CovidCard } from '@app/components/medical-dashboard/covidCard/CovidCard';
import { HealthCard } from '@app/components/medical-dashboard/HealthCard/HealthCard';
import { FavoritesDoctorsCard } from '@app/components/medical-dashboard/favoriteDoctors/FavoriteDoctorsCard/FavoritesDoctorsCard';
import { MintStatusCard } from '@app/components/vaults-dashboard/mintStatusCard/MintStatusCard';
import { StatisticsCards } from '@app/components/vaults-dashboard/statisticsCards/StatisticsCards';
import { BloodScreeningCard } from '@app/components/medical-dashboard/bloodScreeningCard/BloodScreeningCard/BloodScreeningCard';
import { NewsCard } from '@app/components/medical-dashboard/NewsCard/NewsCard';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BigChart } from '../../components/vaults-dashboard/bigChart/BigChart';
import { InfoCard } from '../../components/vaults-dashboard/infoCard/InfoCard';
import { StatisticsCard } from '@app/components/vaults-dashboard/statisticsCards/statisticsCard/StatisticsCard/StatisticsCard';
import { StatisticColor } from '@app/constants/config/statistics';
import { AumCard } from '@app/components/vaults-dashboard/aumCard/AumCard';
import { IAppRouterProps } from '@app/components/router/AppRouter';
// import { MintCard } from '@app/components/vaults-dashboard/mintCard/MintCard/MintCard';
// import { RedeemCard } from '@app/components/vaults-dashboard/redeemCard/RedeemCard/RedeemCard';
import { TripleCard } from '@app/components/vaults-dashboard/tripleCard/TripleCard/TripleCard';

import {
  VAULT_IDX,
  VAULT_TYPE,
  Y_NEUTRAL_PARAMS,
  Y_PDOWN_PARAMS,
  Y_PUP_PARAMS,
  Y_UPDOWN_PARAMS,
} from '@app/lib/contracts';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import HoldingsSummaryCard from '@app/components/vaults-dashboard/holdingsCard/HoldingsSummaryCard';
import { DashboardCard } from '@app/components/medical-dashboard/DashboardCard/DashboardCard';
import HoldingsTableCard from '@app/components/vaults-dashboard/holdingsCard/HoldingsTableCard';
import { GenericCard } from '@app/components/vaults-dashboard/genericCard/GenericCard';
import { ShareCard } from '@app/components/vaults-dashboard/shareCard/ShareCard';
import { ExploreCard } from '@app/components/vaults-dashboard/shareCard/ExploreCard';
import { EstCard } from '@app/components/vaults-dashboard/estCard/EstCard';
// import { ZKExplorerCard } from '@app/components/vaults-dashboard/shareCard/ZKExplorerCard';

const RISK_HEADER = 'vaults.risk';
const RISK_COLORS = ['success', 'primary', 'border', 'warning', 'error'];
const NAV_MULTIPLER = 100;
const SHARE_SYMBOLS = ['Y-UPDOWN', 'Y-NEUTRAL', 'Y-UP', 'Y-DOWN'];
const tooltipEstMinUsdc = (
  <div>
    <p>Estimated Min USD is an estimate of the minimum amount of USD</p>
    <p>that you need to deposit for share-minting to be processed immediately.</p>
    <p>If you deposit less than this amount, your share-minting will be delayed.</p>
  </div>
);
// const tooltipEstMaxUsdc = (
//   <div>
//     <p>Estimated Max sUSD is an estimate of the maximum amount of sUSD</p>
//     <p>that you can deposit for share-minting to be processed immediately.</p>
//     <p>If you deposit more than this amount, your share-minting will be delayed.</p>
//   </div>
// );
const tooltipOrderFees = (
  <div style={{ fontSize: 'x-small', fontStyle: 'italic' }}>
    <p>Estimated amount of fees charged by the Synthetix protocol for transactions</p>
    <p>required to process this request.</p>
  </div>
);

// const tooltipOnboardingFees = (
//     <div>
//       <p>Estimated amount of fees charged by the Synthetix protocol for transactions</p>
//       <p>required to process this request.</p>
//     </div>
// );
//
// const tooltipOrderFees = (
//     <div>
//       <p>Estimated amount of fees charged by the Synthetix protocol for transactions</p>
//       <p>required to process this request.</p>
//     </div>
// );

interface RiskAttributes {
  riskValue: number;
}
const statistics: RiskAttributes[] = [{ riskValue: 3 }, { riskValue: 1 }, { riskValue: 5 }, { riskValue: 5 }];
const vault_types = [VAULT_TYPE.Y_UPDOWN, VAULT_TYPE.Y_NEUTRAL, VAULT_TYPE.Y_PUP, VAULT_TYPE.Y_PDOWN];

function quantize(value: string | number, decimals: number): string {
  return Number(value).toLocaleString(undefined, { maximumFractionDigits: decimals });
}

interface InfoCardProps {
  title: string;
  subtitle: string;
  description: string;
  linkUrl?: string;
}

const infos: Array<InfoCardProps> = [
  {
    title: 'UPDOWN',
    subtitle: 'Weekly Rotation, 37 Assets',
    description:
      'Unbalanced asset allocation where predictions are provided by crowd-sourced machine-learning forecasts run by the Yiedl Competition. The asset allocation is done by buying the positive predictions and short selling the negative predictions. Each week, the total allocations of the long and short side depend on the stake weighted meta-model (could be long bias, short bias, or market neutral).',
    linkUrl: 'https://docs.yiedl.ai/rci-competition/scoring-and-reward-policy#vault-updown',
  },
  {
    title: 'NEUTRAL',
    subtitle: 'Weekly Rotation, 37 Assets',
    description:
      'Market neutral asset allocation (50% long and 50% short) where predictions are provided by crowd-sourced machine-learning forecasts run by the Yiedl Competition. The asset allocation is done by buying the top half and short selling the bottom half of the ranked crypto-assets (from strongest to weakest) predicted by the stake weighted meta-model.',
    linkUrl: 'https://docs.yiedl.ai/rci-competition/scoring-and-reward-policy#vault-neutral',
  },
  {
    title: 'UP',
    subtitle: 'Weekly Rotation, Variable Assets',
    description:
      '100% LONG only allocation. The predictions are provided by crowd-sourced machine-learning forecasts run by the Yiedl Competition. The asset allocation is done by buying the top half of the ranked crypto-assets (only the strongest ) predicted by the stake weighted meta-model.',
  },
  {
    title: 'DOWN',
    subtitle: 'Weekly Rotation, Variable Assets',
    description:
      '100% SHORT only allocation. The predictions are provided by crowd-sourced machine-learning forecasts run by the Yiedl Competition. The asset allocation is done by SELLING the BOTTOM half of the ranked crypto-assets (only the weakest ) predicted by the stake weighted meta-model.',
  },
];

const vaultParams = [Y_UPDOWN_PARAMS, Y_NEUTRAL_PARAMS, Y_PUP_PARAMS, Y_PDOWN_PARAMS];

const VaultsDashboardPage: React.FC<IAppRouterProps> = (props) => {
  const { isTablet, isDesktop } = useResponsive();

  const { t } = useTranslation();

  const desktopLayout = (
    <BaseRow>
      <PageTitle>{t('Vaults Dashboard')}</PageTitle>
      <BaseCol span={24} style={{ marginBottom: '2vh', marginLeft: '4vh' }}>
        <BaseRadio.Group defaultValue={props.vaultSelected} buttonStyle="solid" size="small">
          <BaseRadio.Button onClick={props.selectUp} value={VAULT_IDX.Y_PUP}>
            UP
          </BaseRadio.Button>
          <BaseRadio.Button onClick={props.selectDown} value={VAULT_IDX.Y_PDOWN}>
            DOWN
          </BaseRadio.Button>
          <BaseRadio.Button onClick={props.selectNeutral} value={VAULT_IDX.Y_NEUTRAL}>
            NEUTRAL
          </BaseRadio.Button>
        </BaseRadio.Group>
      </BaseCol>

      <S.LeftSideCol xl={13} xxl={13}>
        <BaseRow gutter={[30, 30]}>
          <BaseCol span={24}>
            <InfoCard
              title={infos[props.vaultSelected].title}
              subtitle={infos[props.vaultSelected].subtitle}
              description={infos[props.vaultSelected].description}
              linkUrl={infos[props.vaultSelected].linkUrl}
              selectedVault={props.vaultSelected}
            />
          </BaseCol>

          <BaseCol span={24}>
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={12} md={8} order={(isTablet && 1) || 0}>
                <AumCard
                  title={'iNav'}
                  value={quantize(props.inavList[props.vaultSelected] * NAV_MULTIPLER, 2)}
                  spinning={props.spinning}
                  unit={''}
                />
              </BaseCol>
              <BaseCol xs={12} md={8} order={(isTablet && 2) || 0}>
                <AumCard
                  title={'TVL'}
                  value={props.aums[props.vaultSelected].toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}
                  spinning={props.spinning}
                  unit={'sUSD'}
                />
              </BaseCol>
              <BaseCol xs={12} md={8} order={(isTablet && 3) || 0}>
                <StatisticsCard
                  name={RISK_HEADER}
                  value={statistics[props.vaultSelected].riskValue}
                  color={`${RISK_COLORS[statistics[props.vaultSelected].riskValue - 1]}` as StatisticColor}
                  unit={'/ 5'}
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol span={24}>
            <BaseRow gutter={[30, 30]}>
              <BaseCol xs={12} md={8} order={(isTablet && 1) || 0}>
                <ShareCard
                  title={'Share Supply'}
                  value={Number(props.shareSupply[props.vaultSelected]).toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}
                  spinning={props.spinning}
                  unit={''}
                />
              </BaseCol>
              <BaseCol xs={12} md={8} order={(isTablet && 2) || 0}>
                <ShareCard
                  title={'Share Holders'}
                  value={props.numShareholders[props.vaultSelected]}
                  spinning={props.spinning}
                  unit={''}
                />
              </BaseCol>
              <BaseCol xs={12} md={8} order={(isTablet && 3) || 0}>
                <ExploreCard
                  title={'Explore Token'}
                  value={'→'}
                  spinning={props.spinning}
                  address={vaultParams[props.vaultSelected].vaultAddress}
                />
              </BaseCol>
            </BaseRow>
          </BaseCol>

          <BaseCol id="big-chart" span={24}>
            <BigChart
              dayHistories={props.dayHistories[props.vaultSelected]}
              weekHistories={props.weekHistories[props.vaultSelected]}
              monthHistories={props.monthHistories[props.vaultSelected]}
              month3Histories={props.month3Histories[props.vaultSelected]}
              yearHistories={props.yearHistories[props.vaultSelected]}
              allHistories={props.allHistories[props.vaultSelected]}
              spinning={props.spinning}
            />
          </BaseCol>
          <BaseCol span={24}>
            <HoldingsSummaryCard
              portfolios={props.portfolios}
              selector={props.vaultSelected}
              loading={props.spinning}
              processingStatus={props.processingStatus}
            />
          </BaseCol>
          <BaseCol span={24} order={25}>
            <HoldingsTableCard
              portfolios={props.portfolios}
              selector={props.vaultSelected}
              loading={props.spinning}
              processingStatus={props.processingStatus}
            />
          </BaseCol>
          {/*<BaseCol span={24} order={50}>*/}
          {/*  <ZKExplorerCard*/}
          {/*    title={'View Executed Orders'}*/}
          {/*    url={`https://dydx.l2beat.com/users/${starkAddresses[props.vaultSelected]}`}*/}
          {/*    spinning={false}*/}
          {/*    value={'→'}*/}
          {/*  />*/}
          {/*</BaseCol>*/}
        </BaseRow>

        {/*<References />*/}
      </S.LeftSideCol>

      <S.RightSideCol xl={11} xxl={11} style={{ marginTop: '0.3vh' }}>
        <>
          <TripleCard
            mintCardProps={{
              usdcBal: props.usdcBal,
              spinning: props.walletLoading,
              vaultType: vault_types[props.vaultSelected],
              connected: props.connected,
              minUsdc: props.minUsdc[props.vaultSelected],
              maxUsdc: props.maxUsdc[props.vaultSelected],
              pending: props.pendingDeposit[props.vaultSelected],
              orderFees: props.orderFees[props.vaultSelected],
              onboardingFees: props.onboardingFees[props.vaultSelected],
              tooltipContent: tooltipOrderFees,
              shareSymbol: SHARE_SYMBOLS[props.vaultSelected],
            }}
            redeemCardProps={{
              shareBal: props.shareBals[props.vaultSelected],
              spinning: props.walletLoading,
              vaultType: vault_types[props.vaultSelected],
              connected: props.connected,
              shareSymbol: SHARE_SYMBOLS[props.vaultSelected],
              inav: props.inavList[props.vaultSelected],
              minVal: props.minShares[props.vaultSelected],
              pending: props.pendingRedeem[props.vaultSelected],
              orderFees: props.orderFees[props.vaultSelected],
              offboardingFees: props.offboardingFees[props.vaultSelected],
              tooltipContent: tooltipOrderFees,
              minShares: props.minShares[props.vaultSelected],
            }}
            tripleSelected={props.tripleSelected}
            selectSwap={props.selectSwap}
            selectMint={props.selectMint}
            selectRedeem={props.selectRedeem}
          />

          {/*<MintCard*/}
          {/*  usdcBal={props.usdcBal}*/}
          {/*  spinning={props.walletLoading}*/}
          {/*  vaultType={vault_types[props.vaultSelected]}*/}
          {/*  connected={props.connected}*/}
          {/*  minUsdc={props.minUsdc[props.vaultSelected]}*/}
          {/*  maxUsdc={props.maxUsdc[props.vaultSelected]}*/}
          {/*  pending={props.pendingDeposit[props.vaultSelected]}*/}
          {/*  orderFees={props.orderFees[props.vaultSelected]}*/}
          {/*  onboardingFees={props.onboardingFees[props.vaultSelected]}*/}
          {/*  tooltipContent={tooltipOrderFees}*/}
          {/*/>*/}

          {/*<BaseRow gutter={[10, 10]} justify="space-between" style={{ marginTop: '1vh' }}>*/}
          {/*  <BaseCol span={24}>*/}
          {/*    <EstCard*/}
          {/*      title1={'Est Order Fees'}*/}
          {/*      value1={props.orderFees[props.vaultSelected]}*/}
          {/*      title2={'Onboarding Fees'}*/}
          {/*      value2={props.onboardingFees[props.vaultSelected]}*/}
          {/*      spinning={props.spinning}*/}
          {/*      tooltipContent={tooltipEstMinUsdc}*/}
          {/*    />*/}
          {/*  </BaseCol>*/}
          {/*</BaseRow>*/}

          {/*<S.Space />*/}
          {/*<RedeemCard*/}
          {/*  shareBal={props.shareBals[props.vaultSelected]}*/}
          {/*  spinning={props.walletLoading}*/}
          {/*  vaultType={vault_types[props.vaultSelected]}*/}
          {/*  connected={props.connected}*/}
          {/*  shareSymbol={SHARE_SYMBOLS[props.vaultSelected]}*/}
          {/*  inav={props.inavList[props.vaultSelected]}*/}
          {/*  minVal={props.minShares[props.vaultSelected]}*/}
          {/*  pending={props.pendingRedeem[props.vaultSelected]}*/}
          {/*  orderFees={props.orderFees[props.vaultSelected]}*/}
          {/*  offboardingFees={props.offboardingFees[props.vaultSelected]}*/}
          {/*  tooltipContent={tooltipOrderFees}*/}
          {/*  minShares={props.minShares[props.vaultSelected]}*/}
          {/*/>*/}
          {/*<BaseRow gutter={[10, 10]} justify="space-between" style={{ marginTop: '1vh' }}>*/}
          {/*  <BaseCol span={24}>*/}
          {/*    <EstCard*/}
          {/*      title1={'Est Order Fees'}*/}
          {/*      value1={props.orderFees[props.vaultSelected]}*/}
          {/*      title2={'Offboarding Fees'}*/}
          {/*      value2={props.offboardingFees[props.vaultSelected]}*/}
          {/*      spinning={props.spinning}*/}
          {/*      tooltipContent={tooltipOrderFees}*/}
          {/*    />*/}
          {/*  </BaseCol>*/}
          {/*</BaseRow>*/}
        </>
      </S.RightSideCol>
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <>
      <BaseCol span={24} style={{ marginBottom: '2vh', marginLeft: '0vh' }}>
        <BaseRadio.Group defaultValue={props.vaultSelected} buttonStyle="solid" size="small">
          <BaseRadio.Button onClick={props.selectUp} value={VAULT_IDX.Y_PUP}>
            UP
          </BaseRadio.Button>
          <BaseRadio.Button onClick={props.selectDown} value={VAULT_IDX.Y_PDOWN}>
            DOWN
          </BaseRadio.Button>
          <BaseRadio.Button onClick={props.selectNeutral} value={VAULT_IDX.Y_NEUTRAL}>
            NEUTRAL
          </BaseRadio.Button>
          {/*<BaseRadio.Button onClick={props.selectUpdown} value={VAULT_IDX.Y_UPDOWN}>*/}
          {/*  UPDOWN*/}
          {/*</BaseRadio.Button>*/}
        </BaseRadio.Group>
      </BaseCol>
      <BaseRow gutter={[20, 20]}>
        <BaseCol span={24}>
          <InfoCard
            title={infos[props.vaultSelected].title}
            subtitle={infos[props.vaultSelected].subtitle}
            description={infos[props.vaultSelected].description}
            linkUrl={infos[props.vaultSelected].linkUrl}
            selectedVault={props.vaultSelected}
          />
        </BaseCol>
        <BaseCol span={24} order={(isTablet && 1) || 0}>
          <TripleCard
            mintCardProps={{
              usdcBal: props.usdcBal,
              spinning: props.walletLoading,
              vaultType: vault_types[props.vaultSelected],
              connected: props.connected,
              minUsdc: props.minUsdc[props.vaultSelected],
              maxUsdc: props.maxUsdc[props.vaultSelected],
              pending: props.pendingDeposit[props.vaultSelected],
              orderFees: props.orderFees[props.vaultSelected],
              onboardingFees: props.onboardingFees[props.vaultSelected],
              tooltipContent: tooltipOrderFees,
              shareSymbol: SHARE_SYMBOLS[props.vaultSelected],
            }}
            redeemCardProps={{
              shareBal: props.shareBals[props.vaultSelected],
              spinning: props.walletLoading,
              vaultType: vault_types[props.vaultSelected],
              connected: props.connected,
              shareSymbol: SHARE_SYMBOLS[props.vaultSelected],
              inav: props.inavList[props.vaultSelected],
              minVal: props.minShares[props.vaultSelected],
              pending: props.pendingRedeem[props.vaultSelected],
              orderFees: props.orderFees[props.vaultSelected],
              offboardingFees: props.offboardingFees[props.vaultSelected],
              tooltipContent: tooltipOrderFees,
              minShares: props.minShares[props.vaultSelected],
            }}
            tripleSelected={props.tripleSelected}
            selectSwap={props.selectSwap}
            selectMint={props.selectMint}
            selectRedeem={props.selectRedeem}
          />

          {/*  */}
          {/*  <MintCard*/}
          {/*    usdcBal={props.usdcBal}*/}
          {/*    spinning={props.walletLoading}*/}
          {/*    vaultType={vault_types[props.vaultSelected]}*/}
          {/*    connected={props.connected}*/}
          {/*    minUsdc={props.minUsdc[props.vaultSelected]}*/}
          {/*    maxUsdc={props.maxUsdc[props.vaultSelected]}*/}
          {/*    pending={props.pendingDeposit[props.vaultSelected]}*/}
          {/*    orderFees={props.orderFees[props.vaultSelected]}*/}
          {/*    onboardingFees={props.onboardingFees[props.vaultSelected]}*/}
          {/*    tooltipContent={tooltipOrderFees}*/}
          {/*  />*/}
          {/*  <S.Space />*/}
          {/*  <BaseRow gutter={[10, 10]} justify="space-between">*/}
          {/*    <BaseCol span={24}>*/}
          {/*      <EstCard*/}
          {/*        title1={'Est Order Fees'}*/}
          {/*        value1={props.orderFees[props.vaultSelected]}*/}
          {/*        title2={'Onboarding Fees'}*/}
          {/*        value2={props.onboardingFees[props.vaultSelected]}*/}
          {/*        spinning={props.spinning}*/}
          {/*        tooltipContent={tooltipOrderFees}*/}
          {/*      />*/}
          {/*    </BaseCol>*/}
          {/*  </BaseRow>*/}
          {/*</BaseCol>*/}

          {/*/!*<BaseCol xs={24} md={12} order={(isTablet && 2) || 0}>*!/*/}

          {/*/!*</BaseCol>*!/*/}

          {/*<BaseCol xs={24} md={12} order={(isTablet && 3) || 0}>*/}
          {/*  <RedeemCard*/}
          {/*    shareBal={props.shareBals[props.vaultSelected]}*/}
          {/*    spinning={props.walletLoading}*/}
          {/*    vaultType={vault_types[props.vaultSelected]}*/}
          {/*    connected={props.connected}*/}
          {/*    shareSymbol={SHARE_SYMBOLS[props.vaultSelected]}*/}
          {/*    inav={props.inavList[props.vaultSelected]}*/}
          {/*    minVal={props.minShares[props.vaultSelected]}*/}
          {/*    pending={props.pendingRedeem[props.vaultSelected]}*/}
          {/*    orderFees={props.orderFees[props.vaultSelected]}*/}
          {/*    offboardingFees={props.offboardingFees[props.vaultSelected]}*/}
          {/*    tooltipContent={tooltipOrderFees}*/}
          {/*    minShares={props.minShares[props.vaultSelected]}*/}
          {/*  />*/}
          {/*  <S.Space />*/}
          {/*  <BaseRow gutter={[10, 10]} justify="space-between" style={{ marginTop: '1vh' }}>*/}
          {/*    <BaseCol span={24}>*/}
          {/*      <EstCard*/}
          {/*        title1={'Est Order Fees'}*/}
          {/*        value1={props.orderFees[props.vaultSelected]}*/}
          {/*        title2={'Offboarding Fees'}*/}
          {/*        value2={props.offboardingFees[props.vaultSelected]}*/}
          {/*        spinning={props.spinning}*/}
          {/*        tooltipContent={tooltipOrderFees}*/}
          {/*      />*/}
          {/*    </BaseCol>*/}
          {/*  </BaseRow>*/}
        </BaseCol>
        <BaseCol xs={12} md={8} order={(isTablet && 5) || 0}>
          <AumCard
            title={'iNav'}
            value={quantize(props.inavList[props.vaultSelected] * NAV_MULTIPLER, 2)}
            spinning={props.spinning}
            unit={''}
          />
        </BaseCol>
        <BaseCol xs={12} md={8} order={(isTablet && 6) || 0}>
          <AumCard
            title={'AUM'}
            value={props.aums[props.vaultSelected].toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
            spinning={props.spinning}
            unit={'sUSD'}
          />
        </BaseCol>
        <BaseCol xs={12} md={8} order={(isTablet && 7) || 0}>
          <StatisticsCard
            name={RISK_HEADER}
            value={statistics[props.vaultSelected].riskValue}
            color={`${RISK_COLORS[statistics[props.vaultSelected].riskValue - 1]}` as StatisticColor}
            unit={'/ 5'}
          />
        </BaseCol>

        <BaseCol xs={12} md={8} order={(isTablet && 8) || 0}>
          <ShareCard
            title={'Share Supply'}
            value={Number(props.shareSupply[props.vaultSelected]).toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
            spinning={props.spinning}
            unit={''}
          />
        </BaseCol>
        <BaseCol xs={12} md={8} order={(isTablet && 9) || 0}>
          <ShareCard
            title={'Share Holders'}
            value={props.numShareholders[props.vaultSelected]}
            spinning={props.spinning}
            unit={''}
          />
        </BaseCol>
        <BaseCol xs={12} md={8} order={(isTablet && 10) || 0}>
          <ExploreCard
            title={'Explore Token'}
            value={'→'}
            spinning={props.spinning}
            address={vaultParams[props.vaultSelected].vaultAddress}
          />
        </BaseCol>

        <BaseCol id="big-chart" md={24} xs={24} order={(isTablet && 15) || 0}>
          <BigChart
            dayHistories={props.dayHistories[props.vaultSelected]}
            weekHistories={props.weekHistories[props.vaultSelected]}
            monthHistories={props.monthHistories[props.vaultSelected]}
            month3Histories={props.month3Histories[props.vaultSelected]}
            yearHistories={props.yearHistories[props.vaultSelected]}
            allHistories={props.allHistories[props.vaultSelected]}
            spinning={props.spinning}
          />
        </BaseCol>
        <BaseCol span={24} order={(isTablet && 20) || 0}>
          <HoldingsSummaryCard
            portfolios={props.portfolios}
            selector={props.vaultSelected}
            loading={props.spinning}
            processingStatus={props.processingStatus}
          />
        </BaseCol>
        <BaseCol span={24} order={(isTablet && 21) || 0}>
          <HoldingsTableCard
            portfolios={props.portfolios}
            selector={props.vaultSelected}
            loading={props.spinning}
            processingStatus={props.processingStatus}
          />
        </BaseCol>
        {/*<BaseCol span={24} order={(isTablet && 22) || 0}>*/}
        {/*  <ZKExplorerCard*/}
        {/*    title={'View Executed Orders'}*/}
        {/*    url={`https://dydx.l2beat.com/users/${starkAddresses[props.vaultSelected]}`}*/}
        {/*    spinning={false}*/}
        {/*    value={'→'}*/}
        {/*  />*/}
        {/*</BaseCol>*/}
      </BaseRow>
    </>
  );

  return (
    <>
      <PageTitle>{t('common.vaults')}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default VaultsDashboardPage;
