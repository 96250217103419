import { BigNumber, ethers } from 'ethers';
import { DydxClient } from '@dydxprotocol/v3-client';
import opsHelperAbiPackage from '../assets/contracts/OpsHelper.json';
import bnbHelperAbiPackage from '../assets/contracts/SpotHelper.json';
import opsManagerAbiPackage from '../assets/contracts/OpsManager.json';
import myerc20AbiPackage from '../assets/contracts/MyERC20.json';
// import poolAbiPackage from '../assets/contracts/IUniswapV3Pool.json';
import vaultAbiPackage from '../assets/contracts/Vault.json';
import routerAbiPackage from '../assets/contracts/Router.json';
import marketSettingsAbiPackage from '../assets/contracts/IPerpsV2MarketSettings.json';
import navRecordsAbiPackage from '../assets/contracts/NavRecords.json';
import spotVaultAbiPackage from '../assets/contracts/SpotVault.json';
import discountTokenAbiPackage from '../assets/contracts/DiscountToken.json';
import discountPolicyAbiPackage from '../assets/contracts/IDiscountPolicy.json';
import oracleConnectorAbiPackage from '../assets/contracts/IOracleConnector.json';
import { LocalnetItems, MainnetItems, TestnetItems } from './AddressItems';
import { configureChains, createConfig } from 'wagmi';
import { EthereumClient, w3mConnectors } from '@web3modal/ethereum';
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect';
import { hardhat, localhost, optimism, bsc } from 'wagmi/chains';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { getEthersSigner } from './ethersProviders';
import {
  BNB_VAULT_ADDRESSES,
  BNB_ASSET_ADDRESSES,
  BNB_ASSET_NAMES,
  DISCOUNT_POLICY_ADDRESS,
  DISCOUNT_TOKEN_ADDRESS,
  ORACLE_CONNECTOR_ADDRESS,
} from '@app/lib/BnbItems';

import { switchNetwork, disconnect } from '@wagmi/core';
import * as url from 'url';
import { toast } from 'react-toastify';

export const currentChain = optimism; // optimism; // hardhat
export const ADDRESSES = MainnetItems;

const bnbHelperAbi = bnbHelperAbiPackage.abi;
const opsHelperAbi = opsHelperAbiPackage.abi;
const opsManagerAbi = opsManagerAbiPackage.abi;
const myerc20Abi = myerc20AbiPackage.abi;
// const poolAbi = poolAbiPackage.abi;
const vaultAbi = vaultAbiPackage.abi;
const routerAbi = routerAbiPackage.abi;
const marketSettingsAbi = marketSettingsAbiPackage.abi;
const navRecordsAbi = navRecordsAbiPackage.abi;

const spotVaultAbi = spotVaultAbiPackage.abi;
const discountTokenAbi = discountTokenAbiPackage.abi;
const discountPolicyAbi = discountPolicyAbiPackage.abi;
const oracleConnectorAbi = oracleConnectorAbiPackage.abi;

export const LIVE = true;

export const UNDER_MAINTENANCE = false;

export const EXPLORER_URL = LIVE ? 'https://optimistic.etherscan.io' : 'https://goerli-optimism.etherscan.io';
export const BSC_EXPLORER_URL = 'https://bscscan.com';

export const DECIMALS = 18;

export const UNIT18 = BigNumber.from(10).pow(DECIMALS);

export enum VAULT_TYPE {
  Y_UPDOWN = 'Y_NEUTRAL',
  Y_NEUTRAL = 'Y_NEUTRAL',
  Y_PUP = 'Y_PUP',
  Y_PDOWN = 'Y_PDOWN',
}

export enum VAULT_IDX {
  Y_UPDOWN = 1,
  Y_NEUTRAL = 1,
  Y_PUP = 2,
  Y_PDOWN = 3,
}

export enum SPOT_IDX {
  S_UP = 0,
  I_BTC = 1,
  I_ETH = 2,
}

interface PARAMS {
  omAddress: string;
  vaultAddress: string;
}

export interface USERS_BALS {
  user: string;
  bal: string;
}

export const Y_UPDOWN_PARAMS: PARAMS = {
  vaultAddress: ADDRESSES.Deployments.Vaults[VAULT_IDX.Y_UPDOWN].Vault,
  omAddress: ADDRESSES.Deployments.Vaults[VAULT_IDX.Y_UPDOWN].OpsManager,
};

export const Y_NEUTRAL_PARAMS: PARAMS = {
  vaultAddress: ADDRESSES.Deployments.Vaults[VAULT_IDX.Y_NEUTRAL].Vault,
  omAddress: ADDRESSES.Deployments.Vaults[VAULT_IDX.Y_NEUTRAL].OpsManager,
};

export const Y_PUP_PARAMS: PARAMS = {
  vaultAddress: ADDRESSES.Deployments.Vaults[VAULT_IDX.Y_PUP].Vault,
  omAddress: ADDRESSES.Deployments.Vaults[VAULT_IDX.Y_PUP].OpsManager,
};

export const Y_PDOWN_PARAMS: PARAMS = {
  vaultAddress: ADDRESSES.Deployments.Vaults[VAULT_IDX.Y_PDOWN].Vault,
  omAddress: ADDRESSES.Deployments.Vaults[VAULT_IDX.Y_PDOWN].OpsManager,
};

// Constants from env file
export const RPC_PROVIDER_LIST =
  currentChain.network.toString() == 'hardhat'
    ? [hardhat.rpcUrls.default.http.toString()]
    : LIVE
    ? // ? ['https://rpc.ankr.com/optimism/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3']
      // : ['https://rpc.ankr.com/optimism_testnet/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3'];
      ['https://virulent-tiniest-sea.optimism.quiknode.pro/44e0c7c0f5bd5f62facbdc0c5059332278f69c61/']
    : // : ['https://optimism-goerli.publicnode.com'];
      ['https://virulent-tiniest-sea.optimism.quiknode.pro/44e0c7c0f5bd5f62facbdc0c5059332278f69c61/'];

export const JSON_RPC_PROVIDER =
  currentChain.network.toString() == 'hardhat' ? hardhat.rpcUrls.default.http.toString() : RPC_PROVIDER_LIST[0];

export const BSC_JSON_RPC_PROVIDER =
  'https://yolo-thrilling-pond.bsc.quiknode.pro/b463c0300f7bf03be02d2cb41f3412fab8fa430e';
const BscWsRpcProvider = 'wss://yolo-thrilling-pond.bsc.quiknode.pro/b463c0300f7bf03be02d2cb41f3412fab8fa430e/';
export const bscProvider: ethers.providers.WebSocketProvider = new ethers.providers.WebSocketProvider(
  BscWsRpcProvider,
  {
    name: bsc.name,
    chainId: bsc.id,
  },
);

const TX_TIMEOUT = 3 * 60 * 1000;

declare global {
  interface Window {
    ethereum: any;
  }
}
const ethereum = window.ethereum;

const WsRpcProvider =
  currentChain.network.toString() == 'hardhat'
    ? 'ws://localhost:8545'
    : // 'wss://optimism.publicnode.com';
      'wss://virulent-tiniest-sea.optimism.quiknode.pro/44e0c7c0f5bd5f62facbdc0c5059332278f69c61/';

export const provider: ethers.providers.WebSocketProvider = new ethers.providers.WebSocketProvider(WsRpcProvider, {
  name: currentChain.name,
  chainId: currentChain.id,
});

const SepoliaOpWsRpcProvider = 'wss://optimism-sepolia.publicnode.com';
// const SepoliaOpWsRpcProvider =
//   'wss://rpc.ankr.com/optimism_sepolia/ws/9e4b472c60019bf1d0f5f340cd03aa712aad82dbba09b943547db4ae76e136ee';

export const sepoliaOptimismProvider: ethers.providers.WebSocketProvider = new ethers.providers.WebSocketProvider(
  SepoliaOpWsRpcProvider,
  {
    name: 'OP Sepolia',
    chainId: 11155420,
  },
);

export let funds: Array<ethers.Contract>;
let fundTransactors: Array<ethers.Contract>;
let myerc20: ethers.Contract;
let myerc20Transactor: ethers.Contract;
let pools: Array<ethers.Contract>;
export let router: ethers.Contract;
let routerTransactor: ethers.Contract;
export let helper: ethers.Contract;
export let bnbHelper: ethers.Contract;
// let faucetTransactor: ethers.Contract;
let marketSettings: ethers.Contract;
let navRecords: ethers.Contract;
let bnbNavRecords: ethers.Contract;

interface FUND_CONTRACTS {
  VAULT: ethers.Contract;
  VAULT_TRANSACT: ethers.Contract;
  OM: ethers.Contract;
}

interface SPOT_CONTRACTS {
  VAULT: ethers.Contract;
  VAULT_TRANSACT: ethers.Contract;
}

interface FUND_SELECTOR {
  Y_UPDOWN: FUND_CONTRACTS;
  Y_NEUTRAL: FUND_CONTRACTS;
  Y_PUP: FUND_CONTRACTS;
  Y_PDOWN: FUND_CONTRACTS;
}
export let fundSelector: FUND_SELECTOR;
export let y_updown: FUND_CONTRACTS;
export let y_neutral: FUND_CONTRACTS;
export let y_pup: FUND_CONTRACTS;
export let y_pdown: FUND_CONTRACTS;

export let s_up: SPOT_CONTRACTS;
export let i_btc: SPOT_CONTRACTS;
export let i_eth: SPOT_CONTRACTS;

export let discountToken: { VAULT_TRANSACT: ethers.Contract; VAULT: ethers.Contract };

export let activeUser: any;

export let requestRecords: ReqRecord[];
export let processedRecords: ProRecord[];

export const networkChains = [currentChain, bsc];
export const projectId = '65f4489871a1058a31939fef588ddd9a';

// Wagmi client

const connector = new WalletConnectConnector({
  options: {
    projectId: projectId,
  },
  chains: networkChains,
});

const rpcProviders: { [key: number]: string } = {
  10: JSON_RPC_PROVIDER,
  56: BSC_JSON_RPC_PROVIDER,
};

export const { chains, publicClient } = configureChains(
  [currentChain, bsc],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: rpcProviders[chain.id],
      }),
    }),
  ],
);

export const wagmiConfig = createConfig({
  autoConnect: true,
  // connectors: [connector],
  connectors: w3mConnectors({
    chains: chains,
    projectId: projectId,
  }),
  publicClient: publicClient,
});

// Convert to Ethers Provider
// export const provider = getEthersProvider({ chainId: polygonMumbai.id });
// export const provider = wagmiClient
// Web3Modal Ethereum Client
export const ethereumClient = new EthereumClient(wagmiConfig, networkChains);

function _setActiveUser() {
  activeUser = ethereumClient.getAccount().address;
  // if (activeUser == undefined) {
  //   toast.error('No connected wallet found.');
  // }
}

// function _appendBuffer(buffer1: ArrayBuffer, buffer2: ArrayBuffer): ArrayBuffer {
//   const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
//   tmp.set(new Uint8Array(buffer1), 0);
//   tmp.set(new Uint8Array(buffer2), buffer1.byteLength);

//   return tmp.buffer;
// }

export interface ReqRecord {
  vault: string;
  requestType: string;
  sender: string;
  receiver: string;
  timestamp: string;
  amount: string;
}

export interface ProRecord {
  vault: string;
  requestType: string;
  receiver: string;
  timestamp: string;
  amountIn: string;
  amountOut: string;
}

const vaultName: { [key: string]: string } = {
  '0xb7e2EA4c06aA130Ee8AF25C1F11b67d8F4dfEd81': 'UPDOWN',
  '0xB8C7d63d45db4c6310a90aD68F65165417527B2d': 'NEUTRAL',
  '0xc4409f0A14fa107081407003c587Db191B8572c6': 'UP',
  '0x34791C02CD7A809C7552571121F358e7fa17AC0F': 'DOWN',
};

export async function getRecords() {
  const [numRequest, numProcessed] = await router.numberOfRecords(activeUser);
  const [requests, processed] = await router.getRecords(activeUser, 0, numRequest, 0, numProcessed);
  let rr: ReqRecord[] = [];
  let pr: ProRecord[] = [];
  requests.forEach((x: any) => {
    const date = new Date(Number(x[4]) * 1000);
    const currency = Number(x[1]) == 0 ? 'USDC' : 'SHARES';
    if (
      [
        Y_UPDOWN_PARAMS.vaultAddress,
        Y_NEUTRAL_PARAMS.vaultAddress,
        Y_PUP_PARAMS.vaultAddress,
        Y_PDOWN_PARAMS.vaultAddress,
      ].includes(x[0])
    ) {
      rr.push({
        vault: vaultName[x[0] as string] as string,
        requestType: Number(x[1]) == 0 ? 'Deposit' : 'Redeem',
        sender: x[2],
        receiver: x[3],
        timestamp: date.toLocaleString(),
        amount: ethers.utils.formatUnits(x[5], DECIMALS) + ' ' + currency,
      });
    }
  });

  processed.forEach((x: any) => {
    const date = new Date(Number(x[3]) * 1000);
    const currencyIn = Number(x[1]) == 0 ? 'USDC' : 'SHARES';
    const currencyOut = Number(x[1]) != 0 ? 'USDC' : 'SHARES';
    if (
      [
        Y_UPDOWN_PARAMS.vaultAddress,
        Y_NEUTRAL_PARAMS.vaultAddress,
        Y_PUP_PARAMS.vaultAddress,
        Y_PDOWN_PARAMS.vaultAddress,
      ].includes(x[0])
    ) {
      pr.push({
        vault: vaultName[x[0] as string] as string,
        requestType: Number(x[1]) == 0 ? 'Deposit' : 'Redeem',
        receiver: x[2],
        timestamp: date.toLocaleString(),
        amountIn: ethers.utils.formatUnits(x[4], DECIMALS) + ' ' + currencyIn,
        amountOut: ethers.utils.formatUnits(x[5], DECIMALS) + ' ' + currencyOut,
      });
    }
  });

  rr = rr.reverse();
  pr = pr.reverse();

  requestRecords = rr;
  processedRecords = pr;

  return [rr, pr];
}

export async function setup() {
  y_updown = {
    VAULT: new ethers.Contract(Y_UPDOWN_PARAMS.vaultAddress, vaultAbi, provider),
    VAULT_TRANSACT: new ethers.Contract(Y_UPDOWN_PARAMS.vaultAddress, vaultAbi, provider),
    OM: new ethers.Contract(Y_UPDOWN_PARAMS.omAddress, opsManagerAbi, provider),
  };

  y_neutral = {
    VAULT: new ethers.Contract(Y_NEUTRAL_PARAMS.vaultAddress, vaultAbi, provider),
    VAULT_TRANSACT: new ethers.Contract(Y_NEUTRAL_PARAMS.vaultAddress, vaultAbi, provider),
    OM: new ethers.Contract(Y_NEUTRAL_PARAMS.omAddress, opsManagerAbi, provider),
  };

  y_pup = {
    VAULT: new ethers.Contract(Y_PUP_PARAMS.vaultAddress, vaultAbi, provider),
    VAULT_TRANSACT: new ethers.Contract(Y_PUP_PARAMS.vaultAddress, vaultAbi, provider),
    OM: new ethers.Contract(Y_PUP_PARAMS.omAddress, opsManagerAbi, provider),
  };

  y_pdown = {
    VAULT: new ethers.Contract(Y_PDOWN_PARAMS.vaultAddress, vaultAbi, provider),
    VAULT_TRANSACT: new ethers.Contract(Y_PDOWN_PARAMS.vaultAddress, vaultAbi, provider),
    OM: new ethers.Contract(Y_PDOWN_PARAMS.omAddress, opsManagerAbi, provider),
  };

  s_up = {
    VAULT: new ethers.Contract(BNB_VAULT_ADDRESSES[0], spotVaultAbi, bscProvider),
    VAULT_TRANSACT: new ethers.Contract(BNB_VAULT_ADDRESSES[0], spotVaultAbi, bscProvider),
  };

  i_btc = {
    VAULT: new ethers.Contract(BNB_VAULT_ADDRESSES[1], spotVaultAbi, bscProvider),
    VAULT_TRANSACT: new ethers.Contract(BNB_VAULT_ADDRESSES[1], spotVaultAbi, bscProvider),
  };

  i_eth = {
    VAULT: new ethers.Contract(BNB_VAULT_ADDRESSES[2], spotVaultAbi, bscProvider),
    VAULT_TRANSACT: new ethers.Contract(BNB_VAULT_ADDRESSES[2], spotVaultAbi, bscProvider),
  };

  discountToken = {
    VAULT: new ethers.Contract(DISCOUNT_TOKEN_ADDRESS, discountTokenAbi, bscProvider),
    VAULT_TRANSACT: new ethers.Contract(DISCOUNT_TOKEN_ADDRESS, discountTokenAbi, bscProvider),
  };

  fundSelector = {
    Y_UPDOWN: y_updown,
    Y_NEUTRAL: y_neutral,
    Y_PUP: y_pup,
    Y_PDOWN: y_pdown,
  };

  myerc20 = new ethers.Contract(ADDRESSES.Assets.SUSD, myerc20Abi, provider);
  helper = new ethers.Contract(ADDRESSES.Deployments.OpsHelper, opsHelperAbi, provider);
  bnbHelper = new ethers.Contract('0x6DF6799d0B9eaccD402c4E79688646af7726a11C', bnbHelperAbi, bscProvider);
  router = new ethers.Contract(ADDRESSES.Deployments.Router, routerAbi, provider);
  marketSettings = new ethers.Contract(ADDRESSES.MarketSettings, marketSettingsAbi, provider);
  navRecords = new ethers.Contract(ADDRESSES.Deployments.NavRecords, navRecordsAbi, sepoliaOptimismProvider);
  bnbNavRecords = new ethers.Contract(
    '0xBDF4D0249194A07675c7Ff7F6994A61cDE84bf2c',
    navRecordsAbi,
    sepoliaOptimismProvider,
  );

  // ethereumClient.watchAccount( async () => { await refresh()})
}

async function getCurrentChainId() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const network = await provider.getNetwork();
    return network.chainId;
  } catch (error) {
    return networkChains[0].id;
  }
}

export let activeSigner: ethers.providers.JsonRpcSigner;

export async function refresh() {
  if (await isConnected()) {
    _setActiveUser();
    const chainId = await getCurrentChainId();
    try {
      const account = (await getEthersSigner({ chainId: chainId })) as ethers.providers.JsonRpcSigner;
      activeSigner = account;
      myerc20Transactor = myerc20.connect(account);
      routerTransactor = router.connect(account);
      fundSelector.Y_UPDOWN.VAULT_TRANSACT = fundSelector.Y_UPDOWN.VAULT_TRANSACT.connect(account);
      fundSelector.Y_NEUTRAL.VAULT_TRANSACT = fundSelector.Y_NEUTRAL.VAULT_TRANSACT.connect(account);
      fundSelector.Y_PUP.VAULT_TRANSACT = fundSelector.Y_PUP.VAULT_TRANSACT.connect(account);
      fundSelector.Y_PDOWN.VAULT_TRANSACT = fundSelector.Y_PDOWN.VAULT_TRANSACT.connect(account);

      discountToken.VAULT_TRANSACT = discountToken.VAULT_TRANSACT.connect(account);
      s_up.VAULT_TRANSACT = s_up.VAULT_TRANSACT.connect(account);
      i_btc.VAULT_TRANSACT = i_btc.VAULT_TRANSACT.connect(account);
      i_eth.VAULT_TRANSACT = i_eth.VAULT_TRANSACT.connect(account);
    } catch (error) {
      console.log('%%%%%%%%% Error in refresh', error);
      await disconnect();
      toast.warn(`Please switch your wallet to the Optimism or ${networkChains[1].name} network`);
    }

    // faucetTransactor = faucetTransactor.connect(account);
  }
}

export async function hasDepositsUnderProcessing() {
  const promises = [
    helper.getProcessingDepositRequests(fundSelector.Y_UPDOWN.OM.address, 0, 0),
    helper.getProcessingDepositRequests(fundSelector.Y_NEUTRAL.OM.address, 0, 0),
    helper.getProcessingDepositRequests(fundSelector.Y_PUP.OM.address, 0, 0),
    helper.getProcessingDepositRequests(fundSelector.Y_PDOWN.OM.address, 0, 0),
  ];

  const res = await Promise.all(promises);

  const returnVals = [false, false, false, false];
  for (let i = 0; i < res.length; i++) {
    for (let j = 0; j < res[i].length; j++) {
      if (res[i][j][1] == activeUser) {
        returnVals[i] = true;
        break;
      }
    }
  }
  return returnVals;
}

export async function hasRedeemsUnderProcessing() {
  const promises = [
    helper.getProcessingRedeemRequests(fundSelector.Y_UPDOWN.OM.address, 0, 0),
    helper.getProcessingRedeemRequests(fundSelector.Y_NEUTRAL.OM.address, 0, 0),
    helper.getProcessingRedeemRequests(fundSelector.Y_PUP.OM.address, 0, 0),
    helper.getProcessingRedeemRequests(fundSelector.Y_PDOWN.OM.address, 0, 0),
  ];

  const res = await Promise.all(promises);

  const returnVals = [false, false, false, false];
  for (let i = 0; i < res.length; i++) {
    for (let j = 0; j < res[i].length; j++) {
      if (res[i][j][1] == activeUser) {
        returnVals[i] = true;
        break;
      }
    }
  }
  return returnVals;
}

export async function getOrderFees() {
  const promises = [
    fundSelector.Y_UPDOWN.OM.getPortfolioAssetsLength(),
    fundSelector.Y_NEUTRAL.OM.getPortfolioAssetsLength(),
    fundSelector.Y_PUP.OM.getPortfolioAssetsLength(),
    fundSelector.Y_PDOWN.OM.getPortfolioAssetsLength(),
    marketSettings.minKeeperFee(),
  ];

  const res = await Promise.all(promises);
  const keeperFee = Number(ethers.utils.formatUnits(res[4], DECIMALS));
  const bufferMultiplier = 2.5;
  const fees = [
    (Number(res[0]) * keeperFee * bufferMultiplier).toFixed(2),
    (Number(res[1]) * keeperFee * bufferMultiplier).toFixed(2),
    (Number(res[2]) * keeperFee * bufferMultiplier).toFixed(2),
    (Number(res[3]) * keeperFee * bufferMultiplier).toFixed(2),
  ];
  return fees;
}

export async function connect() {
  // await _setActiveUser();
  await refresh();
}

export async function addSusd() {
  try {
    await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: ADDRESSES.Assets.SUSD,
          symbol: await susdSymbol(),
          decimals: await susdDecimals(),
          image: 'https://raw.githubusercontent.com/Synthetixio/synthetix-assets/master/synths/png/sUSD.png',
        },
      },
    });
  } catch {
    // toast.error('Metamask not connected.');
  }
}

export async function switchToOP(): Promise<string> {
  return await switchChain('0xA');
}

export async function switchToBnb(): Promise<string> {
  return await switchChain('0x38');
}

async function switchChain(targetChain: string): Promise<string> {
  const chainId: string = await window.ethereum.request({
    method: 'eth_chainId',
    params: [],
  });
  console.log('current chainId', chainId, 'target chainId', targetChain);

  if (chainId.toLowerCase() === targetChain.toLowerCase()) {
    return targetChain;
  }

  try {
    await switchNetwork({ chainId: parseInt(targetChain, 16) });
    return targetChain;
  } catch (error) {
    console.log('error', error);
    return 'error';
  }
}

export async function addTokens(title: string) {
  const chainString = await switchToOP();
  if (chainString === 'error') {
    return;
  }

  if (title == 'NEUTRAL') {
    await addToken(VAULT_TYPE.Y_NEUTRAL, 'Y-NEUTRAL', 1);
  } else if (title == 'UPDOWN') {
    await addToken(VAULT_TYPE.Y_UPDOWN, 'Y-UPDOWN', 0);
  } else if (title == 'UP') {
    await addToken(VAULT_TYPE.Y_PUP, 'Y-UP', 2);
  } else if (title == 'DOWN') {
    await addToken(VAULT_TYPE.Y_PDOWN, 'Y-DOWN', 3);
  }
}

const imageUrls = [
  'https://yiedl-assets.web.app/tokens/updown_sm.png',
  'https://yiedl-assets.web.app/tokens/neutral_sm.png',
  'https://yiedl-assets.web.app/tokens/up_sm.png',
  'https://yiedl-assets.web.app/tokens/down_sm.png',
];

export async function addSpotToken(symbol: string, idx: number) {
  const spots = [s_up, i_btc, i_eth];
  const chainString = await switchToBnb();
  if (chainString === 'error') {
    return;
  }

  // try {
  //   await window.ethereum.request({
  //     method: 'wallet_switchEthereumChain',
  //     params: [
  //       {
  //         chainId: '0x38',
  //       },
  //     ],
  //   });
  // } finally {

  try {
    // console.log('here', idx, BNB_VAULT_ADDRESSES[0], symbol, await spots[0].VAULT.decimals());
    await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: BNB_VAULT_ADDRESSES[0],
          symbol: await spots[0].VAULT.symbol(),
          // decimals: await spots[0].VAULT.decimals(),
          decimals: 18,
          // image: imageUrls[idx],
        },
      },
    });
  } catch {
    console.error('error');
    // toast.error("Metamask not connected.")
  }
  // }
}

export async function addToken(vaultType: VAULT_TYPE, symbol: string, idx: number) {
  // try {
  await ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: fundSelector[vaultType].VAULT.address,
        symbol: symbol,
        decimals: await fundSelector[vaultType].VAULT.decimals(),
        image: imageUrls[idx],
      },
    },
  });
  // } catch {
  //   toast.error("Metamask not connected.")
  // }
}

// export async function addNetwork() {
//   ethereum.request({
//     method: 'wallet_addEthereumChain',
//     params: [
//       {
//         chainId: LIVE ? '0x89' : '0x13881',
//         rpcUrls: LIVE ? ['https://polygon-rpc.com'] : ['https://rpc.ankr.com/polygon_mumbai'],
//         chainName: LIVE ? 'Polygon' : 'Mumbai Testnet',
//         nativeCurrency: {
//           name: LIVE ? 'MATIC' : 'Mumbai MATIC',
//           symbol: 'MATIC',
//           decimals: 18,
//         },
//         blockExplorerUrls: [EXPLORER_URL],
//       },
//     ],
//   });
// }

export async function isConnected() {
  // const chainId = await wagmiClient.  .request({ method: 'eth_chainId' });
  // return chainId === MATIC_ID_HEX;
  //   console.log(await ethereumClient.getAccount().connector?.getSigner())
  return (await ethereumClient.getAccount().connector?.getAccount()) != undefined;
}

function toReadable(bn: BigNumber, units: string | number) {
  return ethers.utils.formatUnits(bn, units);
}

export function aumNavReadable(bn: BigNumber) {
  return toReadable(bn, DECIMALS);
}

export function yiedlReadable(bn: BigNumber) {
  return toReadable(bn, 18);
}

async function wrapTx(tx: Promise<ethers.providers.TransactionResponse>) {
  const txReceipt = await tx;
  const promise = provider.waitForTransaction(txReceipt['hash'], 2, TX_TIMEOUT);

  return [txReceipt, promise];
}

// Beginning of contract function calls.
export async function DEFAULT_ADMIN_ROLE(fundId: number): Promise<string> {
  return funds[fundId].DEFAULT_ADMIN_ROLE();
}

export async function MAX_CYCLIC_FEES(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].MAX_CYCLIC_FEES();
}

export async function RCI_CHILD_ADMIN(fundId: number): Promise<string> {
  return funds[fundId].RCI_CHILD_ADMIN();
}

export async function RCI_MAIN_ADMIN(fundId: number): Promise<string> {
  return funds[fundId].RCI_MAIN_ADMIN();
}

export async function allowance(fundId: number, owner: string, spender: string): Promise<ethers.BigNumber> {
  return funds[fundId].allowance(owner, spender);
}

type Transactor = (...args: any[]) => Promise<ethers.providers.TransactionResponse>;
type WrapperResponse = {
  receipt: ethers.providers.TransactionResponse;
  transaction: Promise<ethers.providers.TransactionReceipt>;
};

// (ethers.providers.TransactionResponse | Promise<ethers.providers.TransactionReceipt>)
function txWrapper<T extends Transactor>(
  fn: T,
  confirmations = 2,
  timeout = TX_TIMEOUT,
): (...x: Parameters<T>) => Promise<WrapperResponse> {
  return async function (...x: Parameters<T>) {
    // eslint-disable-next-line prefer-spread
    const receipt = await fn.apply(undefined, x);
    const transaction = provider.waitForTransaction(receipt['hash'], confirmations, timeout);

    return { receipt, transaction };
  };
}

function bscWrapper<T extends Transactor>(
  fn: T,
  confirmations = 2,
  timeout = TX_TIMEOUT,
): (...x: Parameters<T>) => Promise<WrapperResponse> {
  return async function (...x: Parameters<T>) {
    // eslint-disable-next-line prefer-spread
    const receipt = await fn.apply(undefined, x);
    const transaction = bscProvider.waitForTransaction(receipt['hash'], confirmations, timeout);

    return { receipt, transaction };
  };
}

// const approve_ = txWrapper((fundId: number, spender: string, amount: ethers.BigNumber) => fundTransactors[fundId].approve(spender, amount));

// export async function nextWindow() {
//   return (await faucetTransactor.nextWindow(activeUser)).toNumber();
// }

export async function approve(fundId: number, spender: string, amount: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].approve(spender, amount));
}

export async function asset(fundId: number): Promise<string> {
  return funds[fundId].asset();
}

export async function aumInUse(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].aumInUse();
}

export async function balanceOf(fundId: number, account: string): Promise<ethers.BigNumber> {
  return funds[fundId].balanceOf(account);
}

export async function blacklistPolicy(fundId: number): Promise<string> {
  return funds[fundId].blacklistPolicy();
}

export async function collectedAum(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].collectedAum();
}

// export async function computeFeeAmount(fundId: number, amount: ethers.BigNumber, feeType): Promise<ethers.BigNumber> {
//   return funds[fundId].computeFeeAmount(amount, feeType, tokenType);
// }

export async function computeSlippedAssets(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].computeSlippedAssets();
}

export async function convertToAssets(fundId: number, shares: ethers.BigNumber): Promise<ethers.BigNumber> {
  return funds[fundId].convertToAssets(shares);
}

export async function convertToShares(fundId: number, assets: ethers.BigNumber): Promise<ethers.BigNumber> {
  return funds[fundId].convertToShares(assets);
}

export async function createNewCyclicFee(
  fundId: number,
  feeName: string,
  fxd: ethers.BigNumber,
  pct: ethers.BigNumber,
) {
  return wrapTx(fundTransactors[fundId].createNewCyclicFee(feeName, fxd, pct));
}

export async function cycleNumber(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].cycleNumber();
}

export async function cyclicFeeNames(fundId: number, myuint256: ethers.BigNumber): Promise<string> {
  return funds[fundId].cyclicFeeNames();
}

export async function decimals(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].decimals();
}

export async function decreaseAllowance(fundId: number, spender: string, subtractedValue: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].decreaseAllowance(spender, subtractedValue));
}

export async function deployAumToDelegate(fundId: number, amountAumToDeploy: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].deployAumToDelegate(amountAumToDeploy));
}

export const deposit = txWrapper(
  (vaultType: VAULT_TYPE, assets: ethers.BigNumber) =>
    routerTransactor.depositRequest(fundSelector[vaultType].VAULT.address, assets),
  3,
  10 * 60 * 1000,
);

export const cancelDeposit = txWrapper(
  (vaultType: VAULT_TYPE) => routerTransactor.cancelDepositRequest(fundSelector[vaultType].VAULT.address),
  3,
  10 * 60 * 1000,
);

export const redeem = txWrapper(
  (vaultType: VAULT_TYPE, numShares: ethers.BigNumber) =>
    routerTransactor.redeemRequest(fundSelector[vaultType].VAULT.address, numShares),
  3,
  10 * 60 * 1000,
);

export const cancelRedeem = txWrapper(
  (vaultType: VAULT_TYPE) => routerTransactor.cancelRedeemRequest(fundSelector[vaultType].VAULT.address),
  3,
  10 * 60 * 1000,
);

// export async function deposit(fundId: number, assets: ethers.BigNumber, receiver: string){
//   const tx = fundTransactors[fundId].deposit(assets, receiver);
//   const txReceipt = await tx;
//   const promise = provider.waitForTransaction(txReceipt['hash'], 20, 10 * 60 * 1000);
//   return [txReceipt, promise];
// }

// export async function drDeposit(assets: ethers.BigNumber, receiver: string) {
//   const tx = drTransactor.deposit(assets, receiver);
//   const txReceipt = await tx;
//   const promise = provider.waitForTransaction(txReceipt['hash'], 1, 10 * 60 * 1000);
//   return [txReceipt, promise];
// }

// export async function drBalance(user: string) {
//   await new Promise(r => setTimeout(r, 2000));
//   return depositRequest.balanceOf(user)
// }

export async function vaultBalance(user: string, vaultType: VAULT_TYPE) {
  return fundSelector[vaultType].VAULT.balanceOf(user);
}

export async function depositWithMinShares(
  fundId: number,
  assets: ethers.BigNumber,
  receiver: string,
  minSharesExpected: ethers.BigNumber,
) {
  return wrapTx(fundTransactors[fundId].depositWithMinShares(assets, receiver, minSharesExpected));
}

export async function dydxDelegate(fundId: number): Promise<string> {
  return funds[fundId].dydxDelegate();
}

export async function feeCollector(fundId: number): Promise<string> {
  return funds[fundId].feeCollector();
}

export async function getRemainderAssetToken(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].getRemainderAssetToken();
}

export async function getRoleAdmin(fundId: number, role: string): Promise<string> {
  return funds[fundId].getRoleAdmin(role);
}

export async function getRoleMember(fundId: number, role: string, index: ethers.BigNumber): Promise<string> {
  return funds[fundId].getRoleMember(role, index);
}

export async function getRoleMemberCount(fundId: number, role: string): Promise<ethers.BigNumber> {
  return funds[fundId].getRoleMemberCount(role);
}

export async function getShareHolders(
  fundId: number,
  startIndex: ethers.BigNumber,
  endIndex: ethers.BigNumber,
): Promise<string> {
  return funds[fundId].getShareHolders(startIndex, endIndex);
}

export async function globalMaxDepositAmount(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].globalMaxDepositAmount();
}

export async function globalMaxMintAmount(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].globalMaxMintAmount();
}

export async function globalMaxRedeemAmount(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].globalMaxRedeemAmount();
}

export async function globalMaxWithdrawAmount(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].globalMaxWithdrawAmount();
}

export async function grantRole(fundId: number, role: string, account: string) {
  return wrapTx(fundTransactors[fundId].grantRole(role, account));
}

export async function hasRole(fundId: number, role: string, account: string): Promise<boolean> {
  return funds[fundId].hasRole(role, account);
}

export async function hwm(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].hwm();
}

export async function increaseAllowance(fundId: number, spender: string, addedValue: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].increaseAllowance(spender, addedValue));
}

export async function maxDeposit(fundId: number, receiver: string): Promise<ethers.BigNumber> {
  return funds[fundId].maxDeposit(receiver);
}

export async function maxDepositMap(fundId: number, myaddress: string): Promise<ethers.BigNumber> {
  return funds[fundId].maxDepositMap();
}

export async function maxMint(fundId: number, receiver: string): Promise<ethers.BigNumber> {
  return funds[fundId].maxMint(receiver);
}

export async function maxMintMap(fundId: number, myaddress: string): Promise<ethers.BigNumber> {
  return funds[fundId].maxMintMap();
}

export async function maxRedeem(fundId: number, owner: string): Promise<ethers.BigNumber> {
  return funds[fundId].maxRedeem(owner);
}

export async function maxRedeemMap(fundId: number, myaddress: string): Promise<ethers.BigNumber> {
  return funds[fundId].maxRedeemMap();
}

export async function maxWithdraw(fundId: number, owner: string): Promise<ethers.BigNumber> {
  return funds[fundId].maxWithdraw(owner);
}

export async function maxWithdrawMap(fundId: number, myaddress: string): Promise<ethers.BigNumber> {
  return funds[fundId].maxWithdrawMap();
}

export async function mint(fundId: number, shares: ethers.BigNumber, receiver: string) {
  return wrapTx(fundTransactors[fundId].mint(shares, receiver));
}

export async function mintWithMaxAssets(
  fundId: number,
  shares: ethers.BigNumber,
  receiver: string,
  maxAssetsExpected: ethers.BigNumber,
) {
  return wrapTx(fundTransactors[fundId].mintWithMaxAssets(shares, receiver, maxAssetsExpected));
}

export async function name(fundId: number): Promise<string> {
  return funds[fundId].name();
}

export async function nav(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].nav();
}

export async function navDeviationThresholdForSkimming(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].navDeviationThresholdForSkimming();
}

export async function numCyclicFees(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].numCyclicFees();
}

// export async function numberOfShareHolders(fundId: number): Promise<ethers.BigNumber> {
//   return funds[fundId].numberOfShareHolders();
// }

export async function openSubscription(fundId: number) {
  return wrapTx(fundTransactors[fundId].openSubscription());
}

export async function phase(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].phase();
}

export async function previewDeposit(fundId: number, assets: ethers.BigNumber): Promise<ethers.BigNumber> {
  return funds[fundId].previewDeposit(assets);
}

export async function previewMint(fundId: number, shares: ethers.BigNumber): Promise<ethers.BigNumber> {
  return funds[fundId].previewMint(shares);
}

export async function previewRedeem(fundId: number, shares: ethers.BigNumber): Promise<ethers.BigNumber> {
  return funds[fundId].previewRedeem(shares);
}

export async function previewWithdraw(fundId: number, assets: ethers.BigNumber): Promise<ethers.BigNumber> {
  return funds[fundId].previewWithdraw(assets);
}

// export async function redeem(fundId: number, shares: ethers.BigNumber, receiver: string, owner: string){
//   const tx = await fundTransactors[fundId].redeem(shares, receiver, owner);
//   const txReceipt = await tx;
//   const promise = provider.waitForTransaction(txReceipt['hash'], 20, 10 * 60 * 1000);
//   return [txReceipt, promise];
// }

export async function redeemFees(fundId: number, shares: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].redeemFees(shares));
}

export async function redeemWithMinAssets(
  fundId: number,
  shares: ethers.BigNumber,
  receiver: string,
  owner: string,
  minAssetsExpected: ethers.BigNumber,
) {
  return wrapTx(fundTransactors[fundId].redeemWithMinAssets(shares, receiver, owner, minAssetsExpected));
}

export async function redemptionSuspended(fundId: number): Promise<boolean> {
  return funds[fundId].redemptionSuspended();
}

export async function refundRemainder(fundId: number, receiver: string) {
  return wrapTx(fundTransactors[fundId].refundRemainder(receiver));
}

export async function renounceRole(fundId: number, role: string, account: string) {
  return wrapTx(fundTransactors[fundId].renounceRole(role, account));
}

export async function returnAumFromDelegate(fundId: number, amountAumToReturn: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].returnAumFromDelegate(amountAumToReturn));
}

export async function revokeRole(fundId: number, role: string, account: string) {
  return wrapTx(fundTransactors[fundId].revokeRole(role, account));
}

export async function secondsPerCycle(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].secondsPerCycle();
}

export async function shareTaxPolicy(fundId: number): Promise<string> {
  return funds[fundId].shareTaxPolicy();
}

export async function singleAssetUnit(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].singleAssetUnit();
}

export async function singleShareUnit(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].singleShareUnit();
}

export async function skimSlippedAssets(fundId: number, amountOfAssetsToSkim: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].skimSlippedAssets(amountOfAssetsToSkim));
}

export async function slippedAmountCeiling(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].slippedAmountCeiling();
}

export async function subscriptionSuspended(fundId: number): Promise<boolean> {
  return funds[fundId].subscriptionSuspended();
}

export async function supportsInterface(fundId: number, interfaceId: string): Promise<boolean> {
  return funds[fundId].supportsInterface(interfaceId);
}

export async function sweep(fundId: number, unrelatedTokenAddress: string, receiver: string) {
  return wrapTx(fundTransactors[fundId].sweep(unrelatedTokenAddress, receiver));
}

export async function symbol(fundId: number): Promise<string> {
  return funds[fundId].symbol();
}

export async function toggleSuspendRedemption(fundId: number) {
  return wrapTx(fundTransactors[fundId].toggleSuspendRedemption());
}

export async function toggleSuspendSubscription(fundId: number) {
  return wrapTx(fundTransactors[fundId].toggleSuspendSubscription());
}

export async function totalAssets(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].totalAssets();
}

export async function totalSupply(fundId: number): Promise<ethers.BigNumber> {
  return funds[fundId].totalSupply();
}

export async function transfer(fundId: number, to: string, amount: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].transfer(to, amount));
}

export async function transferFeeActive(fundId: number): Promise<boolean> {
  return funds[fundId].transferFeeActive();
}

export async function transferFrom(fundId: number, from: string, to: string, amount: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].transferFrom(from, to, amount));
}

export async function updateBlacklistPolicyAddress(fundId: number, newBlacklistPolicy: string) {
  return wrapTx(fundTransactors[fundId].updateBlacklistPolicyAddress(newBlacklistPolicy));
}

export async function updateCyclicFee(
  fundId: number,
  feeId: ethers.BigNumber,
  feeName: string,
  fxd: ethers.BigNumber,
  pct: ethers.BigNumber,
) {
  return wrapTx(fundTransactors[fundId].updateCyclicFee(feeId, feeName, fxd, pct));
}

export async function updateDyDxDelegate(fundId: number, newDelegate: string) {
  return wrapTx(fundTransactors[fundId].updateDyDxDelegate(newDelegate));
}

export async function updateFeeCollector(fundId: number, newCollector: string) {
  return wrapTx(fundTransactors[fundId].updateFeeCollector(newCollector));
}

export async function updateGlobalMax(fundId: number, limitId: ethers.BigNumber, newLimit: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].updateGlobalMax(limitId, newLimit));
}

export async function updateIndividualMax(
  fundId: number,
  limitId: ethers.BigNumber,
  userAddress: string,
  newLimit: ethers.BigNumber,
) {
  return wrapTx(fundTransactors[fundId].updateIndividualMax(limitId, userAddress, newLimit));
}

export async function updatePerformanceFee(fundId: number, fxd: ethers.BigNumber, pct: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].updatePerformanceFee(fxd, pct));
}

export async function updateRedemptionFee(fundId: number, fxd: ethers.BigNumber, pct: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].updateRedemptionFee(fxd, pct));
}

export async function updateShareTaxPolicyAddress(fundId: number, newShareTaxPolicy: string) {
  return wrapTx(fundTransactors[fundId].updateShareTaxPolicyAddress(newShareTaxPolicy));
}

export async function updateSubscriptionFee(fundId: number, fxd: ethers.BigNumber, pct: ethers.BigNumber) {
  return wrapTx(fundTransactors[fundId].updateSubscriptionFee(fxd, pct));
}

export async function userTxInfoStorage(fundId: number): Promise<string> {
  return funds[fundId].userTxInfoStorage();
}

export async function withdraw(fundId: number, assets: ethers.BigNumber, receiver: string, owner: string) {
  return wrapTx(fundTransactors[fundId].withdraw(assets, receiver, owner));
}

export async function withdrawWithMaxShares(
  fundId: number,
  assets: ethers.BigNumber,
  receiver: string,
  owner: string,
  maxSharesExpected: ethers.BigNumber,
) {
  return wrapTx(fundTransactors[fundId].withdrawWithMaxShares(assets, receiver, owner, maxSharesExpected));
}

// Beginning of TEST USDC function calls.
export async function usdcAllowance(owner: string, spender: string): Promise<ethers.BigNumber> {
  return myerc20.allowance(owner, spender);
}

export async function shareAllowance(owner: string, spender: string, vaultType: VAULT_TYPE): Promise<ethers.BigNumber> {
  return fundSelector[vaultType].VAULT.allowance(owner, spender);
}

export const usdcAllow = txWrapper((spender: string, approveDelta: ethers.BigNumber) =>
  // myerc20Transactor.increaseAllowance(spender, approveDelta),
  myerc20Transactor.approve(spender, ethers.constants.MaxUint256),
);

export const shareAllow = txWrapper((vaultType: VAULT_TYPE, spender: string, approveDelta: ethers.BigNumber) =>
  fundSelector[vaultType].VAULT_TRANSACT.increaseAllowance(spender, approveDelta),
);

export const usdcApprove = txWrapper((spender: string, amount: ethers.BigNumber) =>
  myerc20Transactor.approve(spender, amount),
);
export const shareApprove = txWrapper((vaultType: VAULT_TYPE, spender: string, amount: ethers.BigNumber) =>
  fundSelector[vaultType].VAULT_TRANSACT.approve(spender, amount),
);
// export async function usdcApprove(spender: string, amount: ethers.BigNumber) {
//   return wrapTx(myerc20Transactor.approve(spender, amount));
// }

// export const faucetDrip = txWrapper(() => faucetTransactor.drip());

export async function usdcBalanceOf(account: string): Promise<ethers.BigNumber> {
  return myerc20.balanceOf(account);
}

export async function susdDecimals(): Promise<ethers.BigNumber> {
  return myerc20.decimals();
}

export async function usdcDecreaseAllowance(spender: string, subtractedValue: ethers.BigNumber) {
  return wrapTx(myerc20Transactor.decreaseAllowance(spender, subtractedValue));
}

export async function usdcIncreaseAllowance(spender: string, addedValue: ethers.BigNumber) {
  return wrapTx(myerc20Transactor.increaseAllowance(spender, addedValue));
}

export async function susdName(): Promise<string> {
  return myerc20.name();
}

export async function susdSymbol(): Promise<string> {
  return myerc20.symbol();
}

export async function susdTotalSupply(): Promise<ethers.BigNumber> {
  return myerc20.totalSupply();
}

export async function susdTransfer(to: string, amount: ethers.BigNumber) {
  return wrapTx(myerc20Transactor.transfer(to, amount));
}

export async function susdTransferFrom(from: string, to: string, amount: ethers.BigNumber) {
  return wrapTx(myerc20Transactor.transferFrom(from, to, amount));
}

const DISPLAY_DECS = 2;

export async function vaultSupplies() {
  const res = [
    Number(ethers.utils.formatUnits(await fundSelector.Y_UPDOWN.VAULT.totalSupply(), DECIMALS)).toFixed(DISPLAY_DECS),
    Number(ethers.utils.formatUnits(await fundSelector.Y_NEUTRAL.VAULT.totalSupply(), DECIMALS)).toFixed(DISPLAY_DECS),
    Number(ethers.utils.formatUnits(await fundSelector.Y_PUP.VAULT.totalSupply(), DECIMALS)).toFixed(DISPLAY_DECS),
    Number(ethers.utils.formatUnits(await fundSelector.Y_PDOWN.VAULT.totalSupply(), DECIMALS)).toFixed(DISPLAY_DECS),
  ];

  return res;
}

export async function vaultNumShareHolders() {
  const res = [
    Number(await fundSelector[VAULT_TYPE.Y_UPDOWN].VAULT.numberOfShareHolders()).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    }),
    Number(await fundSelector[VAULT_TYPE.Y_NEUTRAL].VAULT.numberOfShareHolders()).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    }),
    Number(await fundSelector[VAULT_TYPE.Y_PUP].VAULT.numberOfShareHolders()).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    }),
    Number(await fundSelector[VAULT_TYPE.Y_PDOWN].VAULT.numberOfShareHolders()).toLocaleString(undefined, {
      maximumFractionDigits: 0,
    }),
  ];
  // console.log("vaultNumShareHolders done.")
  return res;
}

export async function getFeeInfo() {
  const promises = [
    fundSelector[VAULT_TYPE.Y_UPDOWN].OM.onboardingFeePercentage(),
    fundSelector[VAULT_TYPE.Y_NEUTRAL].OM.onboardingFeePercentage(),
    fundSelector[VAULT_TYPE.Y_PUP].OM.onboardingFeePercentage(),
    fundSelector[VAULT_TYPE.Y_PDOWN].OM.onboardingFeePercentage(),
    fundSelector[VAULT_TYPE.Y_UPDOWN].OM.offboardingFeePercentage(),
    fundSelector[VAULT_TYPE.Y_NEUTRAL].OM.offboardingFeePercentage(),
    fundSelector[VAULT_TYPE.Y_PUP].OM.offboardingFeePercentage(),
    fundSelector[VAULT_TYPE.Y_PDOWN].OM.offboardingFeePercentage(),
  ];

  const res = await Promise.all(promises);

  const onboardingFees = [
    (Number(ethers.utils.formatUnits(res[0], DECIMALS)) * 100).toFixed(DISPLAY_DECS),
    (Number(ethers.utils.formatUnits(res[1], DECIMALS)) * 100).toFixed(DISPLAY_DECS),
    (Number(ethers.utils.formatUnits(res[2], DECIMALS)) * 100).toFixed(DISPLAY_DECS),
    (Number(ethers.utils.formatUnits(res[3], DECIMALS)) * 100).toFixed(DISPLAY_DECS),
  ];

  const offboardingFees = [
    (Number(ethers.utils.formatUnits(res[4], DECIMALS)) * 100).toFixed(DISPLAY_DECS),
    (Number(ethers.utils.formatUnits(res[5], DECIMALS)) * 100).toFixed(DISPLAY_DECS),
    (Number(ethers.utils.formatUnits(res[6], DECIMALS)) * 100).toFixed(DISPLAY_DECS),
    (Number(ethers.utils.formatUnits(res[7], DECIMALS)) * 100).toFixed(DISPLAY_DECS),
  ];

  return [onboardingFees, offboardingFees];
}

export async function bnbGetShareBals() {
  const supBal = await s_up.VAULT.balanceOf(activeUser);
  const ibtcBal = await i_btc.VAULT.balanceOf(activeUser);
  const iethBal = await i_eth.VAULT.balanceOf(activeUser);

  // console.log("%%%%%%%%%", supBal, ibtcBal, iethBal);
  return [
    ethers.utils.formatUnits(supBal, DECIMALS),
    ethers.utils.formatUnits(ibtcBal, DECIMALS),
    ethers.utils.formatUnits(iethBal, DECIMALS),
  ];
}

export interface OverviewData {
  imgSrc: any;
  name: string;
  address: string;
  aum: string;
  inav: string;
  type: string;
  d7: string;
  riskObj: [number, string, any];
  cagr: string;
  calmar: string;
  drawdown: string;
  sharpe: string;
  age: string;
  stake: string;
  rb: string;
  rating: string;
  chain: any;
  strategy: string;
  // selector: any;
}

export async function getShareBals() {
  const updownBal = await fundSelector[VAULT_TYPE.Y_UPDOWN].VAULT.balanceOf(activeUser);
  const neutralBal = await fundSelector[VAULT_TYPE.Y_NEUTRAL].VAULT.balanceOf(activeUser);
  const pupBal = await fundSelector[VAULT_TYPE.Y_PUP].VAULT.balanceOf(activeUser);
  const pdownBal = await fundSelector[VAULT_TYPE.Y_PDOWN].VAULT.balanceOf(activeUser);

  return [
    ethers.utils.formatUnits(updownBal, DECIMALS),
    ethers.utils.formatUnits(neutralBal, DECIMALS),
    ethers.utils.formatUnits(pupBal, DECIMALS),
    ethers.utils.formatUnits(pdownBal, DECIMALS),
  ];
}

export async function getSusdBal() {
  const susdBal = await myerc20.balanceOf(activeUser);
  return ethers.utils.formatUnits(susdBal, DECIMALS);
}

const FB_TIMEOUT = 2000;

async function getWithRetry(url: string) {
  while (true) {
    try {
      const response = await fetch(url);
      return await response.json();
    } catch (e) {
      console.log(e);
      // backoff
      await new Promise((r) => setTimeout(r, FB_TIMEOUT));
    }
  }
}

export interface Portfolio {
  assets: string[];
  assetSymbols: string[];
  values: number[];
  sizes: number[];
  aeps: number[];
  prices: number[];
  flatUsds: number;
  totalShares: number;
  aum: number;
}

export const emptyPortfolio: Portfolio = {
  assets: [],
  assetSymbols: [],
  values: [],
  sizes: [],
  aeps: [],
  prices: [],
  flatUsds: 0,
  totalShares: 0,
  aum: 0,
};

export async function bnbGetPortfolio(): Promise<Portfolio[]> {
  const vaults = [s_up.VAULT, i_btc.VAULT, i_eth.VAULT];
  const res = await bnbHelper.getAllInfo([s_up.VAULT.address, i_btc.VAULT.address, i_eth.VAULT.address]);
  const portfolios: Portfolio[] = [emptyPortfolio, emptyPortfolio, emptyPortfolio];

  for (let i = 0; i < portfolios.length; i++) {
    const spotVaultInfo = res[i];
    const aumInUsd = spotVaultInfo[0];
    const totalShares = await vaults[i].totalSupply();
    const assets = spotVaultInfo[1];
    const symbols = spotVaultInfo[2];
    const prices = spotVaultInfo[3];
    const usdValues = spotVaultInfo[4];
    const sizes = spotVaultInfo[5];
    const decimals = spotVaultInfo[6];
    const refPrices = spotVaultInfo[7];

    let flatUsds = 0;
    for (let j = 0; j < assets.length; j++) {
      if (
        symbols[j] === 'USDC' ||
        symbols[j] === 'USDT'
        // symbols[j] === 'DAI' ||
        // symbols[j] === 'FRAX' ||
        // symbols[j] === 'TUSD'
      ) {
        flatUsds += Number(ethers.utils.formatUnits(usdValues[j], DECIMALS));
      }
    }

    const actualSizes = sizes.map((v: ethers.BigNumber, idx: number) =>
      Number(ethers.utils.formatUnits(v, decimals[idx])),
    );

    portfolios[i] = {
      assets: assets,
      assetSymbols: symbols,
      aum: Number(ethers.utils.formatUnits(aumInUsd, DECIMALS)),
      totalShares: Number(ethers.utils.formatUnits(totalShares, DECIMALS)),
      values: usdValues.map((v: ethers.BigNumber) => Number(ethers.utils.formatUnits(v, DECIMALS))),
      prices: prices.map((v: ethers.BigNumber) => Number(ethers.utils.formatUnits(v, DECIMALS))),
      aeps: refPrices.map((v: ethers.BigNumber) => Number(ethers.utils.formatUnits(v, DECIMALS))),
      sizes: actualSizes,
      flatUsds: flatUsds,
    };
  }

  return portfolios;
}

export async function getPortfolio(): Promise<Portfolio[]> {
  const res = await helper.getMultipleAssetValues(
    [
      fundSelector.Y_UPDOWN.OM.address,
      fundSelector.Y_NEUTRAL.OM.address,
      fundSelector.Y_PUP.OM.address,
      fundSelector.Y_PDOWN.OM.address,
    ],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  );

  const processingStatuses = await getProcessingStatuses();

  // const assets = res[0];
  // const values = res[1];
  // const sizes = res[2];
  // const aeps = res[3];
  // const prices = res[4];
  // const flatUsds = res[5];
  // const totalShares = res[6];

  const portfolios: Portfolio[] = [emptyPortfolio, emptyPortfolio, emptyPortfolio, emptyPortfolio];
  let valuesArray: number[];
  let aum;
  let totalShares;

  for (let i = 0; i < portfolios.length; i++) {
    valuesArray = res.allValues[i].map((value: ethers.BigNumber) => Number(ethers.utils.formatUnits(value, DECIMALS)));
    // console.log("i", i);
    // console.log("====RES2====", res.allAssets[i].map((asset: string) => ADDRESSES.Symbol[asset]));

    aum =
      processingStatuses[i] == 0
        ? valuesArray.reduce((a, b) => a + b, 0) + Number(ethers.utils.formatUnits(res.allFlatUsd[i], DECIMALS))
        : Number(ethers.utils.formatUnits(await navRecords.aums(ADDRESSES.Deployments.Vaults[i].Vault), DECIMALS));

    totalShares =
      processingStatuses[i] == 0
        ? Number(ethers.utils.formatUnits(res.allTotalSupply[i], DECIMALS))
        : Number(ethers.utils.formatUnits(await navRecords.shares(ADDRESSES.Deployments.Vaults[i].Vault), DECIMALS));

    portfolios[i] = {
      assets: res.allAssets[i],
      assetSymbols: res.allAssets[i].map((asset: string) => ADDRESSES.Symbol[asset]),
      values: valuesArray,
      sizes: res.allSizes[i].map((size: ethers.BigNumber) => Number(ethers.utils.formatUnits(size, DECIMALS))),
      aeps: res.allAeps[i].map((aep: ethers.BigNumber) => Number(ethers.utils.formatUnits(aep, DECIMALS))),
      prices: res.allPrices[i].map((price: ethers.BigNumber) => Number(ethers.utils.formatUnits(price, DECIMALS))),
      flatUsds: Number(ethers.utils.formatUnits(res.allFlatUsd[i], DECIMALS)),
      totalShares: totalShares,
      aum: aum,
    };
  }

  return portfolios;
}

export async function getMinUsdc(): Promise<string[]> {
  let res: string[] = [];
  await Promise.all([
    router.minSusdDeposit(y_updown.VAULT.address),
    router.minSusdDeposit(y_neutral.VAULT.address),
    router.minSusdDeposit(y_pup.VAULT.address),
    router.minSusdDeposit(y_pdown.VAULT.address),
  ]).then((values) => {
    res = values.map((value) => Number(ethers.utils.formatUnits(value, DECIMALS)).toFixed(2));
  });
  return res;
}

export async function getMinShares(): Promise<string[]> {
  let res: string[] = [];
  await Promise.all([
    router.minSharesRedeem(y_updown.VAULT.address),
    router.minSharesRedeem(y_neutral.VAULT.address),
    router.minSharesRedeem(y_pup.VAULT.address),
    router.minSharesRedeem(y_pdown.VAULT.address),
  ]).then((values) => {
    res = values.map((value) => Number(ethers.utils.formatUnits(value, DECIMALS)).toFixed(2));
  });
  return res;
}

export interface StatusTableEntry {
  asset: string;
  assetName: string;
  assetValue: number;
  orderValueForIncreasingPositions: number;
  orderSizeForReducingPositions: number;
  keeperFee: number;
  lastSize: number;
  orderedSize: number;
  marginOut: number;
  completion: string;
}

export async function getStatusTableEntries(om: string, isMainnet: boolean): Promise<StatusTableEntry[]> {
  const rpcUrl =
    currentChain.network.toString() == 'hardhat'
      ? hardhat.rpcUrls.default.http.toString()
      : isMainnet
      ? 'https://rpc.ankr.com/optimism/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3'
      : 'https://rpc.ankr.com/optimism_testnet/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3';

  const myProvider: ethers.providers.JsonRpcProvider = new ethers.providers.JsonRpcProvider(rpcUrl, {
    name: currentChain.network.toString() == 'hardhat' ? hardhat.name : optimism.name,
    chainId: currentChain.network.toString() == 'hardhat' ? hardhat.id : optimism.id,
  });

  const symbols = isMainnet ? MainnetItems.Symbol : TestnetItems.Symbol;

  const myHelper = new ethers.Contract(
    currentChain.network.toString() == 'hardhat'
      ? MainnetItems.Deployments.OpsHelper //'0xE0bda45590d342B0bd0FfDdB4384A6095363b881'
      : isMainnet
      ? MainnetItems.Deployments.OpsHelper
      : TestnetItems.Deployments.OpsHelper,
    opsHelperAbi,
    myProvider,
  );

  const promises = [
    myHelper.getAllOrderValuesForIncreasingPositions(om, 0, 0),
    myHelper.getAllOrderSizesForReducingPositions(om, 0, 0),
    myHelper.getAllKeeperFees(om, 0, 0),
    myHelper.getAllLastSizes(om, 0, 0),
    myHelper.getAllOrderedSizes(om, 0, 0),
    myHelper.getAllMarginOuts(om, 0, 0),
    myHelper.getAllCompletion(om, 0, 0),
  ];

  const res = await Promise.all(promises);

  console.log('chain name', currentChain.name, 'chain id', currentChain.id, 'network', currentChain.network.toString());

  const OrderValuesForIncreasingPositions = res[0][1];
  const OrderSizesForReducingPositions = res[1][1];
  const KeeperFees = res[2][1];
  const LastSizes = res[3][1];
  const OrderedSizes = res[4][1];
  const MarginOuts = res[5][1];
  const Completion = res[6][1];
  const Assets = res[0][0];

  const AssetValues = await myHelper.getAssetValues(om, Assets);

  const statusTableEntries: StatusTableEntry[] = [];
  for (let i = 0; i < Assets.length; i++) {
    statusTableEntries.push({
      asset: Assets[i],
      assetName: symbols[Assets[i]],
      assetValue: Number(ethers.utils.formatUnits(AssetValues[i], DECIMALS)),
      orderValueForIncreasingPositions: Number(
        ethers.utils.formatUnits(OrderValuesForIncreasingPositions[i], DECIMALS),
      ),
      orderSizeForReducingPositions: Number(ethers.utils.formatUnits(OrderSizesForReducingPositions[i], DECIMALS)),
      keeperFee: Number(ethers.utils.formatUnits(KeeperFees[i], DECIMALS)),
      lastSize: Number(ethers.utils.formatUnits(LastSizes[i], DECIMALS)),
      orderedSize: Number(ethers.utils.formatUnits(OrderedSizes[i], DECIMALS)),
      marginOut: Number(ethers.utils.formatUnits(MarginOuts[i], DECIMALS)),
      completion: Completion[i].toString(),
    });
  }

  return statusTableEntries;
}

export interface StatusVariable {
  phase: number;
  requestType: any;
  processingLength: number;
  totalAmount: number;
  counter: number;
  aum: number;
  newAum: number;
  amountSpent: number;
  totalSharesToMint: number;
  amountReceived: number;
  completionCounter: number;
  orderEps: number;
  maxOrderValue: number;
  keeperFeeFactor: number;
  fillPriceBuffer: number;
  totalShares: number;
  leverage: number;
}

export async function getStatusVariables(om: string, isMainnet: boolean): Promise<StatusVariable[]> {
  const rpcUrl =
    currentChain.network.toString() == 'hardhat'
      ? hardhat.rpcUrls.default.http.toString()
      : isMainnet
      ? 'https://rpc.ankr.com/optimism/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3'
      : 'https://rpc.ankr.com/optimism_testnet/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3';

  const myProvider: ethers.providers.JsonRpcProvider = new ethers.providers.JsonRpcProvider(rpcUrl, {
    name: currentChain.network.toString() == 'hardhat' ? hardhat.name : optimism.name,
    chainId: currentChain.network.toString() == 'hardhat' ? hardhat.id : optimism.id,
  });

  const myOm = new ethers.Contract(om, opsManagerAbi, myProvider);
  const vaultAddress = await myOm.vault();
  const myVault = new ethers.Contract(vaultAddress, vaultAbi, myProvider);

  const promises = [
    myOm.operationsCache(),
    myOm.orderEps(),
    myOm.maxOrderValue(),
    myOm.keeperFeeFactor(),
    myOm.fillPriceBuffer(),
    myVault.totalSupply(),
    myOm.getLeverageFactor(),
  ];

  const res = await Promise.all(promises);

  const operationsCache = res[0];
  const orderEps = res[1];
  const maxOrderValue = res[2];
  const keeperFeeFactor = res[3];
  const fillPriceBuffer = res[4];
  const totalShares = res[5];
  const leverage = res[6];

  const statusVariables: StatusVariable[] = [
    {
      phase: Number(ethers.utils.formatUnits(operationsCache[0], 0)),
      requestType: operationsCache[1] == 0 ? 'NULL' : operationsCache[1] == 1 ? 'DEPOSIT' : 'REDEEM',
      processingLength: Number(ethers.utils.formatUnits(operationsCache[2], 0)),
      totalAmount: Number(ethers.utils.formatUnits(operationsCache[3], DECIMALS)),
      counter: Number(ethers.utils.formatUnits(operationsCache[4], 0)),
      aum: Number(ethers.utils.formatUnits(operationsCache[5], DECIMALS)),
      newAum: Number(ethers.utils.formatUnits(operationsCache[6], DECIMALS)),
      amountSpent: Number(ethers.utils.formatUnits(operationsCache[7], DECIMALS)),
      totalSharesToMint: Number(ethers.utils.formatUnits(operationsCache[8], DECIMALS)),
      amountReceived: Number(ethers.utils.formatUnits(operationsCache[9], DECIMALS)),
      completionCounter: Number(ethers.utils.formatUnits(operationsCache[10], 0)),
      orderEps: Number(ethers.utils.formatUnits(orderEps, DECIMALS)),
      maxOrderValue: Number(ethers.utils.formatUnits(maxOrderValue, DECIMALS)),
      keeperFeeFactor: Number(ethers.utils.formatUnits(keeperFeeFactor, 0)),
      fillPriceBuffer: Number(ethers.utils.formatUnits(fillPriceBuffer, DECIMALS)),
      totalShares: Number(ethers.utils.formatUnits(totalShares, DECIMALS)),
      leverage: Number(ethers.utils.formatUnits(leverage, DECIMALS)),
    },
  ];

  return statusVariables;
}

export interface PortfolioAsset {
  asset: string;
  assetSymbol: string;
  value: number;
  size: number;
  aep: number;
  price: number;
  flatUsd: number;
  aum: number;
  pct: string;
}

export const emptyPortfolioAsset: PortfolioAsset = {
  asset: '',
  assetSymbol: '',
  value: 0,
  size: 0,
  aep: 0,
  price: 0,
  flatUsd: 0,
  aum: 0,
  pct: '',
};

export async function getStatusPortfolio(om: string, isMainnet: boolean): Promise<PortfolioAsset[]> {
  const rpcUrl =
    currentChain.network.toString() == 'hardhat'
      ? hardhat.rpcUrls.default.http.toString()
      : isMainnet
      ? 'https://rpc.ankr.com/optimism/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3'
      : 'https://rpc.ankr.com/optimism_testnet/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3';

  const myProvider: ethers.providers.JsonRpcProvider = new ethers.providers.JsonRpcProvider(rpcUrl, {
    name: currentChain.network.toString() == 'hardhat' ? hardhat.name : optimism.name,
    chainId: currentChain.network.toString() == 'hardhat' ? hardhat.id : optimism.id,
  });

  const myHelper = new ethers.Contract(
    currentChain.network.toString() == 'hardhat'
      ? MainnetItems.Deployments.OpsHelper
      : isMainnet
      ? MainnetItems.Deployments.OpsHelper
      : TestnetItems.Deployments.OpsHelper,
    opsHelperAbi,
    myProvider,
  );
  const symbols = isMainnet ? MainnetItems.Symbol : TestnetItems.Symbol;
  const pf: PortfolioAsset[] = [];
  const retVal = await myHelper.getAssetValuesForPortfolio(om, 0, 0);

  const values: number[] = retVal[1].map((value: ethers.BigNumber) =>
    Number(ethers.utils.formatUnits(value, DECIMALS)),
  );
  const assets: string[] = retVal[0];
  const assetSymbols: string[] = retVal[0].map((asset: string) => symbols[asset]);
  const sizes: number[] = retVal[2].map((size: ethers.BigNumber) => Number(ethers.utils.formatUnits(size, DECIMALS)));
  const aeps: number[] = retVal[3].map((aep: ethers.BigNumber) => Number(ethers.utils.formatUnits(aep, DECIMALS)));
  const prices: number[] = retVal[4].map((price: ethers.BigNumber) =>
    Number(ethers.utils.formatUnits(price, DECIMALS)),
  );
  const flatUsds = Number(ethers.utils.formatUnits(retVal[5], DECIMALS));
  // const totalShares = Number(ethers.utils.formatUnits(retVal[6], DECIMALS));
  const aum: number = values.reduce((a, b) => a + b, 0) + Number(ethers.utils.formatUnits(retVal[5], DECIMALS));
  const pcts: string[] = values.map((value: number) =>
    (value / aum).toLocaleString(undefined, {
      style: 'percent',
      maximumFractionDigits: 2,
    }),
  );

  for (let i = 0; i < retVal.assets.length; i++) {
    pf.push({
      asset: assets[i],
      assetSymbol: assetSymbols[i],
      value: values[i],
      size: sizes[i],
      aep: aeps[i],
      pct: pcts[i],
      price: prices[i],
      flatUsd: flatUsds,
      aum: aum,
    });
  }

  pf.sort((a, b) => a.assetSymbol.localeCompare(b.assetSymbol));

  if (pf.length === 0) {
    pf.push(emptyPortfolioAsset);
    pf[0].flatUsd = flatUsds;
    pf[0].aum = aum;
  }

  return pf;
}

export interface Position {
  asset: string;
  assetSymbol: string;
  value: number;
  size: number;
  lastPrice: number;
  margin: number;
}

export async function getPositions(om: string, isMainnet: boolean): Promise<Position[]> {
  const rpcUrl =
    currentChain.network.toString() == 'hardhat'
      ? hardhat.rpcUrls.default.http.toString()
      : isMainnet
      ? 'https://rpc.ankr.com/optimism/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3'
      : 'https://rpc.ankr.com/optimism_testnet/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3';

  const myProvider: ethers.providers.JsonRpcProvider = new ethers.providers.JsonRpcProvider(rpcUrl, {
    name: currentChain.network.toString() == 'hardhat' ? hardhat.name : optimism.name,
    chainId: currentChain.network.toString() == 'hardhat' ? hardhat.id : optimism.id,
  });
  const myHelper = new ethers.Contract(
    currentChain.network.toString() == 'hardhat'
      ? MainnetItems.Deployments.OpsHelper
      : isMainnet
      ? MainnetItems.Deployments.OpsHelper
      : TestnetItems.Deployments.OpsHelper,
    opsHelperAbi,
    myProvider,
  );

  const assetReference = isMainnet ? MainnetItems.Assets : TestnetItems.Assets;
  const assets: {
    [key: string]: string;
  } = {};
  for (let i = 0; i < Object.keys(assetReference).length; i++) {
    if (Object.keys(assetReference)[i] != 'SUSD') {
      assets[Object.keys(assetReference)[i]] = Object.values(assetReference)[i];
    }
  }

  const chunk = 10;
  let res: Position[] = [];
  const promises: Promise<any>[] = [];

  for (let i = 0; i < Object.values(assets).length; i += chunk) {
    if (i + chunk > Object.values(assets).length) {
      const chunkAssets = Object.values(assets).slice(i, Object.values(assets).length);
      promises.push(myHelper.getPositions(om, chunkAssets));
      break;
    } else {
      const chunkAssets = Object.values(assets).slice(i, i + chunk);
      promises.push(myHelper.getPositions(om, chunkAssets));
    }
  }

  await Promise.all(promises).then((values) => {
    const temp = values.flat();
    for (let j = 0; j < temp.length; j++) {
      const margin = Number(ethers.utils.formatUnits(temp[j][2], DECIMALS));
      if (margin > 0) {
        const size = Number(ethers.utils.formatUnits(temp[j][4], DECIMALS));
        const lastPrice = Number(ethers.utils.formatUnits(temp[j][3], DECIMALS));
        const value = size * lastPrice;
        res.push({
          asset: Object.values(assets)[j],
          assetSymbol: Object.keys(assets)[j] == 'ONEINCH' ? '1INCH' : Object.keys(assets)[j],
          value: value,
          size: size,
          lastPrice: lastPrice,
          margin: margin,
        });
      }
    }
  });

  res = res.sort((a, b) => a.assetSymbol.localeCompare(b.assetSymbol));
  return res;
}

export interface Order {
  asset: string;
  assetSymbol: string;
  value: number;
  size: number;
  desiredPrice: number;
  keeperFee: number;
  timestamp: string;
}

export async function getOrders(om: string, isMainnet: boolean): Promise<Order[]> {
  const rpcUrl =
    currentChain.network.toString() == 'hardhat'
      ? hardhat.rpcUrls.default.http.toString()
      : isMainnet
      ? 'https://rpc.ankr.com/optimism/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3'
      : 'https://rpc.ankr.com/optimism_testnet/b0f3ec018d2e80410bf774b307449076aaaaa4b008fea18547a64cf0277fb2a3';

  const myProvider: ethers.providers.JsonRpcProvider = new ethers.providers.JsonRpcProvider(rpcUrl, {
    name: currentChain.network.toString() == 'hardhat' ? hardhat.name : optimism.name,
    chainId: currentChain.network.toString() == 'hardhat' ? hardhat.id : optimism.id,
  });

  const myHelper = new ethers.Contract(
    currentChain.network.toString() == 'hardhat'
      ? MainnetItems.Deployments.OpsHelper
      : isMainnet
      ? MainnetItems.Deployments.OpsHelper
      : TestnetItems.Deployments.OpsHelper,
    opsHelperAbi,
    myProvider,
  );
  const assetReference = isMainnet ? MainnetItems.Assets : TestnetItems.Assets;
  const assets: {
    [key: string]: string;
  } = {};
  for (let i = 0; i < Object.keys(assetReference).length; i++) {
    if (Object.keys(assetReference)[i] != 'SUSD') {
      assets[Object.keys(assetReference)[i]] = Object.values(assetReference)[i];
    }
  }

  const chunk = 30;
  let res: Order[] = [];
  const promises: Promise<any>[] = [];

  for (let i = 0; i < Object.values(assets).length; i += chunk) {
    if (i + chunk > Object.values(assets).length) {
      const chunkAssets = Object.values(assets).slice(i, Object.values(assets).length);
      promises.push(myHelper.getDelayedOrders(om, chunkAssets));
      break;
    } else {
      const chunkAssets = Object.values(assets).slice(i, i + chunk);
      promises.push(myHelper.getDelayedOrders(om, chunkAssets));
    }
  }

  await Promise.all(promises).then((values) => {
    const temp = values.flat();
    for (let j = 0; j < temp.length; j++) {
      const size = Number(ethers.utils.formatUnits(temp[j][1], DECIMALS));
      if (size != 0) {
        const desiredPrice = Number(ethers.utils.formatUnits(temp[j][2], DECIMALS));
        const keeperFee = Number(ethers.utils.formatUnits(temp[j][5], DECIMALS));
        const timestamp = new Date(Number(ethers.utils.formatUnits(temp[j][7], 0)) * 1000).toLocaleString();
        const value = size * desiredPrice;
        res.push({
          asset: Object.values(assets)[j],
          assetSymbol: Object.keys(assets)[j] == 'ONEINCH' ? '1INCH' : Object.keys(assets)[j],
          desiredPrice: desiredPrice,
          size: size,
          value: value,
          timestamp: timestamp,
          keeperFee: keeperFee,
        });
      }
    }
  });

  res = res.sort((a, b) => {
    return b.timestamp.localeCompare(a.timestamp);
  });
  return res;
}

export interface OperationsCache {
  phase: number;
  requestType: any;
  processingLength: number;
  totalAmount: number;
  counter: number;
  aum: number;
  newAum: number;
  amountSpent: number;
  totalSharesToMint: number;
  amountReceived: number;
  completionCounter: number;
}

export async function getOpsCache() {
  let res: OperationsCache[] = [];
  await Promise.all([
    y_updown.OM.operationsCache(),
    y_neutral.OM.operationsCache(),
    y_pup.OM.operationsCache(),
    y_pdown.OM.operationsCache(),
  ]).then((values) => {
    res = values.map((value) => {
      return {
        phase: Number(ethers.utils.formatUnits(value[0], 0)),
        requestType: value[1],
        processingLength: Number(ethers.utils.formatUnits(value[2], 0)),
        totalAmount: Number(ethers.utils.formatUnits(value[3], DECIMALS)),
        counter: Number(ethers.utils.formatUnits(value[4], 0)),
        aum: Number(ethers.utils.formatUnits(value[5], DECIMALS)),
        newAum: Number(ethers.utils.formatUnits(value[6], DECIMALS)),
        amountSpent: Number(ethers.utils.formatUnits(value[7], DECIMALS)),
        totalSharesToMint: Number(ethers.utils.formatUnits(value[8], DECIMALS)),
        amountReceived: Number(ethers.utils.formatUnits(value[9], DECIMALS)),
        completionCounter: Number(ethers.utils.formatUnits(value[10], 0)),
      };
    });
  });

  return res;
}

const EPOCH_MS = 1700438400000;

export const CHART_OFFSETS = [0, 26.922 / 100, 139.221 / 100, 214.737 / 100];
export const ACTUAL_DATA_EPOCH_MS = 1700438400000; // 2023-11-20
// export const ACTUAL_DATA_EPOCH_MS = 1705363200000; // 2024-01-16

export const UP_EPOCH_MS = 1656633600000; // 2022-07-01
export const DOWN_NEUTRAL_EPOCH_MS = 1640995200000; // 2022-01-01

export const BNB_ACTUAL_CHART_OFFSETS = [139.221 / 100, 139.221 / 100, 139.221 / 100, 139.221 / 100];
export const BNB_CHART_OFFSETS = [8.09 / 100, 8.09 / 100, 8.09 / 100, 8.09 / 100];
export const BNB_ACTUAL_DATA_EPOCH_MS = 1713860100000; // 2024-04-23 4:15pm

export async function get3MonthHistory() {
  const interval = 1 * 24 * 60 * 60 * 1000;
  const window = 90 * 24 * 60 * 60 * 1000;
  const endTimestamp = Math.ceil((Date.now() - EPOCH_MS) / interval) * interval + EPOCH_MS;
  const startTimestamp = Math.max(endTimestamp - window, UP_EPOCH_MS);
  const allRecords = await navRecords.getMultipleRecords(
    [
      fundSelector.Y_UPDOWN.VAULT.address,
      fundSelector.Y_NEUTRAL.VAULT.address,
      fundSelector.Y_PUP.VAULT.address,
      fundSelector.Y_PDOWN.VAULT.address,
    ],
    [startTimestamp, startTimestamp, startTimestamp, startTimestamp],
    [endTimestamp, endTimestamp, endTimestamp, endTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

export async function bnbGet3MonthHistory() {
  const interval = 1 * 24 * 60 * 60 * 1000;
  const window = 90 * 24 * 60 * 60 * 1000;
  const endTimestamp = Math.ceil((Date.now() - EPOCH_MS) / interval) * interval + EPOCH_MS;
  const startTimestamp = Math.max(endTimestamp - window, UP_EPOCH_MS);
  const allRecords = await bnbNavRecords.getMultipleRecords(
    [s_up.VAULT.address, i_btc.VAULT.address, i_eth.VAULT.address, i_eth.VAULT.address],
    [startTimestamp, startTimestamp, startTimestamp, startTimestamp],
    [endTimestamp, endTimestamp, endTimestamp, endTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

export async function getYearHistory() {
  const interval = 1 * 24 * 60 * 60 * 1000;
  const window = 366 * 24 * 60 * 60 * 1000;
  // const epoch = DOWN_NEUTRAL_EPOCH_MS;
  // const endTimestamp = Math.ceil((Date.now() - epoch) / interval) * interval + epoch;
  // const startTimestamp = Math.max(endTimestamp - window, epoch);

  const upEpoch = UP_EPOCH_MS;
  const upEndTimestamp = Math.ceil((Date.now() - upEpoch) / interval) * interval + upEpoch;
  const upStartTimestamp = Math.max(upEndTimestamp - window, upEpoch);

  const allRecords = await navRecords.getMultipleRecords(
    [
      fundSelector.Y_UPDOWN.VAULT.address,
      fundSelector.Y_NEUTRAL.VAULT.address,
      fundSelector.Y_PUP.VAULT.address,
      fundSelector.Y_PDOWN.VAULT.address,
    ],
    [upStartTimestamp, upStartTimestamp, upStartTimestamp, upStartTimestamp],
    [upEndTimestamp, upEndTimestamp, upEndTimestamp, upEndTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

export async function bnbGetYearHistory() {
  const interval = 1 * 24 * 60 * 60 * 1000;
  const window = 366 * 24 * 60 * 60 * 1000;

  const upEpoch = UP_EPOCH_MS;
  const upEndTimestamp = Math.ceil((Date.now() - upEpoch) / interval) * interval + upEpoch;
  const upStartTimestamp = Math.max(upEndTimestamp - window, upEpoch);

  const allRecords = await bnbNavRecords.getMultipleRecords(
    [s_up.VAULT.address, i_btc.VAULT.address, i_eth.VAULT.address, i_eth.VAULT.address],
    [upStartTimestamp, upStartTimestamp, upStartTimestamp, upStartTimestamp],
    [upEndTimestamp, upEndTimestamp, upEndTimestamp, upEndTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

export async function getAllHistory() {
  const interval = 1 * 24 * 60 * 60 * 1000;
  const epoch = DOWN_NEUTRAL_EPOCH_MS;
  const endTimestamp = Math.ceil((Date.now() - epoch) / interval) * interval + epoch;
  const startTimestamp = epoch;

  const upEpoch = UP_EPOCH_MS;
  const upEndTimestamp = Math.ceil((Date.now() - upEpoch) / interval) * interval + upEpoch;
  const upStartTimestamp = upEpoch;

  const allRecords = await navRecords.getMultipleRecords(
    [
      fundSelector.Y_UPDOWN.VAULT.address,
      fundSelector.Y_NEUTRAL.VAULT.address,
      fundSelector.Y_PUP.VAULT.address,
      fundSelector.Y_PDOWN.VAULT.address,
    ],
    [startTimestamp, startTimestamp, upStartTimestamp, startTimestamp],
    [endTimestamp, endTimestamp, upEndTimestamp, endTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

export async function bnbGetAllHistory() {
  const interval = 1 * 24 * 60 * 60 * 1000;

  const upEpoch = UP_EPOCH_MS;
  const upEndTimestamp = Math.ceil((Date.now() - upEpoch) / interval) * interval + upEpoch;
  const upStartTimestamp = upEpoch;

  const allRecords = await bnbNavRecords.getMultipleRecords(
    [s_up.VAULT.address, i_btc.VAULT.address, i_eth.VAULT.address, i_eth.VAULT.address],
    [upStartTimestamp, upStartTimestamp, upStartTimestamp, upStartTimestamp],
    [upEndTimestamp, upEndTimestamp, upEndTimestamp, upEndTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

export async function getMonthHistory() {
  const interval = 24 * 60 * 60 * 1000;
  const window = 31 * 24 * 60 * 60 * 1000;
  const endTimestamp = Math.ceil((Date.now() - EPOCH_MS) / interval) * interval + EPOCH_MS;
  const startTimestamp = Math.max(endTimestamp - window, EPOCH_MS);
  const allRecords = await navRecords.getMultipleRecords(
    [
      fundSelector.Y_UPDOWN.VAULT.address,
      fundSelector.Y_NEUTRAL.VAULT.address,
      fundSelector.Y_PUP.VAULT.address,
      fundSelector.Y_PDOWN.VAULT.address,
    ],
    [startTimestamp, startTimestamp, startTimestamp, startTimestamp],
    [endTimestamp, endTimestamp, endTimestamp, endTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

export async function bnbGetMonthHistory() {
  const interval = 24 * 60 * 60 * 1000;
  const window = 31 * 24 * 60 * 60 * 1000;
  const endTimestamp = Math.ceil((Date.now() - EPOCH_MS) / interval) * interval + EPOCH_MS;
  const startTimestamp = Math.max(endTimestamp - window, EPOCH_MS);
  const allRecords = await bnbNavRecords.getMultipleRecords(
    [s_up.VAULT.address, i_btc.VAULT.address, i_eth.VAULT.address, i_eth.VAULT.address],
    [startTimestamp, startTimestamp, startTimestamp, startTimestamp],
    [endTimestamp, endTimestamp, endTimestamp, endTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

export async function getWeekHistory() {
  const interval = 1 * 60 * 60 * 1000;
  const endTimestamp = Math.ceil((Date.now() - EPOCH_MS) / interval) * interval + EPOCH_MS;
  const startTimestamp = Math.max(endTimestamp - 7 * 24 * 60 * 60 * 1000, EPOCH_MS);

  const allRecords = await navRecords.getMultipleRecords(
    [
      fundSelector.Y_UPDOWN.VAULT.address,
      fundSelector.Y_NEUTRAL.VAULT.address,
      fundSelector.Y_PUP.VAULT.address,
      fundSelector.Y_PDOWN.VAULT.address,
    ],
    [startTimestamp, startTimestamp, startTimestamp, startTimestamp],
    [endTimestamp, endTimestamp, endTimestamp, endTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

export async function bnbGetWeekHistory() {
  const interval = 1 * 60 * 60 * 1000;
  const endTimestamp = Math.ceil((Date.now() - EPOCH_MS) / interval) * interval + EPOCH_MS;
  const startTimestamp = Math.max(endTimestamp - 7 * 24 * 60 * 60 * 1000, EPOCH_MS);

  const allRecords = await bnbNavRecords.getMultipleRecords(
    [s_up.VAULT.address, i_btc.VAULT.address, i_eth.VAULT.address, i_eth.VAULT.address],
    [startTimestamp, startTimestamp, startTimestamp, startTimestamp],
    [endTimestamp, endTimestamp, endTimestamp, endTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

export async function getDayHistory() {
  const interval = 15 * 60 * 1000;
  const endTimestamp = Math.ceil((Date.now() - EPOCH_MS) / interval) * interval + EPOCH_MS;
  const startTimestamp = endTimestamp - 24 * 60 * 60 * 1000;

  const allRecords = await navRecords.getMultipleRecords(
    [
      fundSelector.Y_UPDOWN.VAULT.address,
      fundSelector.Y_NEUTRAL.VAULT.address,
      fundSelector.Y_PUP.VAULT.address,
      fundSelector.Y_PDOWN.VAULT.address,
    ],
    [startTimestamp, startTimestamp, startTimestamp, startTimestamp],
    [endTimestamp, endTimestamp, endTimestamp, endTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

export async function bnbGetDayHistory() {
  const interval = 15 * 60 * 1000;
  const endTimestamp = Math.ceil((Date.now() - EPOCH_MS) / interval) * interval + EPOCH_MS;
  const startTimestamp = endTimestamp - 24 * 60 * 60 * 1000;

  const allRecords = await bnbNavRecords.getMultipleRecords(
    [s_up.VAULT.address, i_btc.VAULT.address, i_eth.VAULT.address, i_eth.VAULT.address],
    [startTimestamp, startTimestamp, startTimestamp, startTimestamp],
    [endTimestamp, endTimestamp, endTimestamp, endTimestamp],
    [interval, interval, interval, interval],
  );

  const res = [];
  for (let i = 0; i < 4; i++) {
    res.push([allRecords[0][i], allRecords[1][i]]);
  }

  return res;
}

// export async function getAllHistory() {
//   return getWithRetry(`${firebaseUrl}/getAllHistory`);
// }

// export async function getCycleStartData() {
//   return getWithRetry(`${firebaseUrl}/getCycleStartData`);
// }

function formatPos(vaultObj: any, cycleStartData: any) {
  const formatted: { asset: string; size: string; price: string; value: string; pnl: string; pct: string }[] = [];
  const positions = vaultObj.positionInfo;
  const total = vaultObj.equity;
  // Object.keys(positions).forEach( (key) => {
  //   total += Math.abs(Number(positions[key].size) * Number(positions[key].price))
  // })

  const csData = cycleStartData[0]['referenceData'];

  Object.keys(positions).forEach((key) => {
    const refData = csData[key];
    // console.log(refData)
    formatted.push({
      asset: key.split('-')[0],
      size: Number(positions[key].size).toLocaleString(undefined, { maximumFractionDigits: 3 }),
      price: Number(positions[key].price).toLocaleString(undefined, { maximumFractionDigits: 3 }),
      value: Number(positions[key].usdValue).toLocaleString(undefined, { maximumFractionDigits: 2 }),
      pnl: (Number(positions[key].size) * (Number(positions[key].price) - Number(refData.averagePrice))).toLocaleString(
        undefined,
        { maximumFractionDigits: 2 },
      ),
      pct: ((100 * Number(positions[key].usdValue)) / total).toLocaleString(undefined, { maximumFractionDigits: 3 }),
    });
  });

  formatted.sort(function (a, b) {
    return Number(b.pct) - Number(a.pct);
  });
  return formatted;
}

// export async function getLogs() {
//   return getWithRetry(`${firebaseUrl}/getLogs`);
// }

// async function getUsersAndBals(vault: ethers.Contract) {
//   const numberOfShareHolders = await vault.numberOfShareHolders();
//   const shareHolders = await vault.getShareHolders(0, numberOfShareHolders);
//   const balPromises: Promise<BigNumber>[] = shareHolders.map((x: any) => {
//     return vault.balanceOf(x);
//   });
//   const usersBals: USERS_BALS[] = [];
//   await Promise.all(balPromises).then((res) => {
//     res.forEach((curr, idx) => {
//       usersBals.push({ user: shareHolders[idx], bal: ethers.utils.formatUnits(curr, DECIMALS) });
//     });
//   });
//
//   // console.log("getUsersAndBals done.")
//
//   return usersBals;
// }

// export async function getAllUsersAndBals() {
//   return [
//     // await getUsersAndBals(y_updown.VAULT),
//     // await getUsersAndBals(y_neutral.VAULT),
//     // await getUsersAndBals(y_pup.VAULT),
//     // await getUsersAndBals(y_pdown.VAULT),
//   ];
// }

export interface MarketFeed {
  symbol: string;
  price: string;
}

const EXCLUDE_LIST: Array<string> = ['AUD', 'GBP', 'STETHETH', 'ETHBTC', 'PEPE', 'XAU', 'XAG', 'USDT', 'EUR', 'SUSD'];

export async function getMarketFeed(): Promise<MarketFeed[]> {
  const temp = ADDRESSES.Assets;

  const assets = [];

  for (let i = 0; i < Object.keys(temp).length; i++) {
    if (EXCLUDE_LIST.includes(Object.keys(temp)[i])) {
      continue;
    }
    assets.push(Object.values(temp)[i]);
  }

  // const assets = Object.values(temp).slice(3);
  const chunk = 10;
  let prices: ethers.BigNumber[] = [];

  for (let i = 0; i < assets.length; i += chunk) {
    if (i + chunk > assets.length) {
      const pr = await helper.getAssetPrices(assets.slice(i, assets.length));
      prices = prices.concat(pr);
    } else {
      const pr = await helper.getAssetPrices(assets.slice(i, i + chunk));
      prices = prices.concat(pr);
    }
  }

  // const prices = await helper.getAssetPrices(assets);
  const symbols = assets.map((a) => ADDRESSES.Symbol[a]);
  const feed: MarketFeed[] = [];
  for (let i = 0; i < assets.length; i++) {
    const sym = symbols[i];
    if (EXCLUDE_LIST.includes(sym)) continue;
    feed.push({
      symbol: sym,
      price: Number(ethers.utils.formatUnits(prices[i], DECIMALS)).toLocaleString(undefined, {
        maximumSignificantDigits: 4,
      }),
    });
  }

  feed.sort(function (a, b) {
    return a.symbol.localeCompare(b.symbol);
  });

  return feed;
}

export interface Request {
  requestType: number;
  user: number;
  requestAmount: number;
  requestFeeInUsd: number;
  requestTimestamp: number;
  processedTimestamp: number;
  processedAmount: number;
  // totalRequestedAmount: number;
}

export interface CompletedRequests {
  requestType: number;
  user: number;
  requestAmount: number;
  requestFeeInUsd: number;
  requestTimestamp: number;
  processedTimestamp: number;
  processedAmount: number;
  vaultAddress: string;
  vaultName: string;
  requestTypeStr: string;
  currencyIn: string;
  currencyOut: string;
}

export async function getPendingDeposits(): Promise<Request[]> {
  const res = await helper.getUserPendingDepositRequests(
    [y_updown.OM.address, y_neutral.OM.address, y_pup.OM.address, y_pdown.OM.address],
    activeUser,
  );

  return res.map((x: Array<never>) => {
    return {
      requestType: x[0],
      user: x[1],
      requestAmount: Number(ethers.utils.formatUnits(x[2], DECIMALS)),
      requestFeeInUsd: Number(ethers.utils.formatUnits(x[3], DECIMALS)),
      requestTimestamp: Number(ethers.utils.formatUnits(x[4], 0)),
      processedTimestamp: Number(ethers.utils.formatUnits(x[5], 0)),
      processedAmount: Number(ethers.utils.formatUnits(x[6], DECIMALS)),
      // totalRequestedAmount:
      //   Number(ethers.utils.formatUnits(x[2], DECIMALS)) + Number(ethers.utils.formatUnits(x[3], DECIMALS)),
    };
  });
}

export async function getPendingRedeems(): Promise<Request[]> {
  const res = await helper.getUserPendingRedeemRequests(
    [y_updown.OM.address, y_neutral.OM.address, y_pup.OM.address, y_pdown.OM.address],
    activeUser,
  );

  return res.map((x: Array<never>) => {
    return {
      requestType: x[0],
      user: x[1],
      requestAmount: Number(ethers.utils.formatUnits(x[2], DECIMALS)),
      requestFeeInUsd: Number(ethers.utils.formatUnits(x[3], DECIMALS)),
      requestTimestamp: Number(ethers.utils.formatUnits(x[4], 0)),
      processedTimestamp: Number(ethers.utils.formatUnits(x[5], 0)),
      processedAmount: Number(ethers.utils.formatUnits(x[6], DECIMALS)),
    };
  });
}

export async function getCompletedRequests(): Promise<CompletedRequests[]> {
  const vaults = [y_neutral.OM.address, y_pup.OM.address, y_pdown.OM.address];
  const res = await helper.getMultipleCompletedDepositRequests(
    vaults,
    [activeUser, activeUser, activeUser],
    [0, 0, 0],
    [0, 0, 0],
  );

  const mapped = res.map((x: Array<never>) => {
    return x.map((y: Array<never>) => {
      return {
        requestType: y[0],
        user: y[1],
        requestAmount: Number(ethers.utils.formatUnits(y[2], DECIMALS)),
        requestFeeInUsd: Number(ethers.utils.formatUnits(y[3], DECIMALS)),
        requestTimestamp: Number(ethers.utils.formatUnits(y[4], 0)),
        processedTimestamp: Number(ethers.utils.formatUnits(y[5], 0)),
        processedAmount: Number(ethers.utils.formatUnits(y[6], DECIMALS)),
      };
    });
  });

  const returnArray: CompletedRequests[] = [];
  for (let i = 0; i < mapped.length; i++) {
    const vaultAddress = vaults[i];
    const vaultName =
      // vaultAddress === y_updown.OM.address
      //   ? 'Y-Updown'
      //   :
      vaultAddress === y_neutral.OM.address ? 'Y-Neutral' : vaultAddress === y_pup.OM.address ? 'Y-Up' : 'Y-Down';

    for (let j = 0; j < mapped[i].length; j++) {
      const request = mapped[i][j];
      returnArray.push({
        ...request,
        vaultAddress: vaultAddress,
        vaultName: vaultName,
        requestTypeStr: 'Deposit',
        currencyIn: 'sUSD',
        currencyOut: vaultName,
      });
    }
  }

  const redeems = await helper.getMultipleCompletedRedeemRequests(
    vaults,
    [activeUser, activeUser, activeUser],
    [0, 0, 0],
    [0, 0, 0],
  );

  const redeemMapped = redeems.map((x: Array<never>) => {
    return x.map((y: Array<never>) => {
      return {
        requestType: y[0],
        user: y[1],
        requestAmount: Number(ethers.utils.formatUnits(y[2], DECIMALS)),
        requestFeeInUsd: Number(ethers.utils.formatUnits(y[3], DECIMALS)),
        requestTimestamp: Number(ethers.utils.formatUnits(y[4], 0)),
        processedTimestamp: Number(ethers.utils.formatUnits(y[5], 0)),
        processedAmount: Number(ethers.utils.formatUnits(y[6], DECIMALS)),
      };
    });
  });

  for (let i = 0; i < redeemMapped.length; i++) {
    const vaultAddress = vaults[i];
    const vaultName =
      // vaultAddress === y_updown.OM.address
      //   ? 'Y-Updown'
      //   :
      vaultAddress === y_neutral.OM.address ? 'Y-Neutral' : vaultAddress === y_pup.OM.address ? 'Y-Up' : 'Y-Down';

    for (let j = 0; j < redeemMapped[i].length; j++) {
      const request = redeemMapped[i][j];
      returnArray.push({
        ...request,
        vaultAddress: vaultAddress,
        vaultName: vaultName,
        requestTypeStr: 'Redeem',
        currencyIn: vaultName,
        currencyOut: 'sUSD',
      });
    }
  }

  returnArray.sort((a, b) => {
    return b.processedTimestamp - a.processedTimestamp;
  });

  return returnArray;
}

export async function getProcessingStatuses() {
  const res = await helper.isRotationOrProcessing([
    fundSelector.Y_UPDOWN.OM.address,
    fundSelector.Y_NEUTRAL.OM.address,
    fundSelector.Y_PUP.OM.address,
    fundSelector.Y_PDOWN.OM.address,
  ]);

  const formatted = res.map((x: BigNumber) => {
    return x.toNumber();
  });

  return formatted;
}

/* SPOT VAULTS */
const spotLen = 3;

export interface SpotAllocations {
  aum: number;
  assets: string[];
  prices: number[];
  usdValues: number[];
  percentages: number[];
}

export const EMPTY_SPOT_ALLOCATIONS: SpotAllocations = {
  aum: 0,
  assets: [],
  prices: [],
  usdValues: [],
  percentages: [],
};

export async function getSpotAllocations(): Promise<SpotAllocations[]> {
  const promises = [s_up.VAULT, i_btc.VAULT, i_eth.VAULT].map((x) => {
    return x.getAllocations();
  });
  const res = await Promise.all(promises);

  const ret = res.map((x: Array<any>) => {
    const aum = Number(ethers.utils.formatUnits(x[0], DECIMALS));
    const assets = x[1];
    const prices = x[2].map((y: BigNumber) => {
      return Number(ethers.utils.formatUnits(y, DECIMALS));
    });
    const usdValues = x[3].map((y: BigNumber) => {
      return Number(ethers.utils.formatUnits(y, DECIMALS));
    });
    const percentages = usdValues.map((y: number) => {
      return y / aum;
    });

    return {
      aum: aum,
      assets: assets,
      prices: prices,
      usdValues: usdValues,
      percentages: percentages,
    };
  });
  return ret;
}

export async function getSpotNavs(): Promise<number[]> {
  const promises = [s_up.VAULT, i_btc.VAULT, i_eth.VAULT].map((x) => {
    return x.getNav();
  });
  const res = await Promise.all(promises);

  const formattedNavs = res.map((x: BigNumber) => {
    return Number(ethers.utils.formatUnits(x, DECIMALS));
  });

  return formattedNavs;
}

export async function getSpotShareSupply(): Promise<number[]> {
  const promises = [s_up.VAULT, i_btc.VAULT, i_eth.VAULT].map((x) => {
    return x.totalSupply();
  });
  const res = await Promise.all(promises);

  const formattedShareSupply = res.map((x: BigNumber) => {
    return Number(ethers.utils.formatUnits(x, DECIMALS));
  });

  return formattedShareSupply;
}

export async function getSpotBalanceERC20(tokenAddress: string): Promise<string> {
  if (!activeUser) {
    _setActiveUser();
  }
  const erc20 = new ethers.Contract(tokenAddress, myerc20Abi, bscProvider);
  const balance = await erc20.balanceOf(activeUser);
  const decimals = await erc20.decimals();
  const formatted = ethers.utils.formatUnits(balance, decimals);
  console.log('formatted %%%%%', formatted, tokenAddress, await erc20.symbol());
  return formatted;
}

export async function getSpotBalanceBnb(): Promise<string> {
  _setActiveUser();
  const balance = await bscProvider.getBalance(activeUser);
  const formatted = ethers.utils.formatUnits(balance, DECIMALS);
  console.log('formatted %%%%%', formatted);
  return formatted;
}

export async function getSpotVaultBalance(vaultSelector: number): Promise<string> {
  _setActiveUser();

  const vault = [s_up.VAULT, i_btc.VAULT, i_eth.VAULT][vaultSelector];
  const balance = await vault.balanceOf(activeUser);
  const formatted = ethers.utils.formatUnits(balance, DECIMALS);
  console.log('spot vault balance formatted %%%%%', formatted);
  return formatted;
}

export async function getSpotVaultAllowance(vaultSelector: number): Promise<ethers.BigNumber> {
  _setActiveUser();

  const vault = [s_up.VAULT, i_btc.VAULT, i_eth.VAULT][vaultSelector];
  return await vault.allowance(activeUser, vault.address);
}

export async function getSpotVaultApproveStatus(vaultSelector: number, amount: ethers.BigNumber): Promise<boolean> {
  const allowance = await getSpotVaultAllowance(vaultSelector);
  // console.log('%%%%% spot vault allowance', allowance.toString(), amount.toString());
  return allowance.gte(amount);
}

export async function getDiscountApproveStatus(vaultAddress: string, useDiscount: boolean): Promise<boolean> {
  let discountApproved = true;
  if (useDiscount) {
    const discountAllowance = await discountToken.VAULT.allowance(activeUser, vaultAddress);
    discountApproved = discountAllowance.gte(ethers.constants.MaxUint256);
  }
  return discountApproved;
}

export async function getTokenApproveStatus(
  vaultAddress: string,
  tokenAddress: string,
  tokenAmount: ethers.BigNumber,
): Promise<boolean> {
  if (tokenAddress.toLowerCase() === BNB_ASSET_ADDRESSES.BNB.toLowerCase()) {
    return true;
  }
  _setActiveUser();
  const erc20 = new ethers.Contract(tokenAddress, myerc20Abi, bscProvider);
  const allowance = await erc20.allowance(activeUser, vaultAddress);
  return allowance.gte(tokenAmount);
}

export const discountTokenAllow = bscWrapper((spender: string) =>
  discountToken.VAULT_TRANSACT.approve(spender, ethers.constants.MaxUint256),
);

export const tokenAllow = bscWrapper((spender: string, tokenAddress: string) => {
  const erc20 = new ethers.Contract(tokenAddress, myerc20Abi, bscProvider);
  const connectedErc20 = erc20.connect(activeSigner);
  return connectedErc20.approve(spender, ethers.constants.MaxUint256);
});

export const spotShareAllow = bscWrapper((vaultSelector: number) => {
  const vault = [s_up.VAULT, i_btc.VAULT, i_eth.VAULT][vaultSelector];
  return vault.approve(vault.address, ethers.constants.MaxUint256);
});

const BACKEND_URL = `https://us-central1-yiedl-production.cloudfunctions.net`;

export interface DepositResponse {
  amountDec: string;
  assetAddress: string;
  estMinShares: string;
  feeSwapData: string;
  pcList: string[];
  sharesToMint: string;
  useDiscount: boolean;
  swapAmts: ethers.BigNumber[];
}

export interface RedeemResponse {
  estMinRcvToken: string;
  pcList: string[];
  rcvAddress: string;
  shareAmountDec: string;
  tokensToReturn: string;
  useDiscount: boolean;
}

export async function callDepositNative(
  vault_address: string,
  amount_dec: string,
  use_discount: boolean,
): Promise<DepositResponse> {
  const depositUrl = `${BACKEND_URL}/depositNative`;

  const params = {
    vaultAddress: vault_address,
    amountDec: amount_dec,
    useDiscount: use_discount.toString(),
    senderAddress: activeUser,
  };

  // console.log('%%%%%% params', params);

  const url = new URL(depositUrl);
  url.search = new URLSearchParams(params).toString();

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const resp = await response.json();
    // console.log('%%%%%% resp', resp);
    return {
      amountDec: ethers.utils.formatUnits(ethers.BigNumber.from(resp.amountInt), DECIMALS),
      assetAddress: resp.assetAddress,
      estMinShares: ethers.utils.formatUnits(ethers.BigNumber.from(resp.estMinShares), DECIMALS),
      feeSwapData: resp.feeSwapData,
      pcList: resp.pcList,
      sharesToMint: ethers.utils.formatUnits(ethers.BigNumber.from(resp.sharesToMint), DECIMALS),
      useDiscount: resp.useDiscount,
      swapAmts: resp.swapAmts.map((x: string) => ethers.BigNumber.from(x)),
    };
  } catch (error) {
    console.error('Error:', error);
    return {
      amountDec: '0',
      assetAddress: '',
      estMinShares: '0',
      feeSwapData: '',
      pcList: [],
      sharesToMint: '0',
      useDiscount: false,
      swapAmts: [],
    };
  }
}

export async function callRedeem(
  vault_address: string,
  share_amount_dec: string,
  rcv_address: string,
  use_discount: boolean,
): Promise<RedeemResponse> {
  const redeemUrl = `${BACKEND_URL}/redeem`;
  const params = {
    vaultAddress: vault_address,
    shareAmountDec: share_amount_dec,
    rcvAddress: rcv_address,
    useDiscount: use_discount.toString(),
    senderAddress: activeUser,
  };

  // console.log('%%%%%% redeem params', params);

  const url = new URL(redeemUrl);
  url.search = new URLSearchParams(params).toString();

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const resp = await response.json();
    // console.log('%%%%%% redeem resp', resp);
    return {
      estMinRcvToken: ethers.utils.formatUnits(ethers.BigNumber.from(resp.estMinRcvToken), DECIMALS),
      pcList: resp.pcList,
      rcvAddress: resp.rcvAddress,
      shareAmountDec: ethers.utils.formatUnits(ethers.BigNumber.from(resp.shareAmountInt), DECIMALS),
      tokensToReturn: ethers.utils.formatUnits(ethers.BigNumber.from(resp.tokensToReturn), DECIMALS),
      useDiscount: resp.useDiscount,
    };
  } catch (error) {
    console.error('Failed to fetch:', error);
    return {
      estMinRcvToken: '-1',
      pcList: [],
      rcvAddress: '',
      shareAmountDec: '',
      tokensToReturn: '',
      useDiscount: false,
    };
  }

  // return {
  //   estMinRcvToken: '',
  //   pcList: [],
  //   rcvAddress: '',
  //   shareAmountDec: '',
  //   tokensToReturn: '',
  //   useDiscount: false,
  // };
}

export async function callDeposit(
  vault_address: string,
  asset_address: string,
  amount_dec: string,
  use_discount: boolean,
): Promise<DepositResponse> {
  const depositUrl = `${BACKEND_URL}/deposit`;
  const params = {
    vaultAddress: vault_address,
    assetAddress: asset_address,
    amountDec: amount_dec,
    useDiscount: use_discount.toString(),
    senderAddress: activeUser,
  };

  // console.log('%%%%%% params', params);

  const url = new URL(depositUrl);
  url.search = new URLSearchParams(params).toString();

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const resp = await response.json();
    // console.log('%%%%%% resp', resp);
    return {
      amountDec: ethers.utils.formatUnits(ethers.BigNumber.from(resp.amountInt), DECIMALS),
      assetAddress: resp.assetAddress,
      estMinShares: ethers.utils.formatUnits(ethers.BigNumber.from(resp.estMinShares), DECIMALS),
      feeSwapData: resp.feeSwapData,
      pcList: resp.pcList,
      sharesToMint: ethers.utils.formatUnits(ethers.BigNumber.from(resp.sharesToMint), DECIMALS),
      useDiscount: resp.useDiscount,
      swapAmts: [],
    };
  } catch (error) {
    console.error('Failed to fetch:', error);
    return {
      amountDec: '',
      assetAddress: '',
      estMinShares: '-1',
      feeSwapData: '',
      pcList: [],
      sharesToMint: '',
      useDiscount: false,
      swapAmts: [],
    };
  }

  // return {
  //   amountDec: '',
  //   assetAddress: '',
  //   estMinShares: '',
  //   feeSwapData: '',
  //   pcList: [],
  //   sharesToMint: '',
  //   useDiscount: false,
  //   swapAmts: [],
  // };
}

export const spotRedeem = bscWrapper(
  async (vaultSelector: number, res: RedeemResponse) => {
    const vault = [s_up.VAULT_TRANSACT, i_btc.VAULT_TRANSACT, i_eth.VAULT_TRANSACT][vaultSelector];
    const estimatedGas = await vault.estimateGas.redeem(
      ethers.utils.parseUnits(res.shareAmountDec, DECIMALS),
      res.rcvAddress,
      ethers.utils.parseUnits(res.estMinRcvToken, DECIMALS),
      res.pcList,
      res.useDiscount,
    );
    const gasLimit = estimatedGas.mul(120).div(100);
    return vault.redeem(
      ethers.utils.parseUnits(res.shareAmountDec, DECIMALS),
      res.rcvAddress,
      ethers.utils.parseUnits(res.estMinRcvToken, DECIMALS),
      res.pcList,
      res.useDiscount,
      { gasLimit },
    );
  },
  3,
  10 * 60 * 1000,
);

export const spotDeposit = bscWrapper(
  async (vaultSelector: number, res: DepositResponse) => {
    const vault = [s_up.VAULT_TRANSACT, i_btc.VAULT_TRANSACT, i_eth.VAULT_TRANSACT][vaultSelector];
    const estimatedGas = await vault.estimateGas.deposit(
      res.assetAddress,
      ethers.utils.parseUnits(res.amountDec, DECIMALS),
      ethers.utils.parseUnits(res.estMinShares, DECIMALS),
      res.pcList,
      res.feeSwapData,
      res.useDiscount,
    );
    const gasLimit = estimatedGas.mul(120).div(100);

    return vault.deposit(
      res.assetAddress,
      ethers.utils.parseUnits(res.amountDec, DECIMALS),
      ethers.utils.parseUnits(res.estMinShares, DECIMALS),
      res.pcList,
      res.feeSwapData,
      res.useDiscount,
      { gasLimit },
    );
  },

  3,
  10 * 60 * 1000,
);

export const spotDepositNative = bscWrapper(
  async (vaultSelector: number, res: DepositResponse) => {
    // console.log(
    //   '%%%%%% depositNative',
    //   vaultSelector,
    //   ethers.utils.parseUnits(res.estMinShares, DECIMALS).toString(),
    //   res.pcList,
    //   res.swapAmts.map((x) => x.toString()),
    //   res.feeSwapData.toString(),
    //   res.useDiscount,
    //   { value: ethers.utils.parseUnits(res.amountDec, DECIMALS).toString() },
    // );

    const vault = [s_up.VAULT_TRANSACT, i_btc.VAULT_TRANSACT, i_eth.VAULT_TRANSACT][vaultSelector];
    const estimatedGas = await vault.estimateGas.depositNative(
      ethers.utils.parseUnits(res.estMinShares, DECIMALS),
      res.pcList,
      res.swapAmts,
      res.feeSwapData,
      res.useDiscount,
      { value: ethers.utils.parseUnits(res.amountDec, DECIMALS) },
    );
    const gasLimit = estimatedGas.mul(120).div(100);

    return vault.depositNative(
      ethers.utils.parseUnits(res.estMinShares, DECIMALS),
      res.pcList,
      res.swapAmts,
      res.feeSwapData,
      res.useDiscount,
      {
        value: ethers.utils.parseUnits(res.amountDec, DECIMALS),
        gasLimit,
      },
    );
  },

  3,
  10 * 60 * 1000,
);
