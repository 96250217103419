import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import MedicalDashboardPage from '@app/pages/DashboardPages/MedicalDashboardPage';
import VaultsDashboardPage from '@app/pages/DashboardPages/VaultsDashboardPage';
import SpotDashboardPage from '@app/pages/DashboardPages/SpotDashboardPage';

const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));
const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));
const TransactionsPage = React.lazy(() => import('@app/pages/TransactionsPage'));
const StatusPage = React.lazy(() => import('@app/pages/StatusPage'));
const OverviewPage = React.lazy(() => import('@app/pages/OverviewPage'));
const MyPortfolioPage = React.lazy(() => import('@app/pages/MyPortfolioPage'));
const LeaderboardPage = React.lazy(() => import('@app/pages/LeaderboardPage'));
const AirdropPage = React.lazy(() => import('@app/pages/AirdropPage'));
const AboutCompetitionPage = React.lazy(() => import('@app/pages/AboutCompetitionPage'));
const TermsOfUsePage = React.lazy(() => import('@app/pages/TermsOfUse'));
const PrivacyPolicyPage = React.lazy(() => import('@app/pages/PrivacyPolicy'));

// import SwapPage from '@app/pages/SwapPage';
import {
  CompletedRequests,
  OperationsCache,
  OverviewData,
  Portfolio,
  Request,
  SpotAllocations,
} from '@app/lib/contracts';

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/vaults';

const VaultsDashboard = VaultsDashboardPage;
const SpotDashboard = SpotDashboardPage;
const MedicalDashboard = withLoading(MedicalDashboardPage);
const NftDashboard = withLoading(NftDashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
const AdvancedForm = withLoading(AdvancedFormsPage);

const Transactions = withLoading(TransactionsPage);
const Status = withLoading(StatusPage);
const Overview = withLoading(OverviewPage);
const MyPortfolio = withLoading(MyPortfolioPage);
// const Swap = withLoading(SwapPage);
const Leaderboard = withLoading(LeaderboardPage);
const Airdrop = withLoading(AirdropPage);
const AboutCompetition = withLoading(AboutCompetitionPage);
const TermsOfUse = withLoading(TermsOfUsePage);
const PrivacyPolicy = withLoading(PrivacyPolicyPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);

const DataTables = withLoading(DataTablesPage);
const Charts = withLoading(ChartsPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export interface IAppRouterProps {
  dayHistories: Array<Array<Array<{ x: number; y: number }>>>;
  weekHistories: Array<Array<Array<{ x: number; y: number }>>>;
  monthHistories: Array<Array<Array<{ x: number; y: number }>>>;
  month3Histories: Array<Array<Array<{ x: number; y: number }>>>;
  yearHistories: Array<Array<Array<{ x: number; y: number }>>>;
  allHistories: Array<Array<Array<{ x: number; y: number }>>>;

  bnb_dayHistories: Array<Array<Array<{ x: number; y: number }>>>;
  bnb_weekHistories: Array<Array<Array<{ x: number; y: number }>>>;
  bnb_monthHistories: Array<Array<Array<{ x: number; y: number }>>>;
  bnb_month3Histories: Array<Array<Array<{ x: number; y: number }>>>;
  bnb_yearHistories: Array<Array<Array<{ x: number; y: number }>>>;
  bnb_allHistories: Array<Array<Array<{ x: number; y: number }>>>;

  spinning: boolean;
  aums: Array<number>;
  connected: boolean;
  vaultSelected: number;
  inavList: Array<number>;
  usdcBal: string;
  shareBals: Array<string>;
  pendingDepositState: number;
  pendingWithdrawState: number;
  walletLoading: boolean;
  processedRecords: Array<never>;
  requestRecords: Array<never>;
  selectUpdown: any;
  selectNeutral: any;
  selectUp: any;
  selectDown: any;
  info: Record<string, unknown>;
  shareSupply: Array<number>;
  numShareholders: Array<string>;
  minUsdc: Array<string>;
  minShares: Array<string>;
  maxUsdc: Array<string>;
  marketFeed: Array<any>;
  portfolios: Array<Portfolio | null>;
  bnbPortfolios: Array<Portfolio | null>;
  pendingDeposit: Array<Request>;
  pendingRedeem: Array<Request>;
  completedRequests: Array<CompletedRequests>;
  operationsCache: Array<OperationsCache | null>;
  onboardingFees: Array<string>;
  offboardingFees: Array<string>;
  orderFees: Array<string>;
  userDepositsUnderProcessing: Array<boolean>;
  userRedeemsUnderProcessing: Array<boolean>;
  processingStatus: Array<number>;
  tripleSelected: number;
  selectSwap: any;
  selectMint: any;
  selectRedeem: any;
  spotAllocations: Array<SpotAllocations>;
  spotNavs: Array<number>;

  selectSUp: any;
  selectIBtc: any;
  selectIEth: any;

  spotSelected: number;
  spotShareSupply: Array<number>;

  overviewData: Array<OverviewData>;
  // nextWindow: number;
}

export const AppRouter: React.FC<IAppRouterProps> = (props: IAppRouterProps) => {
  return (
    <BrowserRouter>
      <Routes>
        {/*<Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>*/}
        <Route
          path={NFT_DASHBOARD_PATH}
          element={
            <RequireAuth>
              <MainLayout
                marketFeed={props.marketFeed}
                connected={props.connected}
                // nextWindow={props.nextWindow}
              />
            </RequireAuth>
          }
        >
          {/*<Route index element={<NftDashboard />} />*/}
          <Route
            // path={'/overview'}
            index
            element={
              <Overview
                // aums={props.aums}
                // dayHistories={props.dayHistories}
                // weekHistories={props.weekHistories}
                // monthHistories={props.monthHistories}
                // month3Histories={props.month3Histories}
                // yearHistories={props.yearHistories}
                // allHistories={props.allHistories}
                // inavList={props.inavList}
                loading={props.spinning}
                selectUpdown={props.selectUpdown}
                selectNeutral={props.selectNeutral}
                selectUp={props.selectUp}
                selectDown={props.selectDown}
                selectSUp={props.selectSUp}
                selectIBtc={props.selectIBtc}
                selectIEth={props.selectIEth}
                overviewData={props.overviewData}
              />
            }
          />
          <Route
            path={'/vaults'}
            element={
              <VaultsDashboard
                aums={props.aums}
                dayHistories={props.dayHistories}
                weekHistories={props.weekHistories}
                monthHistories={props.monthHistories}
                month3Histories={props.month3Histories}
                yearHistories={props.yearHistories}
                allHistories={props.allHistories}
                bnb_dayHistories={props.bnb_dayHistories}
                bnb_weekHistories={props.bnb_weekHistories}
                bnb_monthHistories={props.bnb_monthHistories}
                bnb_month3Histories={props.bnb_month3Histories}
                bnb_yearHistories={props.bnb_yearHistories}
                bnb_allHistories={props.bnb_allHistories}
                spinning={props.spinning}
                connected={props.connected}
                vaultSelected={props.vaultSelected}
                inavList={props.inavList}
                usdcBal={props.usdcBal}
                shareBals={props.shareBals}
                pendingDepositState={props.pendingDepositState}
                pendingWithdrawState={props.pendingWithdrawState}
                walletLoading={props.walletLoading}
                processedRecords={props.processedRecords}
                requestRecords={props.requestRecords}
                selectUpdown={props.selectUpdown}
                selectNeutral={props.selectNeutral}
                selectUp={props.selectUp}
                selectDown={props.selectDown}
                info={props.info}
                shareSupply={props.shareSupply}
                numShareholders={props.numShareholders}
                minUsdc={props.minUsdc}
                minShares={props.minShares}
                maxUsdc={props.maxUsdc}
                marketFeed={props.marketFeed}
                portfolios={props.portfolios}
                bnbPortfolios={props.bnbPortfolios}
                pendingDeposit={props.pendingDeposit}
                pendingRedeem={props.pendingRedeem}
                completedRequests={props.completedRequests}
                operationsCache={props.operationsCache}
                onboardingFees={props.onboardingFees}
                offboardingFees={props.offboardingFees}
                orderFees={props.orderFees}
                userRedeemsUnderProcessing={props.userRedeemsUnderProcessing}
                userDepositsUnderProcessing={props.userDepositsUnderProcessing}
                processingStatus={props.processingStatus}
                selectSwap={props.selectSwap}
                selectMint={props.selectMint}
                selectRedeem={props.selectRedeem}
                tripleSelected={props.tripleSelected}
                spotAllocations={props.spotAllocations}
                spotNavs={props.spotNavs}
                selectSUp={props.selectSUp}
                selectIBtc={props.selectIBtc}
                selectIEth={props.selectIEth}
                spotSelected={props.spotSelected}
                spotShareSupply={props.spotShareSupply}
                overviewData={props.overviewData}
              />
            }
          />
          {/*<Route*/}
          {/*  path={'/spot'}*/}
          {/*  element={*/}
          {/*    <SpotDashboard*/}
          {/*      aums={props.aums}*/}
          {/*      dayHistories={props.bnb_dayHistories}*/}
          {/*      weekHistories={props.bnb_weekHistories}*/}
          {/*      monthHistories={props.bnb_monthHistories}*/}
          {/*      month3Histories={props.bnb_month3Histories}*/}
          {/*      yearHistories={props.bnb_yearHistories}*/}
          {/*      allHistories={props.bnb_allHistories}*/}
          {/*      bnb_dayHistories={props.bnb_dayHistories}*/}
          {/*      bnb_weekHistories={props.bnb_weekHistories}*/}
          {/*      bnb_monthHistories={props.bnb_monthHistories}*/}
          {/*      bnb_month3Histories={props.bnb_month3Histories}*/}
          {/*      bnb_yearHistories={props.bnb_yearHistories}*/}
          {/*      bnb_allHistories={props.bnb_allHistories}*/}
          {/*      spinning={props.spinning}*/}
          {/*      connected={props.connected}*/}
          {/*      vaultSelected={props.vaultSelected}*/}
          {/*      inavList={props.inavList}*/}
          {/*      usdcBal={props.usdcBal}*/}
          {/*      shareBals={props.shareBals}*/}
          {/*      pendingDepositState={props.pendingDepositState}*/}
          {/*      pendingWithdrawState={props.pendingWithdrawState}*/}
          {/*      walletLoading={props.walletLoading}*/}
          {/*      processedRecords={props.processedRecords}*/}
          {/*      requestRecords={props.requestRecords}*/}
          {/*      selectUpdown={props.selectUpdown}*/}
          {/*      selectNeutral={props.selectNeutral}*/}
          {/*      selectUp={props.selectUp}*/}
          {/*      selectDown={props.selectDown}*/}
          {/*      info={props.info}*/}
          {/*      shareSupply={props.shareSupply}*/}
          {/*      numShareholders={props.numShareholders}*/}
          {/*      minUsdc={props.minUsdc}*/}
          {/*      minShares={props.minShares}*/}
          {/*      maxUsdc={props.maxUsdc}*/}
          {/*      marketFeed={props.marketFeed}*/}
          {/*      portfolios={props.portfolios}*/}
          {/*      bnbPortfolios={props.bnbPortfolios}*/}
          {/*      pendingDeposit={props.pendingDeposit}*/}
          {/*      pendingRedeem={props.pendingRedeem}*/}
          {/*      completedRequests={props.completedRequests}*/}
          {/*      operationsCache={props.operationsCache}*/}
          {/*      onboardingFees={props.onboardingFees}*/}
          {/*      offboardingFees={props.offboardingFees}*/}
          {/*      orderFees={props.orderFees}*/}
          {/*      userRedeemsUnderProcessing={props.userRedeemsUnderProcessing}*/}
          {/*      userDepositsUnderProcessing={props.userDepositsUnderProcessing}*/}
          {/*      processingStatus={props.processingStatus}*/}
          {/*      selectSwap={props.selectSwap}*/}
          {/*      selectMint={props.selectMint}*/}
          {/*      selectRedeem={props.selectRedeem}*/}
          {/*      tripleSelected={props.tripleSelected}*/}
          {/*      spotAllocations={props.spotAllocations}*/}
          {/*      spotNavs={props.spotNavs}*/}
          {/*      selectSUp={props.selectSUp}*/}
          {/*      selectIBtc={props.selectIBtc}*/}
          {/*      selectIEth={props.selectIEth}*/}
          {/*      spotSelected={props.spotSelected}*/}
          {/*      spotShareSupply={props.spotShareSupply}*/}
          {/*      overviewData={props.overviewData}*/}
          {/*    />*/}
          {/*  }*/}
          {/*/>*/}
          <Route path={'account'}>
            <Route
              path={'portfolio'}
              element={
                <MyPortfolio
                  inavList={props.inavList}
                  shareBals={props.shareBals}
                  loading={props.walletLoading}
                  connected={props.connected}
                  spotInavList={props.spotNavs}
                />
              }
            />
            <Route
              path={'transactions'}
              element={
                <Transactions
                  pendingDepositState={props.pendingDepositState}
                  pendingWithdrawState={props.pendingWithdrawState}
                  processedRecords={props.processedRecords}
                  requestRecords={props.requestRecords}
                  loading={props.walletLoading}
                  connected={props.connected}
                  completedRequests={props.completedRequests}
                  pendingDeposit={props.pendingDeposit}
                  pendingRedeem={props.pendingRedeem}
                  operationsCache={props.operationsCache}
                  userDepositsUnderProcessing={props.userDepositsUnderProcessing}
                  userRedeemsUnderProcessing={props.userRedeemsUnderProcessing}
                />
              }
            />
          </Route>
          {/*<Route path={'/swap'} element={<Swap loading={props.spinning} />} />*/}
          <Route path={'competition'}>
            <Route path={'about'} element={<AboutCompetition />} />
            <Route path={'leaderboard'} element={<Leaderboard />} />
            <Route path={'airdrop'} element={<Airdrop connected={props.connected} spinning={props.spinning} />} />
          </Route>

          <Route path="404" element={<Error404 />} />
        </Route>
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/status" element={<Status />} />
      </Routes>
    </BrowserRouter>
  );
};
