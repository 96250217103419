import React, { useState, useEffect } from 'react';
// import { Modal, Button } from 'antd';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { Link } from 'react-router-dom';

const cookiesLabel = 'cookies0.1';
const cookiesHtml = (
  <div
    style={{
      maxHeight: '50vh',
      overflowY: 'auto',
    }}
  >
    <h2>1. Introduction</h2>
    <p>
      Our approach to cookies unveils the methods through the Yiedl platform, employs cookies and akin technologies to
      identify you during your site visits (referred to as the {`"Cookie Policy"`}). This policy delves into the nature
      of these technologies, the reasons behind their usage, and outlines your rights concerning their application. It
      {`'`}s important to note that, on occasion, we might employ cookies to gather personal information or data that
      becomes personal when amalgamated with other information. For a comprehensive understanding of how we handle your
      personal information, we encourage you to peruse our Privacy Policy.
    </p>

    <h2>2. What are cookies?</h2>
    <p>
      Cookies represent small data files deposited on your computer or mobile device during your website visits. Website
      owners commonly employ cookies to optimize functionality, enhance efficiency, and provide insightful reporting.
    </p>

    <p>
      The cookies instated by the website owner (in this instance the Yiedl platform) are denoted as{' '}
      {`"first-party
      cookies."`}{' '}
      Conversely, those established by entities other than the website owner are termed {`"third-party cookies."`}
      Third-party cookies facilitate the provision of external features or functionalities on or through the site, such
      as advertising, interactive content, and analytics. The entities responsible for these third-party cookies can
      identify your computer or device upon its visit to the respective website.
    </p>

    <h2>3. Why do we use cookies?</h2>
    <p>
      Our use of cookies is multifaceted. Certain cookies are imperative for the technical functioning of our sites,
      labeled as {`"essential"`} or {`"strictly necessary."`} Other cookies allow us to comprehend the preferences of
      our website visitors and users, thereby enriching the overall user experience. Through our sites, third parties
      administer cookies for advertising, analytics, and various purposes, as elucidated further below. The specific
      categories of first and third-party cookies employed on our sites and the functions they serve are detailed below
      (please note that the specific cookies used may vary based on the particular sites you explore).
    </p>

    <h2>4. What about other tracking technologies?</h2>
    <p>
      While cookies stand as one method to recognize or track website visitors, they are not the exclusive means.
      Periodically, we may deploy other analogous technologies, such as web beacons (sometimes referred to as{' '}
      {`"tracking
      pixels"`}{' '}
      or {`"clear gifs""`}). These minute graphic files carry a distinctive identifier, enabling us to identify visits
      to our sites or email openings. This functionality empowers us to monitor site traffic patterns, facilitate the
      delivery or interaction with cookies, enhance overall site performance and services, and gauge the effectiveness
      of email marketing initiatives. Frequently, these technologies rely on cookies to operate seamlessly, so opting
      out of cookies may compromise their effectiveness.
    </p>

    <h2>5. How often will you update this Cookie Policy?</h2>
    <p>
      Updates to this Cookie Policy may occur periodically to reflect changes in the cookies employed or for other
      operational, legal, or regulatory considerations. Please revisit this Cookie Policy regularly to remain apprised
      of our use of cookies and associated technologies. The date atop this Cookie Policy indicates the most recent
      update.
    </p>
  </div>
);

const CookiesPopup: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already agreed to the disclaimer
    const hasAgreed = localStorage.getItem(cookiesLabel);
    if (hasAgreed !== 'true') {
      setIsModalVisible(true);
    }
  }, []);

  const handleAgree = () => {
    // Set flag in local storage and hide the modal
    localStorage.setItem(cookiesLabel, 'true');
    setIsModalVisible(false);
  };

  return (
    <BaseModal
      title="Cookies Policy"
      visible={isModalVisible}
      onOk={handleAgree}
      closable={false}
      footer={[
        <BaseButton key="agree" type="primary" onClick={handleAgree}>
          I Agree
        </BaseButton>,
      ]}
    >
      {cookiesHtml}
    </BaseModal>
  );
};

export default CookiesPopup;
